import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { notification, Tabs } from "antd";
import axios from "axios";
import MainLayout from "layouts/MainLayout";
import styles from "./styles.module.scss";
import { Table, Input } from "antd";
import { TbFolderExclamation } from "react-icons/tb";
import { BiSolidEditAlt } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import EditCompanyModal from "components/Modals/EditCompanyModal";
import ViewCompanyDetailsModal from "components/Modals/ViewCompanyDetails";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import ActionButton from "components/ActionButton";
import { editCompany } from "APIs/Services";

function EditCompany() {
  const {
    tokenUser,
    allCompaniesList,
    setAllCompaniesList,
    getAllCompanies,
    handleUnautherized,
  } = useAuth();
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
  const [isViewCompanyDetailsModalOpen, setIsViewCompanyDetailsModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const Navigate = useNavigate();

  const showEditModalWithData = (item) => {
    const itemIndex = allCompaniesList.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditCompanyModal();
  };

  const updateCompaniesData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...allCompaniesList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setAllCompaniesList(updatedCsvFileDataTemp);
  };

  // const editCompanyDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
  //       {
  //         user_id: item?.id,
  //         first_name: item?.firstName,
  //         last_name: item?.lastName,
  //         phone: item?.mobileNumber,
  //         address: item?.address,
  //         user_name: item?.preferredName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Company Details Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  const handleViewCompanyDetailsOk = () => {
    setIsViewCompanyDetailsModalOpen(false);
  };

  const handleViewCompanyDetailsCancel = () => {
    setIsViewCompanyDetailsModalOpen(false);
  };

  const showEditCompanyModal = () => {
    setIsEditCompanyModalOpen(true);
  };

  const handleEditCompanyOk = async (updatedData) => {
    // await editCompanyDetails(updatedData);
    // await getAllCompanies();
    // setIsEditCompanyModalOpen(false);

    setIsLoading(true);
    const response = await editCompany(token, {
      user_id: updatedData?.id,
      first_name: updatedData?.firstName,
      last_name: updatedData?.lastName,
      phone: updatedData?.mobileNumber,
      address: updatedData?.address,
      user_name: updatedData?.preferredName,
    });

    if (response?.status === 200) {
      await getAllCompanies();
      notification.success({
        message: "Success",
        description: "Company Details Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }

    setIsEditCompanyModalOpen(false);
  };

  const handleEditCompanyCancel = () => {
    setIsEditCompanyModalOpen(false);
  };

  const ActiveDriversColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers`}
          onClick={() =>
            localStorage.setItem("specificComDetails", JSON.stringify(record))
          }
        >
          {text}
        </Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: 120,
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      render: (text, record) => (
        <div>
          <p>{`${(record?.plan).toUpperCase()}`}</p>
        </div>
      ),
    },
    {
      title: "Remaining Days",
      dataIndex: "planRemainingDays",
      width: 230,
      sorter: (a, b) => a.planRemainingDays - b.planRemainingDays,
      render: (text, record) => (
        <div>
          <p>
            {record?.plan === "free" ? `${record?.planRemainingDays}` : "--"}
          </p>
        </div>
      ),
    },
    {
      title: "Grace Period",
      dataIndex: "planGraceDays",
      width: 210,
      sorter: (a, b) => a.planGraceDays - b.planGraceDays,
      render: (text, record) => (
        <div>
          <p>{record?.plan === "paid" ? `${record?.planGraceDays}` : "--"}</p>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: 210,
      sorter: (a, b) => a.mobileNumber - b.mobileNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Driver",
      dataIndex: "totalDrivers",
      width: 120,
      sorter: (a, b) => a.totalDrivers - b.totalDrivers,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
          />
          <ActionButton
            onClick={() => {
              Navigate(`/companydrivers`);
              localStorage.setItem(
                "specificComDetails",
                JSON.stringify(record)
              );
            }}
            icon={<AiFillEye size={15} />}
            text="View"
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "All Companies",
    },
    {
      key: "2",
      label: "Edit Company",
      children: (
        <div className={styles.mainWrapper}>
          {allCompaniesList?.length > 0 ? (
            <div className={styles.dataTableWrapper}>
              <div className={styles.tableWrapperHeader}>
                <p className={styles.heading}>Edit Company Right Now</p>
                <Input.Search
                  placeholder="Search here..."
                  style={{ width: "40%" }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <Table
                columns={ActiveDriversColumns}
                dataSource={allCompaniesList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.mobileNumber,
                  email: item.email,
                  totalAmount: item.paidAmount,
                }))}
                scroll={{
                  x: 1700,
                }}
                className="custom-table"
              />
            </div>
          ) : (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Block Company",
    },
  ];

  const onChange = (key) => {
    if (key === "1") {
      console.log(key);
      Navigate("/allcompanies");
    } else if (key === "3") {
      console.log(key);
      Navigate("/blockcompany");
    }
  };

  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="2"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
          onChange={onChange}
        />
        <EditCompanyModal
          handleEditCompanyCancel={handleEditCompanyCancel}
          handleEditCompanyOk={handleEditCompanyOk}
          isEditCompanyModalOpen={isEditCompanyModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCompaniesData={updateCompaniesData}
          isLoading={isLoading}
        />
        <ViewCompanyDetailsModal
          handleViewCompanyDetailsCancel={handleViewCompanyDetailsCancel}
          handleViewCompanyDetailsOk={handleViewCompanyDetailsOk}
          isViewCompanyDetailsModalOpen={isViewCompanyDetailsModalOpen}
        />
      </div>
    </MainLayout>
  );
}

export default EditCompany;
