import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import CustomButton from "components/Button";
import CustomInput from "components/CustomInput";

import { validateDriverEditModal } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import InActiveInput from "components/InActiveInput";
import CancelButton from "components/CancelButton";

function ViewCompanyDetailsModal({
  isViewCompanyDetailsModalOpen,
  handleViewCompanyDetailsOk,
  handleViewCompanyDetailsCancel,
}) {
  const initialValues = {
    name: "",
    email: "",
  };

  const formikRef = useRef(null);

  const handleUpdateClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <Modal
      centered
      open={isViewCompanyDetailsModalOpen}
      onOk={handleViewCompanyDetailsOk}
      onCancel={handleViewCompanyDetailsCancel}
      maskClosable={false}
      width={"79%"}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 1.5rem",
            gap: "14px",
            width: "94%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleViewCompanyDetailsOk}
          >
            Custom Button
          </CancelButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "94%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "2.65rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p style={{ alignSelf: "flex-start" }} className={styles.modalText}>
          Company <span>Details</span>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validateDriverEditModal}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleViewCompanyDetailsOk();
          }}
          innerRef={formikRef}
        >
          {({ isSubmitting }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <InActiveInput
                    type="text"
                    placeholder="Company Name"
                    style={{ height: "42px" }}
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <InActiveInput
                    type="text"
                    placeholder="Company Email"
                    style={{ height: "42px" }}
                  />
                </div>
              </div>
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <InActiveInput
                    type="text"
                    placeholder="Phone Number"
                    style={{ height: "42px" }}
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <InActiveInput
                    type="text"
                    placeholder="Company Address"
                    style={{ height: "42px" }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={styles.statisticsWrapper}>
          <div className={styles.allDrivers}>
            <div className={styles.topBar}>
              <p>All Drivers</p>
            </div>
            <div className={styles.numberContent}>
              <p>560</p>
            </div>
          </div>
          <div style={{ width: "36%" }} className={styles.allDrivers}>
            <div
              style={{ backgroundColor: "#3379AC" }}
              className={styles.topBar}
            >
              <p>Active Drivers</p>
            </div>
            <div className={styles.numberContent}>
              <p>500</p>
            </div>
          </div>
          <div className={styles.allDrivers}>
            <div
              style={{ backgroundColor: "#FFB715" }}
              className={styles.topBar}
            >
              <p>Inactive Driver</p>
            </div>
            <div className={styles.numberContent}>
              <p>60</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewCompanyDetailsModal;
