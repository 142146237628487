import React from "react";
import styles from "./styles.module.scss";
import LandingPageFooter from "layouts/LandingPageFooter";
import LandingPageHeader from "layouts/LandingPageHeader";

function PrivacyPolicy() {
  return (
    <>
      <LandingPageHeader isBoxShadow={true} />
      <div className={styles.privacyPolicyWrapper}>
        <img className={styles.bgImage} src="\Images\PrivacyPolicyBg.png" />
        <p className={styles.privacyPolicyHeading}>
          PRIVACY POLICY October 2023
        </p>
        <div className={styles.privacyPolicyContent}>
          <p>
            {" "}
            Cabber USA welcomes you to our platform.We would like to describe
            how and why we may collect, store, use, and/or process your given
            information when you use our website at{" "}
            <a href="http://www.cabberusa.com" target="_blank">
              www.cabberusa.com
            </a>{" "}
            or the mobile application ‘CABBER 2’ The privacy policy
            predominantly pertains to the driver/operators who input a small
            amount of personal information into the app when setting up a user
            account subscription. Website users who only use the platform to
            connect with drivers do not share personal information with CABBER
            and may only be interested in the privacy aspects of functional
            website cookies related to the Google Maps API, which are far
            removed from any personal information disclosure or logging.
          </p>

          <p>
            If you do not find our data usage policy acceptable, you can choose
            not to use our online platform. We do not process sensitive personal
            information. We do not receive any information from third parties.
            We process your information:
          </p>

          <ol>
            <li>To administer our Online Services,</li>
            <li>To communicate with you</li>
            <li>For security and fraud prevention, and</li>
            <li>
              To comply with legal requirements but only when we have a legal
              obligation.
            </li>
          </ol>

          <p>
            No electronic transmission over the internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other unauthorized
            third parties will not be able to defeat our security and
            subsequently collect, access, steal, or modify your information.
          </p>

          <p>Personal information you disclose:</p>

          <p>We may collect personal information that you provide to us.</p>

          <p>
            We may collect the personal information you voluntarily provide us
            when you obtain our services, when you participate in activities on
            our platform, or otherwise when you contact us. Personal Information
            Provided by You.
          </p>

          <ol>
            <li>Names</li>
            <li>Phone Numbers</li>
            <li>Geolocation</li>
            <li>Email Addresses</li>
            <li>Usernames</li>
            <li>Passwords</li>
          </ol>
          <p>
            Payment Data. We collect the data necessary to process your
            recurring payments through Stripe.
          </p>

          <p>You may find their privacy notice link(s) here:</p>
          <p>
            https://www.paypal.com/us/webapps/mpp/ua/privacy-full#personalData
          </p>
          <p>
            https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice
          </p>
          <p>https://www.apple.com/legal/applepayments/privacy-notice</p>

          <p>
            Application Data. If you use our app, we collect the following
            information that you choose to provide us with:
          </p>

          <p>Geolocation Information.</p>

          <p>
            We require permission to track location-based information from your
            mobile device continuously or while you are using our mobile
            application to provide the intended location-based services. If you
            wish to change our access or permissions, you may do so in your
            device's settings, however, that may render the app inoperable.
          </p>

          <p>
            This information is primarily needed to maintain the security and
            operation of our app, for troubleshooting, and for our internal
            analytics and reporting purposes.
          </p>

          <p>
            All Personal Information you provide must be sufficiently accurate
            as is necessary for your payment gateway to maintain recurring
            payments on schedule.
          </p>

          <p>
            Your IP address, browser, and device characteristics are collected
            when you visit our Services.
          </p>

          <p>
            Our back-end digital systems may automatically collect certain
            information when you visit, use, or navigate the Services. This
            information does not necessarily reveal your specific identity, but
            it may include device and usage information, such as your IP
            address, browser and device characteristics, operating system,
            language preferences, referring URLs, device name, country,
            location, information about how and when you use our Services, and
            other technical information. This information is primarily needed to
            maintain the security and functionality of our services.
          </p>

          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies, including:
          </p>

          <p>Location Data.</p>

          <p>
            We collect location data from devices such as GPS and to collect
            geolocation data that tells us your current location (based on your
            IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by
            disabling your Location setting on your device. However, if you opt
            out, you may not enjoy our platform's full capability.
          </p>

          <p>We process your information:</p>

          <ul>
            <li>
              To administer our services, communicate with you for security and
              fraud prevention, and to comply with the law.
            </li>
            <li>
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </li>
            <li>
              To deliver and facilitate the delivery of services to the website
              users.
            </li>
          </ul>

          <p>
            We only process your personal information when we believe it is
            necessary and we have a valid legal reason.
          </p>

          <p>For those located in the EU or UK, this section applies to you:</p>

          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on to process your personal
            information. As such, we may rely on the following legal bases to
            process your personal information:
          </p>

          <p>
            Consent. We may process your information if you have given us
            permission (i.e., consent) to use your personal information for a
            specific purpose. You can withdraw your relevant consent anytime by
            deleting the app from your smartphone.
          </p>

          <p>
            Performance of a Contract. We may process your personal information
            when we believe it is necessary to fulfill our contractual
            obligations to you, including providing our Services or at your
            request before entering into a contract with you.
          </p>

          <p>
            We may process your information to cooperate with a law enforcement
            body or regulatory agency, exercise or defend our legal rights, or
            disclose your information as evidence in litigation in which we are
            involved. As the 'data controller' under European data protection
            laws, we determine the means and/or purposes of our data processing.
            This privacy notice does not apply to the personal information we
            process as a 'data processor' on behalf of our customers. In those
            situations, the customer we provide services to and with whom we
            have entered into a data processing agreement is the 'data
            controller' responsible for your personal information. We merely
            process your information on their behalf by your instructions. If
            you want to know more about our customers' privacy practices, read
            their privacy policies and direct any questions to them.
          </p>

          <p>For those located in Canada, this section applies to you:</p>

          <p>
            We process your information if you have given us specific permission
            (i.e., express consent) to use your personal information for a
            specific purpose or in situations where your permission can be
            inferred (i.e., implied consent). You can withdraw consent anytime
            by deleting the app from your smartphone. In some exceptional cases,
            we may be legally permitted under applicable law to process your
            information without your consent, including, for example:
          </p>

          <ul>
            <li>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained promptly.
            </li>
            <li>For investigations, fraud detection, and prevention.</li>
            <li>
              For business transactions, provided certain conditions are met.
            </li>
            <li>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim.
            </li>
            <li>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin.
            </li>
            <li>
              If we have reasonable grounds to believe an individual has been,
              is, or maybe the victim of financial abuse.
            </li>
            <li>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information, the collection is reasonable for purposes related to
              investigating a breach of an agreement or a contravention of the
              laws of Canada or a province.
            </li>
            <li>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records.
            </li>
            <li>
              If an individual produced it during their employment, business, or
              profession, the collection is consistent with the purposes for
              which the information was produced.
            </li>
            <li>
              If the collection is solely for journalistic, artistic, or
              literary purposes.
            </li>
            <li>
              If the information is publicly available and is specified by the
              regulations.
            </li>
          </ul>

          <p>
            We may share information in specific situations described in this
            section and/or with the following third parties.
          </p>

          <p>
            We may need to share your personal information in the following
            situations:
          </p>

          <p>
            Business Transfers. We may share or transfer your information in
            connection with or during negotiations of any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </p>

          <p>
            When we use Google Maps Platform APIs, we may share your information
            with certain Google Maps Platform APIs (e.g. Google Maps API, Places
            API). Please refer to{" "}
            <a href="https://policies.google.com/privacy" target="_blank">
              this link
            </a>{" "}
            to learn more about Google’s Privacy Policy.
          </p>

          <p>
            We may use cookies and other tracking technologies to collect and
            store your information and for similar tracking technologies (like
            web beacons and pixels) to access or store information. Our Cookie
            Notice sets out specific information about how we use such
            technologies and how you can refuse certain cookies.We keep your
            Information for as long as necessary to fulfill the purposes
            outlined in this privacy notice unless otherwise required by law.
          </p>

          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No other purpose in
            this notice will require us to keep your personal information for
            longer than the period of time in which users have an account with
            us.
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          <p>
            We protect your personal information through organizational and
            technical security measures, including appropriate and reasonable
            technical and organizational security measures designed to protect
            the security of any personal information we process. However,
            despite our safeguards and efforts to secure your information, no
            electronic transmission over the Internet or information storage
            technology can be guaranteed to be 100% secure, so we cannot promise
            or guarantee that hackers, cybercriminals, or other unauthorized
            third parties will not be able to defeat our security and improperly
            collect, access, steal, or modify your information. Although we will
            do our best to protect your personal information, transmission of
            personal information to and from our Services is at your own risk.
            You should only access the Services within a secure environment.
          </p>

          <p>
            We do not knowingly collect data from or market to children under 18
            or solicit data from or market to children under 18.
          </p>

          <p>
            Using the Cabber USA platform, you represent that you are at least
            18 or the parent or guardian of such a minor and consent to such
            minor dependent’s use of the Services. If we learn that personal
            information from users less than 18 years of age has been collected,
            we will deactivate the account and take reasonable measures to
            promptly delete such data from our records.
          </p>

          <p>
            If you become aware of any data we may have collected from children
            under 18, please contact us at{" "}
            <a href="mailto:info@cabberusa.com">info@cabberusa.com</a>. In
            regions such as the European Economic Area (EEA), United Kingdom
            (UK), and Canada, you have rights that allow you greater access to
            and control over your personal information. You may review, change,
            or terminate your account at any time.
          </p>

          <p>
            In regions (like the EEA, UK, and Canada), you have certain rights
            under applicable data protection laws. These may include the right
            (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure, (iii) to
            restrict the processing of your personal information, and (iv) if
            applicable, to data portability. In certain circumstances, you may
            also have the right to object to processing your personal
            information. You can make such a request by emailing{" "}
            <a href="mailto:info@cabberusa.com">info@cabberusa.com</a>.
          </p>

          <p>
            We will consider and act upon any request per applicable data
            protection laws.
          </p>

          <p>
            Suppose you are in the EEA or UK and believe we are unlawfully
            processing your personal information. In that case, you also have
            the right to complain to your local data protection supervisory
            authority. You can find their contact details here:{" "}
            <a
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
              target="_blank"
            >
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>

          <p>
            If you are located in Switzerland, the contact details for the data
            protection authorities are available here:{" "}
            <a
              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
              target="_blank"
            >
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
          </p>

          <p>
            Withdrawing your consent: If we are relying on your consent to
            process your personal information, which may be express and/or
            implied consent, depending on the applicable law, you have the right
            to withdraw your consent at any time. You can withdraw your consent
            anytime by deleting the app from your smartphone. However, please
            note that this will not affect the lawfulness of the processing
            before its withdrawal, nor when applicable law allows, will it
            affect the processing of your personal information conducted in
            reliance on lawful processing grounds other than consent.
          </p>

          <p>Account Information – Taxi Companies</p>

          <p>
            If you would like to review or change the information in your
            account or terminate your account, you can log in to your account
            settings and update your user account.
          </p>

          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with investigations, enforce
            our legal terms, and/or comply with applicable legal requirements.
          </p>

          <p>
            Cookies and similar technologies: Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually set your
            browser to remove cookies and reject cookies. If you choose to
            remove cookies or reject cookies, this could affect certain features
            or services of our platform.
          </p>

          <p>
            If you have questions or comments about your privacy rights, email
            us at <a href="mailto:info@cabberusa.com">info@cabberusa.com</a>.
            Most web browsers, mobile operating systems, and mobile applications
            include a Do-Not-Track ('DNT') feature or setting you can activate
            to signal your privacy preference and not to have data about your
            online browsing activities monitored and collected. No uniform
            technology standard for recognizing and implementing DNT signals has
            been finalized at this stage. As such, we do not currently respond
            to DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy notice.
          </p>

          <p>
            If you are a resident of California, you are granted specific rights
            regarding access to your personal information.
          </p>

          <p>
            California Civil Code Section 1798.83, also known as the 'Shine The
            Light' law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing using our website's contact
            information.
          </p>

          <p>
            If you are under 18, reside in California, and have a registered
            account, you can request the removal of unwanted data you post
            publicly. To request removal of such data, please contact us using
            the contact information provided on our website. Include the email
            address associated with your account and a statement that you reside
            in California. We will make sure the data is not publicly displayed
            on the platform, but please be aware that the data may not be
            completely or comprehensively removed from all our systems (e.g.,
            backups, etc.).
          </p>

          <p>CCPA Privacy Notice</p>

          <p>The California Code of Regulations defines a 'resident' as:</p>
          <ol>
            <li>
              Every individual who is in the State of California for other than
              a temporary or transitory purpose and
            </li>
            <li>
              Every individual who is domiciled in the State of California who
              is outside the State of California for a temporary or transitory
              purpose
            </li>
          </ol>

          <p>All other individuals are defined as 'non-residents'.</p>

          <p>
            If this definition of 'resident' applies to you, we must adhere to
            certain rights and obligations regarding your personal information.
          </p>

          <p>What categories of personal information do we collect?</p>

          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>

          <p>
            A. Identifiers Contact details, such as real name, alias, postal
            address, telephone or mobile contact number, unique personal
            identifier, online identifier, Internet Protocol address, email
            address, and account name YES.
          </p>

          <p>
            B. Personal information categories listed in the California Customer
            Records Statute name, contact information, education, employment,
            employment history, and financial information: YES.
          </p>

          <p>
            C. Protected classification characteristics under California or
            federal law under and date of birth NO
          </p>

          <p>
            D. Commercial information transaction information, purchase history,
            financial details, and payment information NO
          </p>

          <p>E. Biometric information Fingerprints and voiceprints NO</p>

          <p>
            F. Internet or other similar network activity Browsing history,
            search history, online behavior, interest data, and interactions
            with our and other websites, applications, systems, and
            advertisements NO
          </p>

          <p>G. Geolocation data device location YES</p>

          <p>
            H. Audio, electronic, visual, thermal, olfactory, or similar
            information and audio, video, or call recordings created in
            connection with our business activities. NO
          </p>

          <p>
            I. Professional or employment-related information Business contact
            details to provide you with our Services at a business level or job
            title, work history, and professional qualifications if you apply
            for a job with us. NO
          </p>

          <p>
            J. Education Information Student records and directory information
            NO
          </p>

          <p>
            K. Inferences are drawn from other personal information Inferences
            drawn from any of the collected personal information listed above to
            create a profile or summary about, for example, an individual’s
            preferences and characteristics NO
          </p>

          <p>L. Sensitive Personal Information NO</p>

          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>

          <p>
            Category A - As long as the user has an account with us +8 years.
          </p>

          <p>
            Category B - As long as the user has an account with us +8 years.
          </p>

          <p>
            Category G - As long as the user has an account with us +8 years.
          </p>

          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>

          <p>
            You may contact us by email at{" "}
            <a href="mailto:info@cabberusa.com">info@cabberusa.com</a> or by
            referring to the contact details on our website at{" "}
            <a href="https://www.cabberusa.com" target="_blank">
              www.cabberusa.com
            </a>
            .
          </p>

          <p>
            If you are using an authorized agent to exercise your right to opt
            out, we may deny a request if the authorized agent does not submit
            proof that they have been validly authorized to act on your behalf.
          </p>

          <p>Will your information be shared with anyone else?</p>

          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Each service provider is a for-profit entity that processes the
            information on our behalf, following the same strict privacy
            protection obligations mandated by the CCPA. We may use your
            personal information for our business purposes, such as undertaking
            internal research for technological development and demonstration.
            This is not considered the ‘selling' of your personal information.
          </p>

          <p>
            <em>
              Cabber USA has not disclosed, sold, or shared any personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months.
            </em>
          </p>

          <p>
            We will not sell or share personal information belonging to website
            visitors, users, and other consumers in the future.
          </p>

          <p>Your rights for your personal data</p>

          <p>Right to request deletion of the data — Request to delete</p>

          <p>
            You can ask for the deletion of your personal information. Suppose
            you ask us to delete your personal information. In that case, we
            will respect your request and delete your personal information,
            subject to certain exceptions provided by law, such as (but not
            limited to) the exercise by another consumer of his or her right to
            free speech, our compliance requirements resulting from a legal
            obligation, or any processing that may be required to protect
            against illegal activities.
          </p>

          <p>Right to be informed — Request to know</p>

          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>Whether we collect and use your personal information,</li>
            <li>The categories of personal information that we collect.</li>
            <li>
              The purposes for which the collected personal information is used.
            </li>
            <li>
              Whether we sell or share personal information to third parties.
            </li>
            <li>
              The categories of personal information that we sold, shared or
              disclosed for a business purpose.
            </li>
            <li>
              The categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose.
            </li>
            <li>
              The business or commercial purpose for collecting, selling, or
              sharing personal information.
            </li>
            <li>
              The specific pieces of personal information we collected about
              you.
            </li>
          </ul>

          <p>
            By applicable law, we are not obligated to provide or delete
            consumer information that is deidentified in response to a consumer
            request or to re-identify individual data to verify a consumer
            request.
          </p>

          <p>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights: We will not discriminate against you if you exercise your
            privacy rights.
          </p>

          <p>
            Right to Limit Use and Disclosure of Sensitive Personal Information
            - We do not process consumer's sensitive personal information.
          </p>

          <p>
            Upon receiving your request, we will need to verify your identity to
            determine that you are the same person with whom we have the
            information in our system. These verification efforts require us to
            ask you to provide information so that we can match it with the
            information you have previously provided us. For instance, depending
            on the type of request you submit, we may ask you to provide certain
            information so that we can match the information you provide with
            the information we already have on file, or we may contact you
            through a communication method (e.g., phone or email) that you have
            previously provided to us. We may also use other verification
            methods as the circumstances dictate.
          </p>

          <p>
            We will only use the personal information provided in your request
            to verify your identity or authority to make the request. To the
            extent possible, we will avoid requesting additional information
            from you for the purposes of verification. However, suppose we
            cannot verify your identity from our maintained information. In that
            case, we may request that you provide additional information for the
            purposes of verifying your identity and for security or
            fraud-prevention purposes. We will delete such additional
            information as soon as we finish verifying you.
          </p>

          <p>Other privacy rights:</p>

          <p>
            You may object to the processing of your personal information. You
            may request correction of your personal data if it is incorrect or
            no longer relevant or ask to restrict the processing of the
            information.
          </p>

          <p>
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            who does not submit proof that they have been validly authorized to
            act on your behalf under the CCPA.
          </p>

          <p>
            You may request to opt-out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the request submission
            date.
          </p>

          <p>
            To exercise these rights, you can contact us by email at{" "}
            <a href="mailto:info@cabberusa.com" target="_blank">
              info@cabberusa.com
            </a>
            or by referring to the contact details at the bottom of this
            document. If you have a complaint about how we handle your data, we
            would like to hear from you.
          </p>

          <p>
            If you are a resident of Virginia, you may be granted specific
            rights regarding access to and use of your personal information.
          </p>

          <p>Virginia CDPA Privacy Notice</p>

          <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>

          <ul>
            <li>
              'Consumer' means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </li>
            <li>
              'Personal data' means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              'Personal data' does not include de-identified data or publicly
              available information.
            </li>
            <li>
              'Sale of personal data' means the exchange of personal data for
              monetary consideration.
            </li>
          </ul>

          <p>
            If this definition of 'consumer' applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
          </p>

          <p>
            The information we collect, use, and disclose about you will vary
            depending on how you interact with Cabber USA and our Services.
          </p>

          <p>Your rights to your personal data</p>

          <p>
            Right to be informed whether or not we are processing your personal
            data.
          </p>

          <p>Right to access your personal data</p>

          <p>Right to correct inaccuracies in your personal data</p>

          <p>Right to request deletion of your personal data</p>

          <p>
            Right to obtain a copy of the personal data you previously shared
            with us.
          </p>

          <p>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ('profiling')
          </p>

          <p>
            Cabber USA has not sold personal data to third parties for business
            or commercial purposes. Cabber USA will not sell personal data
            belonging to website visitors, users, and other consumers in the
            future.
          </p>

          <p>Exercise your rights provided under the Virginia CDPA</p>

          <p>
            More information about our data collection and sharing practices can
            be found in this privacy notice.
          </p>

          <p>
            You may contact us by email at{" "}
            <a href="mailto:info@cabberusa.com" target="_blank">
              info@cabberusa.com
            </a>
            or by visiting{" "}
            <a href="http://www.cabberusa.com" target="_blank">
              http://www.cabberusa.com
            </a>
            .
          </p>

          <p>
            If you are using an authorized agent to exercise your rights, we may
            deny a request if the authorized agent does not submit proof that
            they have been validly authorized to act on your behalf.
          </p>

          <p>Verification process:</p>

          <p>
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorized agent, we may need to collect
            additional information to verify your identity before processing
            your request.
          </p>

          <p>
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period and the reason
            for the extension.
          </p>

          <p>Right to appeal</p>

          <p>
            If we decline to take action regarding your request, we will inform
            you of our decision and the reasoning behind it. If you wish to
            appeal against our decision, please email us at{" "}
            <a href="mailto:info@cabberusa.com" target="_blank">
              info@cabberusa.com
            </a>
            . Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions. You may contact the Attorney General to submit a
            complaint if your appeal is denied.
          </p>

          <p>
            We will update this notice as necessary to stay compliant with
            relevant laws.
          </p>

          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated 'Revised' date, and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you by
            prominently posting a notice of such changes or by directly sending
            you a notification. We encourage you to review this privacy notice
            frequently to be informed of how we are protecting your information.
          </p>

          <p>
            If you have questions or comments about this notice, you may email
            us at <a href="mailto:info@cabberusa.com">info@cabberusa.com</a> or
            by post to:
          </p>

          <address>
            CABBER USA
            <br />
            39 West Lexington Street, Suite 1901
            <br />
            Baltimore, MD 21201
            <br />
            United States
          </address>

          <p>
            Cabber USA has appointed Fred Santiago to be its representative in
            the UK. You can contact them directly regarding the processing of
            your information by CABBER USA, by email at{" "}
            <a href="mailto:info@cabberusa.com">info@cabberusa.com</a> or by
            visiting{" "}
            <a href="http://www.cabberusa.com" target="_blank">
              http://www.cabberusa.com
            </a>
            , by phone at +1.443.231.8926, or by mail to:
          </p>

          <address>
            CABBER USA
            <br />
            39 West Lexington Street, Suite 1901
            <br />
            Baltimore, MD 21201
            <br />
            United States
          </address>

          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please visit{" "}
            <a href="http://www.cabberusa.com" target="_blank">
              http://www.cabberusa.com
            </a>
            , use the links to download the driver/operator app, and then delete
            your user account for yourself.
          </p>
        </div>
      </div>
      <LandingPageFooter />
    </>
  );
}

export default PrivacyPolicy;
