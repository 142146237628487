import React, { useState, useRef } from "react";
import axios from "axios";
import { notification } from "antd";
import { backendEndpoints } from "constants/Backend";
import styles from "./styles.module.scss";
import MainLayout from "layouts/MainLayout";
import CustomInput from "components/CustomInput";
import { ReactComponent as EditIconSmal } from "assets/icons/EditIconSmal.svg";
import CustomButton from "components/Button";
import { validateProfile } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { useAuth } from "context";
import InActiveInput from "components/InActiveInput";
import PhoneInputField from "components/PhoneNumberInput";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { updateProfile } from "APIs/Services";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 25, color: "#fff" }} spin />
);

function Profile() {
  const {
    user,
    tokenUser,
    getBasicInfo,
    imageSrc,
    setImageSrc,
    handleUnautherized,
  } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [imgUpload, setImgUpload] = useState(false);
  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;

  const fileInputRef = useRef(null);

  const handleEditClick = () => {
    // Click the hidden file input when the "Edit" icon is clicked
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setImgUpload(true);
    const selectedFile = event.target.files[0];
    console.log("File Size =>", event.target.files[0].size);
    var fileSizeInBytes = event.target.files[0].size;
    var fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    console.log("File Size =>", fileSizeInMB);

    if (fileSizeInMB > 2) {
      notification.error({
        message: "Request Failed",
        description: "Image should be less than 2 MBs",
      });
      setImgUpload(false);
      return;
    }

    if (selectedFile) {
      // Create a FormData object and append the selected file and other fields to it
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("user_id", user?.id);
      formData.append("first_name", user?.firstName);
      formData.append("last_name", user?.lastName);
      formData.append("phone", user?.mobileNumber);
      formData.append("address", user?.address);
      formData.append("user_name", user?.preferredName);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
          formData, // Send the entire request as FormData
          {
            headers: {
              "Content-Type": "multipart/form-data", // Use multipart/form-data
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Handle the API response as needed.

        await getBasicInfo();
        setImgUpload(false);
        setImageSrc(URL.createObjectURL(selectedFile));
        notification.success({
          message: "Success",
          description: "Profile Image Updated",
        });
        return response;
      } catch (error) {
        notification.error({
          message: "Request Failed",
          description:
            error?.response?.status === 401
              ? "Session Expired"
              : "Something Went Wrong",
        });
        setImgUpload(false);
        handleUnautherized(error?.response?.status);
      }

      // Display the selected image

      // You can now use the formData for your desired functionality (e.g., upload, etc.)
    }
  };

  // const editCompanyDetails = async (item) => {
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
  //       item,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  const handleUpdateUserProile = async (data) => {
    const response = await updateProfile(token, data);

    if (response.status === 200) {
      await getBasicInfo();
      notification.success({
        message: "Success",
        description: "Profile Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };
  return (
    <MainLayout>
      <div className={styles.profileWrapper}>
        <div className={styles.profileHeader}>
          <div className={styles.profileAndEditWrapper}>
            {imgUpload ? (
              <div>
                <Spin indicator={antIcon} />
              </div>
            ) : (
              <>
                <img src={imageSrc ? imageSrc : user?.image} alt="" />
                <div className={styles.edit} onClick={handleEditClick}>
                  <EditIconSmal />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </>
            )}
          </div>
          <p>{user?.firstName}</p>
        </div>
        <Formik
          initialValues={{
            firstName: user?.firstName || "",
            phoneNumber: user?.mobileNumber || "",
            address: user?.address || "",
          }}
          validationSchema={validateProfile}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            handleUpdateUserProile({
              user_id: user?.id,
              first_name: values?.firstName,
              phone: values?.phoneNumber,
              address: values?.address,
              // image: fileForApi,
              user_name: user?.preferredName,
            });

            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <label>Username</label>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <label>Phone Number</label>
                  <Field
                    component={PhoneInputField}
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <label>Address</label>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Address"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error"
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <label>Email</label>
                  <InActiveInput value={user?.email || ""} />
                </div>
              </div>
              {user?.roleName === "admin" && (
                <div
                  style={{ marginTop: "1.4rem" }}
                  className={styles.inputFieldsWrapper}
                >
                  <div className={styles.inputAndErrorWrapper}>
                    <label>Plan</label>
                    <InActiveInput value={`${user?.plan} plan`} />
                  </div>
                  <div className={styles.inputAndErrorWrapper}>
                    <label>Remaining Days</label>
                    <InActiveInput value={`${user?.planRemainingDays}`} />
                  </div>
                </div>
              )}
              {/* <>
                <TextArea placeholder={"Bio"} />
              </> */}
              <CustomButton
                style={{
                  height: "38px",
                  width: "140px",
                  borderRadius: "7px",
                  alignSelf: "flex-end",
                  marginTop: "2rem",
                }}
                text={"Update"}
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </MainLayout>
  );
}

export default Profile;
