import React, { useState } from "react";
import axios from "axios";
import styles from "./styles.module.scss";
import { ReactComponent as SmallCabitIcon } from "assets/icons/SmallCabitIcon.svg";
import { Input } from "antd";
import { FiSearch } from "react-icons/fi";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import { Link } from "react-router-dom";
import ConfirmLogoutModal from "components/Modals/ConfirmLogoutModal";

function Header() {
  const customInputStyle = {
    backgroundColor: "#FF0200", // Set the background color to red
    border: "1px solid white", // Set the border to white
    borderRadius: "12px", // Add border radius for rounded corners
    color: "white", // Set text color to white
  };
  const {
    idUser,
    tokenUser,
    user,
    getAllDriversBySearch,
    getAllCompaniesBySearch,
    searchInputText,
    setSearchInputText,
    setSearchResult,
    logout,
    imageSrc,
  } = useAuth();
  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] =
    useState(false);

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;

  const handleChange = (e) => {
    var value = e.target.value;
    setSearchInputText(value);
  };
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      (user?.roleName).toLowerCase() === "superadmin"
        ? await getAllCompaniesBySearch(token)
        : await getAllDriversBySearch(token, id);
      // alert(`You pressed Enter. Search Value: ${searchInputText}`);
    }
  };

  const handleShowConfirmLogout = () => {
    setIsConfirmLogoutModalOpen(true);
  };
  const handleConfirmLogoutOk = async () => {
    await logout();
    setIsConfirmLogoutModalOpen(false);
  };

  const handleConfirmLogoutCancel = () => {
    setIsConfirmLogoutModalOpen(false);
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.iconWrapper}>
        <Link
          to={
            (user?.roleName).toLowerCase() === "superadmin"
              ? "/addcompany"
              : "/dashboard"
          }
        >
          <SmallCabitIcon />
        </Link>
      </div>
      <div className={styles.searchAndProfleWrapper}>
        <div className={styles.planWrapper}>
          {/* <Input
            style={customInputStyle}
            placeholder="Search or type"
            prefix={<FiSearch size={20} />}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          /> */}
          {user?.roleName === "admin" && (
            <>
              <div className={styles.subsPlanWrapper}>
                <p>Subscription Plan</p>
                <div className={styles.planWrapper}>
                  {(user?.plan).toUpperCase()}
                </div>
              </div>
              {user?.plan === "free" ? (
                <div className={styles.remDaysWrapper}>
                  <p>
                    Remaining Days: <span>{`${user?.planRemainingDays}`}</span>
                  </p>
                </div>
              ) : user?.planGraceDays > 1 ? (
                <div className={styles.remDaysWrapper}>
                  <p>
                    Grace Period: <span>{`${user?.planGraceDays}`}</span>
                  </p>
                </div>
              ) : user?.planGraceDays > 1 && user?.isUnpaid === true ? (
                <div className={styles.remDaysWrapper}>
                  <p>Expired</p>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className={styles.profileSectionWrapper}>
          <p>{user?.firstName}</p>
          <div className={styles.profileImgWrapper}>
            <img src={imageSrc ? imageSrc : user?.image} alt="" />
          </div>
          <div className={styles.menuDropdown}>
            <Link to={"/profile"}>
              <div className={styles.dropdownMenuOptions}>Profile</div>
            </Link>
            <div
              onClick={handleShowConfirmLogout}
              className={styles.dropdownMenuOptions}
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      <ConfirmLogoutModal
        isConfirmLogoutModalOpen={isConfirmLogoutModalOpen}
        handleConfirmLogoutOk={handleConfirmLogoutOk}
        handleConfirmLogoutCancel={handleConfirmLogoutCancel}
      />
    </div>
  );
}

export default Header;
