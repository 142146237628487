import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "context";
import { routes, PrivateRoute } from "routes";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        {routes.map((item) => (
          <Route
            exact
            path={item.path}
            key={item.path}
            element={<PrivateRoute route={item}>{item.element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </>
  );
}

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
export default App;
