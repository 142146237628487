import { Table } from "antd";

const PaymentTable = ({ data, perDriverCost, totalPayAble, licenseFee }) => {
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serialNo",
      width: 175,
    },
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 175,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: () =>
        `$ ${
          perDriverCost <= 0.5 && data?.length === 1 ? 0.5 : perDriverCost
        }`,
      width: 175,
    },
  ];
  console.log("data?.length/0.5", (0.5 / data?.length).toFixed(3));
  return (
    <Table
      columns={columns}
      dataSource={data?.map((item, index) => ({
        ...item,
        key: index,
        serialNo: index + 1,
        name: item.first_name,
        email: item.email,
        phoneNumber: item.phone,
      }))}
      scroll={{
        x: 720,
      }}
      pagination={data?.length > 4 ? true : false}
      className="custom-table"
    />
  );
};
export default PaymentTable;
