import AddCompany from "pages/AddCompany";
import AllCompanies from "pages/AllCompanies";
import AllDrivers from "pages/AllDrivers";
import BlockCompany from "pages/BlockCompany";
import BlockDriver from "pages/BlockDriver";
import ChangePassword from "pages/ChangePassword";
import CompanyDrivers from "pages/CompanyDrivers";
import Dashboard from "pages/Dashboard";
import EditCompany from "pages/EditCompany";
import EditDrivers from "pages/EditDrivers";
import ForgetPassword from "pages/ForgetPassword";
import LandingPage from "pages/LandingPage";
import Login from "pages/Login";
import PageNotFound from "pages/PageNotFound";
import Checkout from "pages/Payment/Checkout";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Profile from "pages/Profile";
import TaxiCompany from "pages/TaxiCompany";
import TermsAndConditions from "pages/TermsAndConditions";
import VerifyLink from "pages/VerifyLink";

export const routes = [
  {
    path: "/",
    element: <LandingPage />,
    // auth: true,
  },
  {
    path: "/login",
    element: <Login />,
    auth: true,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
    auth: true,
  },
  {
    path: "/verifylink",
    element: <VerifyLink />,
    auth: true,
  },
  {
    path: "/changepassword",
    element: <ChangePassword />,
    auth: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    protected: true,
    role: "admin",
  },
  {
    path: "/alldrivers",
    element: <AllDrivers />,
    protected: true,
    role: "admin",
  },
  {
    path: "/editdriver",
    element: <EditDrivers />,
    protected: true,
    role: "admin",
  },
  {
    path: "/blockdriver",
    element: <BlockDriver />,
    protected: true,
    role: "admin",
  },
  {
    path: "/addcompany",
    element: <AddCompany />,
    protected: true,
    role: "superadmin",
  },
  {
    path: "/allcompanies",
    element: <AllCompanies />,
    protected: true,
    role: "superadmin",
  },
  {
    path: "/editcompany",
    element: <EditCompany />,
    protected: true,
    role: "superadmin",
  },
  {
    path: "/blockcompany",
    element: <BlockCompany />,
    protected: true,
    role: "superadmin",
  },
  {
    path: "/companydrivers",
    element: <CompanyDrivers />,
    protected: true,
    role: "superadmin",
  },
  {
    path: "/profile",
    element: <Profile />,
    protected: true,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/termsandconditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/taxicompany",
    element: <TaxiCompany />,
    // auth: true,
  },
];
