import * as Yup from "yup";

const phoneNumberPattern = /^[+-]?\d+(\.\d+)?( |$)/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const nameRegex = /^[A-Za-z\s]+$/;

export const validateProfile = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("First Name is required"),
  // lastName: Yup.string()
  //   .matches(nameRegex, "Name must contain only alphabets")
  //   .required("Last Name is required"),
  phoneNumber: Yup.string()
    .matches(phoneNumberPattern, "Phone Number must contain only digits")
    .max(15, "Phone Number must not exceed 11 characters")
    .required("Phone Number is required"),
  address: Yup.string().required("Company Address field is required"),
});
