import React, { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import styles from "./styles.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { validateChangePassword } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CustomInput from "components/CustomInput";
import { ReactComponent as CabitIcon } from "assets/icons/CabitIcon.svg";
import CustomButton from "components/Button";
import { backendEndpoints } from "constants/Backend";

const ChangePassword = () => {
  const Navigate = useNavigate();
  const [isLoading, isSetLoading] = useState(false);

  const url = new URL(window.location.href);
  const tempToken = url?.search?.split("=");

  function handleClick() {
    Navigate("/login");
  }

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.innerLeft}>
          <div className={styles.iconTextWrapper}>
          <Link to={"/"}><CabitIcon /></Link>
            <p>
              Ride without Limits. No Logins, No Frills. Just Hop In and Go!
            </p>
          </div>
        </div>
        <div className={styles.innerRight}>
          <div className={styles.welcomeTextWrapper}>
            <p>Change Password</p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validateChangePassword}
            onSubmit={(values, actions) => {
              const changePassword = async () => {
                isSetLoading(true);
                try {
                  const loginApiToken = process.env.REACT_APP_API_ACCESS_TOKEN;
                  await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.changePassword}`,
                    {
                      token: tempToken[1],
                      password: values?.newPassword,
                    },
                    {
                      headers: {
                        Authorization: `${loginApiToken}`,
                      },
                    }
                  );
                  isSetLoading(false);
                  notification.success({
                    message: "Success",
                    description: "Password Changed Successfully",
                  });
                  handleClick();
                } catch (error) {
                  notification.error({
                    message: "Change Password Request Failed",
                    description: error,
                  });
                  isSetLoading(false);
                }
              };
              changePassword();
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    component={CustomInput}
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    placeholder="Enter new password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error"
                  />
                </div>
                <div styles={{ marginTop: "1rem" }}>
                  <Field
                    component={CustomInput}
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                    text="Set Password"
                    isLoading={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.circleShade}></div>
    </div>
  );
};

export default ChangePassword;
