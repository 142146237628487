import axios from "axios";
import { backendEndpoints } from "constants/Backend";

export const registerDrivers = async (
  token,
  driversObj,
  id,
  invoiceId,
  amount,
  subAmount,
  subCharges,
  subTaxes,
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.AddDrivers}`,
      {
        data: driversObj,
        user_id: id,
        invoice_id: invoiceId,
        amount: amount,
        subAmount: subAmount,
        subCharges: subCharges,
        subTaxes: subTaxes,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
