import React, { useState } from "react";
import MainLayout from "layouts/MainLayout";
import styles from "./styles.module.scss";
import { Table, notification, Input, Tabs } from "antd";
import axios from "axios";
import { TbFolderExclamation } from "react-icons/tb";
import ViewDetailsModal from "components/Modals/ViewDetailsModel";
import { MdOutlineBlock } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { useAuth } from "context/AuthProvider";
import { backendEndpoints } from "constants/Backend";
import DeleteDriverModal from "components/Modals/DeleteDriverModal";
import ActionButton from "components/ActionButton";
import CustomButton from "components/Button";
import { useNavigate } from "react-router-dom";
import { deleteDriver } from "APIs/Services";

function BlockDriver() {
  const {
    allDriversList,
    idUser,
    tokenUser,
    getAllDrivers,
    user,
    handleUnautherized,
  } = useAuth();
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [driverId, setDriverId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewDriver, setViewDriver] = useState();
  const [searchText, setSearchText] = useState("");

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;

  const navigate = useNavigate();

  // const deleteDriver = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.deleteDriver}`,
  //       {
  //         user_id: id,
  //         driver:
  //           driverId.length > 0
  //             ? driverId
  //             : selectedRowKeys.map((key) => ({ id: key })),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };
  const showViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const handleViewDetailsOk = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalOpen(false);
  };

  const showBlockModal = (record) => {
    setIsBlockModalOpen(true);
  };

  const handleBlockOk = async () => {
    setIsLoading(true);
    const response = await deleteDriver(token, {
      user_id: id,
      driver:
        driverId.length > 0
          ? driverId
          : selectedRowKeys.map((key) => ({ id: key })),
    });
    await getAllDrivers();
    setIsBlockModalOpen(false);
    setIsLoading(false);
    setSelectedRowKeys([]);
    if (response?.status === 200) {
      notification.success({
        message: "Success",
        description: "Driver Deleted Successfully",
      });
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const handleBlockCancel = () => {
    setIsBlockModalOpen(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDriverId([]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 250,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 200,
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showBlockModal();
              setDriverId([{ id: record?.id }]);
            }}
            icon={<MdOutlineBlock size={15} />}
            text={"Delete"}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
          <ActionButton
            onClick={() => {
              showViewDetailsModal();
              console.log("View Driver Data", record);
              setViewDriver(record);
            }}
            icon={<AiFillEye size={15} />}
            text="View"
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "All Drivers",
    },
    {
      key: "2",
      label: "Edit Drivers",
    },
    {
      key: "3",
      label: "Delete Drivers",
      children: (
        <div className={styles.mainWrapper}>
          {allDriversList?.length === 0 ? (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          ) : (
            <div className={styles.dataTableWrapper}>
              <div className={styles.tableWrapperHeader}>
                <p className={styles.heading}>Delete Driver Right Now</p>
                <Input.Search
                  placeholder="Search here..."
                  style={{
                    width: "40%",
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              {selectedRowKeys?.length > 0 && (
                <CustomButton
                  style={{
                    width: "160px",
                    height: "33px",
                    borderRadius: "4px",
                    marginBottom: "1rem",
                    marginLeft: "calc(100% - 160px)",
                    fontSize: "12px",
                  }}
                  text={"Delete Selected Drivers"}
                  onClick={showBlockModal}
                  disabled={isLoading}
                />
              )}
              <Table
                columns={ActiveDriversColumns}
                rowSelection={rowSelection}
                dataSource={allDriversList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.phone,
                  email: item.email,
                }))}
                scroll={{
                  x: 950,
                }}
                className="custom-table"
              />
            </div>
          )}
        </div>
      ),
    },
  ];
  const onChange = (key) => {
    if (key === "1") {
      console.log(key);
      navigate("/alldrivers");
    } else if (key === "2") {
      console.log(key);
      navigate("/editdriver");
    }
  };

  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="3"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
          onChange={onChange}
        />

        <DeleteDriverModal
          isDeleteDriverModalOpen={isBlockModalOpen}
          handleDeleteDriverOk={handleBlockOk}
          handleDeleteDriverCancel={handleBlockCancel}
          isLoading={isLoading}
        />
        <ViewDetailsModal
          handleViewDetailsCancel={handleViewDetailsCancel}
          handleViewDetailsOk={handleViewDetailsOk}
          isViewDetailsModalOpen={isViewDetailsModalOpen}
          viewDriverData={viewDriver}
          companyName={user?.firstName}
          companyAddress={user?.address}
          companyNumber={user?.mobileNumber}
        />
      </div>
    </MainLayout>
  );
}

export default BlockDriver;
