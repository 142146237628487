import React from "react";
import styles from "./styles.module.scss";
import LandingPageFooter from "layouts/LandingPageFooter";
import LandingPageHeader from "layouts/LandingPageHeader";

function TermsAndConditions() {
  return (
    <>
      <LandingPageHeader isBoxShadow={true} />
      <div className={styles.privacyPolicyWrapper}>
        <img className={styles.bgImage} src="\Images\PrivacyPolicyBg.png" />
        <p className={styles.privacyPolicyHeading}>Terms & Conditions</p>
        <div className={styles.privacyPolicyContent}>
          <p>1. Overview</p>

          <p>
            These terms & conditions apply to using the Cabber website and
            smartphone applications.
          </p>

          <p>
            Use of the website and app indicates that you have read, understood,
            and agree to respect these terms & conditions.
          </p>

          <p>
            If you disagree with these terms & conditions, you may exercise your
            freedom to use an alternative platform.
          </p>

          <p>
            If we must contact any advertiser, we will do so via the email
            address used for the app subscription.
          </p>

          <p>2. Privacy Notice</p>

          <p>
            CABBER USA will only share Driver / Operator account details with
            third parties if CABBER USA is required to provide such information
            to a law enforcement agency as part of an investigation.
          </p>

          <p>3. Charges and Payment</p>

          <p>
            Drivers / Operators provide an email address and password to create
            an advertiser account and activate their app subscription.
          </p>

          <p>
            App subscriptions are charged monthly via reputable payment gateways
            (Google Pay or Apple Pay).
          </p>

          <p>
            For example, the monthly app subscription charge may be modified
            occasionally to track inflation.
          </p>

          <p>
            Subscribers will be informed before any price changes and allowed to
            terminate their subscription if they do not wish to continue
            advertising past the end of that month.
          </p>

          <p>
            Unless expressly stated otherwise, the monthly subscription fee
            includes applicable taxes, including VAT.
          </p>

          <p>
            Unlike other ride-hailing apps, Cabber USA does not process the fare
            payments or take a commission from the transport service provider's
            fare/charges, meaning there is no form of driver employment,
            contractual or otherwise.
          </p>

          <p>4. Cancellation / Termination</p>

          <p>
            The Cabber USA app includes a facility to delete the user account;
            this will not terminate the subscription associated with that email
            address, so the subscriber should terminate the subscription in
            their own Google or Apple accounts to ensure that no further monthly
            charges occur. Note: For traceability and record-keeping purposes,
            the account details may be retained on the Cabber USA user account
            database for an undefined period.
          </p>

          <p>
            There can be no pro-rata refund for the remaining month after the
            cancellation.
          </p>

          <p>
            The account username will be relinquished when an account is
            deleted.
          </p>

          <p>
            Subscription payment transaction failures will cause the user
            account to be suspended, making the app inoperable. The payment
            gateways will automatically create multiple attempts to complete the
            scheduled subscription payment transaction; however, the account
            will be terminated after a reasonable number of repeated attempts.
          </p>

          <p>
            Reactivating accounts may require staffing that is not ordinarily
            available within the headcount of CABBER USA organization structure,
            so it is not guaranteed to be possible.
          </p>

          <p>
            Subscribers will face difficulties when attempting to use an email
            address already associated with another user account, suspended or
            otherwise.
          </p>

          <p>
            CABBER USA may suspend or terminate any user account without notice
            in case of alleged abuse, misuse, fraud, illegitimate operation, or
            infringement of these terms & conditions.
          </p>

          <p>
            CABBER USA reserves the right to report severe allegations to law
            enforcement authorities.
          </p>

          <p>
            CABBER USA reserves the right to take legal action against abusers
            where damage to the CABBER enterprise has occurred.
          </p>

          <p>5. Subscriber liability and Indemnity</p>

          <p>
            Subscribers are liable for any damage suffered by Cabber USA as a
            result of their violation of these Terms or misuse of the
            subscription app or website, including the following:
          </p>

          <p>A. Reputational damage evidenced via social media platforms.</p>

          <p>
            B. Any defamation aimed at Cabber USA or custom attained via our
            website.
          </p>

          <p>
            C. Costs incurred for any remedial actions necessitated by the
            contravening actions of an advertiser.
          </p>

          <p>6. Limitation of Liability</p>

          <p>
            Any liability that cannot legally be limited or excluded or alters
            your rights as a consumer that cannot be excluded under applicable
            law.
          </p>

          <p>CABBER USA is not liable for the following:</p>

          <p>
            A. Transportation services you have provided to your customers as an
            independent commercial operation.
          </p>

          <p>
            B. Damages or losses you may have incurred from work attained via
            our advertising platform.
          </p>

          <p>C. The accuracy of your advertised information.</p>

          <p>Any eventuality beyond our reasonable control</p>

          <p>
            CABBER USA's liability limit shall not exceed £500 unless expressly
            agreed in writing.
          </p>

          <p>
            CABBER.com accepts no liability for losses or damages incurred by
            third parties connected via the CABBER platform.
          </p>

          <p>
            Drivers using the CABBER™ app should acknowledge that law
            enforcement agencies are entitled to use the CABBER™ website to
            conduct random spot checks. Therefore, we can produce the necessary
            operating license (s) upon request.
          </p>

          <p>7. Abuse of the CABBER USA platform:</p>

          <p>
            Drivers / Operators who advertise via the CABBER platform are
            responsible for their operational legality wherever they operate.
            Cabber USA cannot be held liable for app users who choose to act
            illegally.
          </p>

          <p>8. Phone service integrity:</p>

          <p>
            Advertisers cannot hold Cabber USA liable for unsolicited nuisance
            phone calls that may have resulted from advertising on CABBER USA.
          </p>

          <p>
            Drivers / Operators using the CABBER USA service are advised to use
            a business phone number, not their mobile phone number.
          </p>

          <p>9. Intellectual property:</p>

          <p>
            We intend to protect our company and trademark via appropriate legal
            means.
          </p>

          <p>
            The CABBER Brand Identity: 'CABBER will become a registered
            trademark in the state structure of the United States.
          </p>

          <p>
            With the kindest intention, we discourage web-squatting of CABBER
            USA internationally. We advise that domain name-blocking or
            web-squatting will be dealt with through legal channels as the
            trademark is expanded into respective territories.
          </p>

          <p>
            The CABBER USA System Architecture and digital infrastructure
            constitute a unique 'concrete, useful, and tangible result.'
            Therefore, our technologically advanced strategy supporting our live
            map, including the subscription-based smartphone application, and
            associated back-end service interactions, including the searchable
            username database, shall not be emulated or copied by third parties
            without the risk of prosecution.
          </p>

          <p>10. Advertiser Usernames:</p>

          <p>
            Usernames can be between three and twenty alphanumeric characters in
            length and may also contain underscores.
          </p>

          <p>
            Usernames are ordinarily expected to resemble the commercial
            identity of the taxi company. However, a taxi company may choose an
            unrelated, memorable username to accrue a loyal customer base within
            their region of operation.
          </p>

          <p>
            Usernames are not guaranteed to remain permanently associated with a
            user account, primarily based upon an email address; however, Cabber
            USA will endeavor to maintain a user's account 'Ownership' of
            username if a subscription is supported because we recognize their
            embedded value as loyalty is accrued in the long term.
          </p>

          <p>
            Cabber USA may amend an account username unilaterally if presented
            with a justifiable reason, for example, if a trademark holder
            presents evidence of an infringement.
          </p>

          <p>
            Account usernames are not ordinarily transferable between accounts
            by any facility within the CABBER USA internal business systems.
          </p>

          <p>Usernames should not:</p>

          <ul>
            <li>Contain offensive or worrisome terms.</li>
            <li>
              Impersonate un-associated persons, businesses,
              institutions/organizations, etc.
            </li>
            <li>
              Squat: Username squatting for profit is discouraged by 8.3 above.
            </li>
            <li>
              Anticompetitive username blocking is prevented by 8.3 above.
            </li>
            <li>Infringe trademarks.</li>
            <li>Raise political issues.</li>
            <li>Cause emotional discomfort to others.</li>
            <li>Be misleading.</li>
            <li>Incur mind peril.</li>
          </ul>

          <p>
            Users who attempt to transfer their "Ownership" of usernames for
            profit do so at their own risk.
          </p>

          <p>11. Non-Transferability Stipulation</p>

          <p>Cabber USA does not permit app users to share their accounts.</p>

          <p>Drivers must not allow third parties to use their app.</p>

          <p>Drivers must keep their login details confidential.</p>

          <p>
            They must inform us immediately if their account has been
            compromised or if they believe another person has used it.
          </p>

          <p>12. Dispute Resolution Process:</p>

          <p>
            In the event of a dispute, we would ask you to raise a complaint
            using the email address support@Cabber.com USA website. In the
            unlikely event that we cannot resolve the dispute, we will work with
            you to discuss a way forward to resolve the dispute, which may
            include mediation. Our right to refer the dispute to a court is
            expressly reserved.
          </p>

          <p>13. Governing Law and Jurisdiction</p>

          <p>
            These Terms shall be exclusively governed by and construed under the
            laws of the United States.
          </p>

          <p>14. Problems</p>

          <p>
            If there is a problem with the app or website or a complaint, don't
            hesitate to contact us via email at{" "}
            <a href={`mailto:info@cabberusa.com`}>info@cabberusa.com</a> or at
            <a href="https://www.cabberusa.com" target="_blank">
              www.cabberusa.com
            </a>
            .
          </p>
        </div>
      </div>
      <LandingPageFooter />
    </>
  );
}

export default TermsAndConditions;
