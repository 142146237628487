import * as Yup from "yup";
const phoneNumberPattern = /^[+-]?\d+(\.\d+)?( |$)/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const nameRegex = /^[A-Za-z]+$/;

export const validateAddDriver = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("Last Name is required"),
  driverEmail: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is required"),
  // confirmDriverEmail: Yup.string()
  //   .oneOf([Yup.ref("driverEmail"), null], "Emails must match")
  //   .required("Confirm Email is required"),
  driverPhoneNumber: Yup.string().required("Phone Number is required"),
  confirmDriverPhoneNumber: Yup.string()
    .oneOf([Yup.ref("driverPhoneNumber"), null], "Phone numbers must match")
    .required("Confirm Phone Number is required"),
});
