import Tabs from 'components/Tabs'
import MainLayout from 'layouts/MainLayout'
import React from 'react'

function AllDrivers() {
  return (
    <MainLayout>
        <Tabs/>
    </MainLayout>
  )
}

export default AllDrivers