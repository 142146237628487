import axios from "axios";
import { backendEndpoints } from "constants/Backend";

export const contactUs = async (obj) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.contactUs}`,
      obj,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: process.env.REACT_APP_API_ACCESS_TOKEN,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
