export const backendEndpoints = {
  loginWithEmail: "/auth/login",
  logoutUser: "/user/logout",
  getUserInfo: "/user/get-basic-detail",
  forgetPassword: "/auth/forgot/send-reset-otp",
  changePassword: "/auth/forgot/reset-password",
  contactUs: "/customer/contact-us",

  //SuperAdmin
  addCompany: "/user/register-user",
  getAllCompanies: "/user/get-list",
  blockCompany: "/user/block-user",
  updateUserProfile: "/user/update-profile",
  payTheInvoice: "/user/pay-the-invoice",

  //Admin
  AddDrivers: "/driver/add-driver",
  getDriverList: "/driver/get-company-wise-driver-list",
  checkDriverDuplication: "/driver/check-drivers-duplication",
  blockDriver: "/driver/block-driver",
  deleteDriver: "/driver/delete-driver",
  editDriver: "/driver/edit-profile",
  getLastUnpaidAmount: "/user/get-last-unpaid-amount",

  //Payment
  createPaymentIntent: "/user/create-the-payment-secret",
  getFreeTrailInvoice: "/user/generate-the-invoice",
};
