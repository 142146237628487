import React from 'react'
import styles from "./styles.module.scss"
import { Modal } from 'antd'
import CustomButton from 'components/Button'
import { ReactComponent as FolderIcon } from "assets/icons/FolderIcon.svg";

function CsvModal({isModalOpen, handleOk, handleCancel}) {
  return (
    <Modal
    centered
    open={isModalOpen}
    onOk={handleOk}
    onCancel={handleCancel}
    width={"56%"}
    maskClosable={false}
    footer={[
      <div
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <button
        className={styles.btnOk}
          key="customButton"
          onClick={handleOk}
        >
          Done
        </button>
      </div>,
    ]}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      <FolderIcon />
      <p className={styles.modalText}>
        Your File Uploaded <span>Successfully!</span>
      </p>
    </div>
  </Modal>
  )
}

export default CsvModal