import React, { useEffect, useState } from "react";
import { notification } from "antd";
import MainLayout from "layouts/MainLayout";
import AddCompanyContainer from "containers/AddCompanyContainer";
import CompanyTableContainer from "containers/CompanyTableContainer";
import axios from "axios";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import { registerCompany } from "APIs/Services";

function AddCompany() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    tokenUser,
    allCompaniesList,
    getAllCompanies,
    handleUnautherized,
    setAllCompaniesList,
  } = useAuth();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  // const registerCompany = async (data) => {
  //   setIsLoading(true);
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.addCompany}`,
  //       data,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setIsLoading(false);
  //     return res;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Faileddd",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : error?.response?.data?.errors?.email[0],
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  const addCompanyFunc = async (data) => {
    setIsLoading(true);
    const response = await registerCompany(token, data);

    if (response?.status === 200) {
      notification.success({
        message: "Success",
        description: response?.data?.message,
      });
      getAllCompanies(token);
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Faileddd",
        description:
          response?.status === 401
            ? "Session Expired"
            : response?.data?.errors?.email[0],
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const getAllCompanyList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getAllCompanies}`,
        { limit: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllCompaniesList(response?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllCompanyList();
  }, []);

  return (
    <MainLayout>
      <AddCompanyContainer
        isLoading={isLoading}
        registerCompany={addCompanyFunc}
      />
      <CompanyTableContainer data={allCompaniesList} />
    </MainLayout>
  );
}

export default AddCompany;
