import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { backendEndpoints } from "constants/Backend";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [allDriversList, setAllDriversList] = useState(null);
  const [allCompaniesList, setAllCompaniesList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenUser, setTokenUser] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const [isPayInvoiceModalOpen, setIsPayInvoiceModalOpen] = useState(null);
  const [searchResult, setSearchResult] = useState();
  const [searchInputText, setSearchInputText] = useState();
  const [imageSrc, setImageSrc] = useState();

  const userToken = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const userId = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;
  const navigate = useNavigate();

  useEffect(() => {
    verifyToken();
    getAllDrivers();
    getAllCompanies();
  }, []);

  const getAllCompaniesBySearch = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getAllCompanies}`,
        { limit: 10000, name: searchInputText },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken ? userToken : token}`,
          },
        }
      );

      setSearchResult(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDriversBySearch = async (token, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getDriverList}`,
        { user_id: userId ? userId : id, limit: 10000, name: searchInputText },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken ? userToken : token}`,
          },
        }
      );
      setSearchResult(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCompanies = async (token) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getAllCompanies}`,
        { limit: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken ? userToken : token}`,
          },
        }
      );

      setAllCompaniesList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDrivers = async (token, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getDriverList}`,
        { user_id: userId ? userId : id, limit: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken ? userToken : token}`,
          },
        }
      );
      setAllDriversList(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBasicInfo = async () => {
    try {
      const userResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getUserInfo}`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setUser(userResponse?.data?.data);
    } catch (error) {
      console.log("user is not loggedIn");
    }
  };

  const verifyToken = async () => {
    // localStorage.getItem()
    if (localStorage.getItem("userToken")) {
      setIsLoading(true);
      await getBasicInfo();
    }
    setIsLoading(false);
  };

  const login = async (userObj) => {
    try {
      const loginApiToken = process.env.REACT_APP_API_ACCESS_TOKEN;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.loginWithEmail}`,
        userObj,
        {
          headers: {
            Authorization: `${loginApiToken}`,
          },
        }
      );
      const userToken = response?.data?.data?.token;
      const userId = response?.data?.data?.user?.id;
      localStorage.setItem("userToken", userToken);
      localStorage.setItem("userId", userId);
      setTokenUser(userToken);
      setIdUser(userId);

      const userResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getUserInfo}`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      await getAllCompanies(userToken);
      setUser(userResponse?.data?.data);
      notification.success({
        message: `Welcome ${userResponse?.data?.data?.firstName}`,
      });
      navigate(
        userResponse?.data?.data?.roleName === "superadmin"
          ? "/addcompany"
          : "/dashboard"
      );
      await getAllDrivers(userToken, userId);
      console.log("User Data:", userResponse.data);
    } catch (error) {
      console.error("Login Error:", error);
      notification.error({
        message: "Authentication Failed",
        description: error?.response?.data?.message,
      });
    }
  };

  const logoutUser = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.logoutUser}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    await logoutUser();
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    setIdUser(null);
    setTokenUser(null);
    setUser(null);
    setImageSrc(null);
    setIsLoading(false);
    setIsPayInvoiceModalOpen(null);
    notification.success({
      message: "Logout Successfully",
    });
    navigate("/login");
  };

  const handleUnautherized = async (status) => {
    if (status === 401) {
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");
      setUser(null);
      setIdUser(null);
      setTokenUser(null);
      setImageSrc(null);
      setIsPayInvoiceModalOpen(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        user,
        isLoading,
        logout,
        tokenUser,
        idUser,
        allDriversList,
        setAllDriversList,
        getAllDrivers,
        allCompaniesList,
        setAllCompaniesList,
        getAllCompanies,
        getBasicInfo,
        isPayInvoiceModalOpen,
        setIsPayInvoiceModalOpen,
        getAllCompaniesBySearch,
        setSearchResult,
        searchResult,
        searchInputText,
        setSearchInputText,
        getAllDriversBySearch,
        imageSrc,
        setImageSrc,
        handleUnautherized,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
