import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { notification, Tabs } from "antd";
import axios from "axios";
import MainLayout from "layouts/MainLayout";
import styles from "./styles.module.scss";
import { Table, Input } from "antd";
import { TbFolderExclamation } from "react-icons/tb";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import BlockCompanyModal from "components/Modals/BlockCompanyModal";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import ActionButton from "components/ActionButton";
import { blockCompany } from "APIs/Services";

function BlockCompany() {
  const { tokenUser, allCompaniesList, getAllCompanies, handleUnautherized } =
    useAuth();
  const [isBlockCompanyModalOpen, setIsBlockCompanyModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const Navigate = useNavigate();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;

  // const blockCompany = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.blockCompany}`,
  //       {
  //         user_id: companyData.id,
  //         is_blocked: !companyData.isBlocked,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  const showBlockCompanyModal = () => {
    setIsBlockCompanyModalOpen(true);
  };

  const handleBlockCompanyOk = async () => {
    // await blockCompany();
    // await getAllCompanies();
    // setIsBlockCompanyModalOpen(false);
    setIsLoading(true);
    const response = await blockCompany(token, {
      user_id: companyData.id,
      is_blocked: !companyData.isBlocked,
    });

    if (response?.status === 200) {
      await getAllCompanies();
      notification.success({
        message: "Success",
        description: "Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
    setIsBlockCompanyModalOpen(false);
  };

  const handleBlockCompanyCancel = () => {
    setIsBlockCompanyModalOpen(false);
  };

  const ActiveDriversColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers`}
          onClick={() =>
            localStorage.setItem("specificComDetails", JSON.stringify(record))
          }
        >
          {text}
        </Link>
      ),
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: 120,
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      render: (text, record) => (
        <div>
          <p>{`${(record?.plan).toUpperCase()}`}</p>
        </div>
      ),
    },
    {
      title: "Remaining Days",
      dataIndex: "planRemainingDays",
      width: 230,
      sorter: (a, b) => a.planRemainingDays - b.planRemainingDays,
      render: (text, record) => (
        <div>
          <p>
            {record?.plan === "free" ? `${record?.planRemainingDays}` : "--"}
          </p>
        </div>
      ),
    },
    {
      title: "Grace Period",
      dataIndex: "planGraceDays",
      width: 210,
      sorter: (a, b) => a.planGraceDays - b.planGraceDays,
      render: (text, record) => (
        <div>
          <p>{record?.plan === "paid" ? `${record?.planGraceDays}` : "--"}</p>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: 210,
      sorter: (a, b) => a.mobileNumber - b.mobileNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Driver",
      dataIndex: "totalDrivers",
      width: 120,
      sorter: (a, b) => a.totalDrivers - b.totalDrivers,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showBlockCompanyModal();
              setCompanyData(record);
            }}
            icon={
              record?.isBlocked === false ? <MdOutlineBlock size={15} /> : ""
            }
            text={record?.isBlocked === false ? "Block" : "Blocked"}
            isBlocked={record?.isBlocked}
          />
          <ActionButton
            onClick={() => {
              Navigate(`/companydrivers`);
              localStorage.setItem(
                "specificComDetails",
                JSON.stringify(record)
              );
            }}
            icon={<AiFillEye size={15} />}
            text="View"
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "All Companies",
    },
    {
      key: "2",
      label: "Edit Company",
    },
    {
      key: "3",
      label: "Block Company",
      children: (
        <div className={styles.mainWrapper}>
          {allCompaniesList?.length > 0 ? (
            <div className={styles.dataTableWrapper}>
              <div className={styles.tableWrapperHeader}>
                <p className={styles.heading}>Block Company Right Now</p>
                <Input.Search
                  placeholder="Search here..."
                  style={{ width: "40%" }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <Table
                columns={ActiveDriversColumns}
                dataSource={allCompaniesList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.mobileNumber,
                  email: item.email,
                  totalAmount: item.paidAmount,
                }))}
                scroll={{
                  x: 1700,
                }}
                className="custom-table"
              />
            </div>
          ) : (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          )}
        </div>
      ),
    },
  ];

  const onChange = (key) => {
    if (key === "1") {
      console.log(key);
      Navigate("/allcompanies");
    } else if (key === "2") {
      console.log(key);
      Navigate("/editcompany");
    }
  };

  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="3"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
          onChange={onChange}
        />
        <BlockCompanyModal
          handleBlockCompanyCancel={handleBlockCompanyCancel}
          handleBlockCompanyOk={handleBlockCompanyOk}
          isBlockCompanyModalOpen={isBlockCompanyModalOpen}
          blockStatus={companyData?.isBlocked}
          isLoading={isLoading}
        />
      </div>
    </MainLayout>
  );
}

export default BlockCompany;
