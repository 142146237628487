import React from "react";
import styles from "./styles.module.scss";
import CustomInput from "components/CustomInput";
import CustomButton from "components/Button";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { validateAddCompany } from "yupSchema";
import PhoneInputField from "components/PhoneNumberInput";

function AddCompanyContainer({ registerCompany, isLoading }) {
  const initialValues = {
    firstName: "",
    confirmCompanyName: "",
    companyEmail: "",
    confirmCompanyEmail: "",
    companyPhoneNumber: "",
    confirmCompanyPhoneNumber: "",
    companyAddress: "",
    // confirmCompanyAddress: "",
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.addCompanyWrapper}>
      <p>Add Company</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validateAddCompany}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          const data = {
            first_name: values.firstName,
            last_name: "",
            address: values.companyAddress,
            phone: values.companyPhoneNumber,
            user_name: `${values.firstName}${
              values.lastName
            }${Math.random()}`.replace(/\s/g, ""),
            email: values.companyEmail,
          };
          await registerCompany(data);
          actions.resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={styles.inputFieldsWrapper}>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Company Name"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error"
                />
              </div>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="text"
                  id="companyAddress"
                  name="companyAddress"
                  placeholder="Company Address"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="companyAddress"
                  component="div"
                  className="error"
                />
              </div>
              {/* <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="text"
                  id="confirmCompanyName"
                  name="confirmCompanyName"
                  placeholder="Confirm Company Name"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="confirmCompanyName"
                  component="div"
                  className="error"
                />
              </div> */}
            </div>
            <div className={styles.inputFieldsWrapper}>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="email"
                  id="companyEmail"
                  name="companyEmail"
                  placeholder="Company Email"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="companyEmail"
                  component="div"
                  className="error"
                />
              </div>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="email"
                  id="confirmCompanyEmail"
                  name="confirmCompanyEmail"
                  onPaste={(e) => preventCopyPaste(e)}
                  placeholder="Confirm Email"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="confirmCompanyEmail"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className={styles.inputFieldsWrapper}>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={PhoneInputField}
                  type="text"
                  id="companyPhoneNumber"
                  name="companyPhoneNumber"
                  placeholder="Company Phone Number"
                  style={{ height: "42px" }}
                />
                <ErrorMessage
                  name="companyPhoneNumber"
                  component="div"
                  className="error"
                />
              </div>
              <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={PhoneInputField}
                  type="text"
                  id="confirmCompanyPhoneNumber"
                  name="confirmCompanyPhoneNumber"
                  placeholder="Confirm Phone Number"
                  style={{ height: "42px" }}
                  onPaste={(e) => preventCopyPaste(e)}
                />
                <ErrorMessage
                  name="confirmCompanyPhoneNumber"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className={styles.inputFieldsWrapper}>
              {/* <div className={styles.inputAndErrorWrapper}>
                <Field
                  component={CustomInput}
                  type="text"
                  id="confirmCompanyAddress"
                  name="confirmCompanyAddress"
                  placeholder="Confirm Address"
                  style={{ height: "42px"}}
                />
                <ErrorMessage
                  name="confirmCompanyAddress"
                  component="div"
                  className="error"
                />
              </div> */}
            </div>

            <CustomButton
              style={{
                width: "100px",
                height: "38px",
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "7px",
                alignSelf: "flex-end",
                marginTop: "7px",
              }}
              type="submit"
              isLoading={isLoading}
              text="Add"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddCompanyContainer;
