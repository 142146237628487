import React, { useState } from "react";
import MainLayout from "layouts/MainLayout";
import axios from "axios";
import styles from "./styles.module.scss";
import { Table, notification, Input, Tabs } from "antd";
import { TbFolderExclamation } from "react-icons/tb";
import ViewDetailsModal from "components/Modals/ViewDetailsModel";
import { BiSolidEditAlt } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import { useAuth } from "context/AuthProvider";
import EditAddedDriverModal from "components/Modals/EditAddedDriverModal";
import { backendEndpoints } from "constants/Backend";
import ActionButton from "components/ActionButton";
import { useNavigate } from "react-router-dom";
import { editDriver } from "APIs/Services";

function EditDrivers() {
  const {
    allDriversList,
    setAllDriversList,
    getAllDrivers,
    idUser,
    tokenUser,
    user,
    handleUnautherized,
  } = useAuth();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [viewDriver, setViewDriver] = useState();
  const [isLoading, setIsLoading] = useState();
  const [searchText, setSearchText] = useState("");

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;
  const navigate = useNavigate();

  const showEditModalWithData = (item) => {
    const itemIndex = allDriversList.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditModal();
  };

  const updateAllDriversData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...allDriversList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setAllDriversList(updatedCsvFileDataTemp);
  };

  // const editDriverDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.editDriver}`,
  //       {
  //         user_id: id,
  //         driver_id: item.id,
  //         email: item.email,
  //         first_name: item.firstName,
  //         last_name: item.lastName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Driver Details Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };
  const showViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const handleViewDetailsOk = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalOpen(false);
    setViewDriver(null);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = async (updatedData) => {
    // await editDriverDetails(updatedData);
    setIsLoading(true);
    const response = await editDriver(token, {
      user_id: id,
      driver_id: updatedData.id,
      email: updatedData.email,
      first_name: updatedData.firstName,
      last_name: updatedData.lastName,
    });
    await getAllDrivers();
    setIsEditModalOpen(false);
    if (response?.status === 200) {
      notification.success({
        message: "Success",
        description: "Driver Details Updated Successfully",
      });
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 250,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 200,
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
          />
          <ActionButton
            onClick={() => {
              showViewDetailsModal();
              setViewDriver(record);
            }}
            icon={<AiFillEye size={15} />}
            text="View"
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "All Drivers",
    },
    {
      key: "2",
      label: "Edit Drivers",
      children: (
        <div className={styles.mainWrapper}>
          {allDriversList?.length === 0 ? (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          ) : (
            <div className={styles.dataTableWrapper}>
              <div className={styles.tableWrapperHeader}>
                <p className={styles.heading}>Edit Driver Right Now</p>
                <Input.Search
                  placeholder="Search here..."
                  style={{
                    width: "40%",
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <Table
                columns={ActiveDriversColumns}
                dataSource={allDriversList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.phone,
                  email: item.email,
                }))}
                scroll={{
                  x: 950,
                }}
                className="custom-table"
              />
            </div>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Delete Drivers",
    },
  ];

  const onChange = (key) => {
    if (key === "1") {
      console.log(key);
      navigate("/alldrivers");
    } else if (key === "3") {
      console.log(key);
      navigate("/blockdriver");
    }
  };

  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="2"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
          onChange={onChange}
        />
        <EditAddedDriverModal
          handleEditAddedDriverCancel={handleEditCancel}
          handleEditAddedDriverOk={handleEditOk}
          isEditAddedDriverModalOpen={isEditModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCsvFileData={updateAllDriversData}
          isLoading={isLoading}
        />
        <ViewDetailsModal
          handleViewDetailsCancel={handleViewDetailsCancel}
          handleViewDetailsOk={handleViewDetailsOk}
          isViewDetailsModalOpen={isViewDetailsModalOpen}
          viewDriverData={viewDriver}
          companyName={user?.firstName}
          companyAddress={user?.address}
          companyNumber={user?.mobileNumber}
        />
      </div>
    </MainLayout>
  );
}

export default EditDrivers;
