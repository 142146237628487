import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { backendEndpoints } from "constants/Backend";
import styles from "./styles.module.scss";
import Header from "layouts/Header";
import SideBar from "layouts/Sidebar";
import { useAuth } from "context";
import PayInvoiceModal from "components/Modals/PayInvoiceModal";
import PaymentFormWrapper from "pages/Payment";
import SearchBarContainer from "containers/SearchBarContainer";
import { notification } from "antd";
import ConfirmLogoutModal from "components/Modals/ConfirmLogoutModal";
import ContactAdminModal from "components/Modals/ContactAdminModal";

function MainLayout({ children }) {
  const {
    tokenUser,
    user,
    isPayInvoiceModalOpen,
    searchResult,
    setSearchResult,
    setIsPayInvoiceModalOpen,
    searchInputText,
    setSearchInputText,
    logout
  } = useAuth();
  const [paymentIntent, setPaymentIntent] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState(false);
  const [checkboxCheck, setCheckboxCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] = useState(false);
  const [isContactAdminModalOpen, setIsContactAdminModalOpen] = useState(false);

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;


    const handleShowConfirmLogout= () => {
      setIsConfirmLogoutModalOpen(true);
    }
    const handleConfirmLogoutOk = async () => {
      await logout();
      setIsConfirmLogoutModalOpen(false);
    };
  
    const handleConfirmLogoutCancel = () => {
      setIsConfirmLogoutModalOpen(false);
    };

    const showContactAdminModal = () => {
      setIsContactAdminModalOpen(true);
    };
  
    const handleContactAdminOk = async () => {
          setIsContactAdminModalOpen(false);
    };
  
    const handleContactAdminCancel = () => {
        setIsContactAdminModalOpen(false);
    };

  const showPayInvoiceModal = () => {
    setIsPayInvoiceModalOpen(true);
  };

  const handlePayInvoiceOk = async () => {
    if (!checkboxCheck) {
      notification.error({
        message: "Accept Terms and Conditions",
      });
      return null;
    } else {
      setIsLoading(true);
      const res = await getPaymentIntent();
      if (res?.status === 200) {
        console.log("res", res);
        // localStorage.setItem("invoiceData", JSON.stringify(res));
        setPaymentIntent(res?.data?.data?.client_secret);
        setInvoiceStatus(true);
        setIsLoading(false);
        setIsPayInvoiceModalOpen(false);
      } else {
        setIsLoading(false);
        alert("reload the page");
      }
    }
  };

  const handlePayInvoiceCancel = () => {
    if (user?.dueDay <= 0) {
      return null;
    } else {
      setIsPayInvoiceModalOpen(false);
    }
  };

  const stripeChargesPercentage =
    (2.9 / 100) * Number(user?.unPaidInvoices[0]?.amount);
  const pfAndStripe = Number(stripeChargesPercentage + 0.3).toFixed(2);
  const totalCharges = (
    Number(user?.unPaidInvoices[0]?.amount) + Number(pfAndStripe)
  ).toFixed(2);

  const getPaymentIntent = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.createPaymentIntent}`,
        {
          user_id: user?.id,
          invoice_id: user?.unPaidInvoices[0]?.id,
          amount: totalCharges,
          subAmount: user?.unPaidInvoices[0]?.subAmount,
          subTaxes: user?.unPaidInvoices[0]?.subTaxes,
          subCharges: pfAndStripe,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log("Error".error);
    }
  };

  const condition =
    isPayInvoiceModalOpen === false
      ? isPayInvoiceModalOpen
      : user?.isUnpaid || user?.isBlocked;


  const contentstyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  return (
    <div className={styles.mainLayoutWrapper}>
      <Header
        searchInputText={searchInputText}
        setSearchInputText={setSearchInputText}
        setSearchResult={setSearchResult}
      />
      <div className={styles.innerMain}>
        <div className={styles.sidebarPart}>
          <SideBar />
        </div>
        <div style={contentstyle} className={styles.content}>
          {paymentIntent ? (
            <div className={styles.dashboardPaymentForm}>
              <PaymentFormWrapper
                paymentIntent={paymentIntent}
                invoiceStatus={invoiceStatus}
              />
              <div className={styles.invoiceSection}>
                <p style={{ selfAlign: "center" }}>Invoice</p>
                <p>
                  No. of Driver(s):{" "}
                  <span>{user?.unPaidInvoices[0]?.totalDrivers}</span>
                </p>
                <p>
                  Lisence Fee:{" "}
                  <span>{`$ ${user?.unPaidInvoices[0]?.subAmount}`}</span>
                </p>
                <p>
                  Tax (Lisence Fee + 6%):{" "}
                  <span>{`$ ${user?.unPaidInvoices[0]?.subTaxes}`}</span>
                </p>
                <p>
                  Sub Total:{" "}
                  <span>{`$ ${
                    Number(user?.unPaidInvoices[0]?.subTaxes) +
                    Number(user?.unPaidInvoices[0]?.subAmount)
                  }`}</span>
                </p>
                <p>
                  Transaction Charges: <span>{`$ ${pfAndStripe}`}</span>
                </p>

                {/* {lastInvoive?.previousAmount > 0 && (
              <p>
                Unpaid Invoice Amount:{" "}
                <span>${pfAndStripe}</span>
              </p>
            )} */}

                <div className={styles.divider}></div>
                <p className={styles.totalPayable}>
                  Total Payable: <span>{`$ ${totalCharges}`}</span>
                </p>
              </div>
            </div>
          ) : searchInputText ? (
            <SearchBarContainer
              setSearchResult={setSearchResult}
              searchResult={searchResult}
            />
          ) : (
            children
          )}

          <div className={styles.layoutFooter}>
            <p>
              Copyright © 2023 Cabber, All Rights Reserved.{" "}
              <Link
                style={{ color: "#fff" }}
                target="_blank"
                to={"/privacypolicy"}
              >
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link
                style={{ color: "#fff" }}
                target="_blank"
                to={"/termsandconditions"}
              >
                Terms & Conditions
              </Link>
            </p>
          </div>
        </div>
      </div>

      <ContactAdminModal
        isContactAdminModalOpen={isContactAdminModalOpen}
        handleContactAdminOk={handleContactAdminOk}
        handleContactAdminCancel={handleContactAdminCancel}
      />

      <ConfirmLogoutModal
        isConfirmLogoutModalOpen={isConfirmLogoutModalOpen}
        handleConfirmLogoutOk={handleConfirmLogoutOk}
        handleConfirmLogoutCancel={handleConfirmLogoutCancel}
      />

      <PayInvoiceModal
        // isPayInvoiceModalOpen={isUserBlocked}
        isPayInvoiceModalOpen={condition}
        handlePayInvoiceOk={handlePayInvoiceOk}
        handlePayInvoiceCancel={handlePayInvoiceCancel}
        userData={user}
        isLoading={isLoading}
        checkboxCheck={checkboxCheck}
        toggleCheckBox={setCheckboxCheck}
        handleShowConfirmLogout={handleShowConfirmLogout}
        showContactAdminModal={showContactAdminModal}
      />
    </div>
  );
}

export default MainLayout;
