import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import { Checkbox } from "antd";
import CustomButton from "components/Button";
import PaymentTable from "components/Tables/PaymentTable";
import PaymentForm from "pages/Payment/PaymentForm";
import PaymentFormWrapper from "pages/Payment";
import { Link } from "react-router-dom";
import CancelButton from "components/CancelButton";

function PaymentModal({
  isPaymentModalOpen,
  handlePaymentOk,
  handlePaymentCancel,
  data,
  perDriverCost,
  lastUnpaidInvoice,
  checkboxCheck,
  toggleCheckBox,
  payBtnLoading,
  totalPayAble,
  licenseFee,
  percentageOfLicenseFee,
  licenseFeeAndTax,
  pfAndStripe,
  totalCharges,
}) {
  return (
    <Modal
      centered
      open={isPaymentModalOpen}
      onOk={handlePaymentOk}
      onCancel={handlePaymentCancel}
      width={"85%"}
      maskClosable={false}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "88%",
          }}
        >
          <CustomButton
            text={"Pay Now"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
              // color: "white",
              // background: "#FFB715",
            }}
            onClick={handlePaymentOk}
            isLoading={payBtnLoading}
            disabled={payBtnLoading}
          >
            Custom Button
          </CustomButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "88%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <PaymentTable
          perDriverCost={perDriverCost}
          totalPayAble={totalPayAble}
          data={data}
          licenseFee={licenseFee}
        />
        {totalPayAble < 0.5 ? (
          <p className={styles.warningText}>
            <span>Note:</span> Minimal Payable will be 0.5$
          </p>
        ) : null}
        <div className={styles.termsAndBillingWrapper}>
          <div className={styles.termsAndConditionsWrapper}>
            <div style={{ display: "flex" }}>
              <Checkbox
                className="custom-checkbox"
                // checked={true}
                onChange={(e) => toggleCheckBox(!checkboxCheck)}
                checked={checkboxCheck}
              >
                <p>Terms & Conditions</p>
              </Checkbox>
              {/* <input
                type="checkbox"
                id="myCheckbox"
                checked={checkboxCheck}
                onChange={(e) => toggleCheckBox(!checkboxCheck)}
                style={{
                  backgroundColor: checkboxCheck ? "#ffff00" : "#ffffff",
                  border: "none",
                }}
              />
              <label for="myCheckbox">Click me</label> */}
            </div>

            <p>
              By clicking 'I agree,' you acknowledge that you have read and
              understood the{" "}
              <Link
                style={{ textDecoration: "none", color: "#ffaa00" }}
                to={"/termsandconditions"}
                target="_blank"
              >
                terms and conditions
              </Link>{" "}
              and agree to be bound by them
            </p>
          </div>
          <div className={styles.billingDetailsWrapper}>
            <div className={styles.subTotal}>
              <p>Lisence Fee:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${licenseFee}`}</p>
            </div>
            <div className={styles.subTotal}>
              <p>Tax (Lisence Fee + 6%):</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${percentageOfLicenseFee?.toFixed(2)}`}</p>
            </div>
            <div className={styles.subTotal}>
              <p>Sub Total:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${licenseFeeAndTax}`}</p>
            </div>
            <div className={styles.subTotal}>
              <p>Transaction Charges:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${pfAndStripe}`}</p>
            </div>

            {lastUnpaidInvoice?.previousAmount ? (
              <>              
              <div className={styles.subTotal}><p style={{ color: "#000" }}>Unpaid Invoice</p></div>
              <div className={styles.billingDetailsWrapper}>
                <div className={styles.subTotal}>
              <p>Lisence Fee:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${lastUnpaidInvoice?.subAmount}`}</p>
            </div>
            <div className={styles.subTotal}>
              <p>Tax (Lisence Fee + 6%):</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${lastUnpaidInvoice?.subTaxes}`}</p>
            </div>
            <div className={styles.subTotal}>
              <p>Sub Total:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${Number(lastUnpaidInvoice.subTaxes) + Number(lastUnpaidInvoice?.subAmount)}`}</p>
            </div>
            {/* <div className={styles.subTotal}>
              <p>Transaction Charges:</p> */}
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              {/* <p>{`$ ${lastUnpaidInvoice?.subCharges}`}</p>
            </div> */}
              </div>
              </>
            ) : null}
            <div></div>

            <div className={styles.divider}></div>
            <div className={styles.total}>
              <p>Total Payable Amount:</p>
              {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
              <p>{`$ ${totalCharges}`}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PaymentModal;
