import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import MainLayout from "layouts/MainLayout";

function PaymentFormWrapper({ paymentIntent, invoiceStatus, setClientSecret }) {
  // const public_Key =
  //   "pk_test_51O0OffFF0V8QqulCrM90pUORoMUHzOGetlmjyBr0J3nSCCKXoWBRbLIEyNFGWofQc4tL51IU8WPjz69LLRb723pY00Q5cpuoRX";
  const public_Key = process.env.REACT_APP_API_STRIPE_PUBLIC_KEY
  const promise = loadStripe(public_Key);

  console.log("Static =>", public_Key);
  console.log("Env =>", process.env.REACT_APP_API_STRIPE_PUBLIC_KEY);
  return (
    <div style={{ width: "400px", margin: "4rem auto" }}>
      {" "}
      <Elements stripe={promise} options={{ clientSecret: paymentIntent }}>
        <PaymentForm invoiceStatus={invoiceStatus} />
      </Elements>
    </div>
  );
}

export default PaymentFormWrapper;
