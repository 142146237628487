import React, { useState, useCallback } from "react";
import axios from "axios";
import styles from "./styles.module.scss";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { Select, Table, Tabs, notification } from "antd";
import MainLayout from "layouts/MainLayout";
import CustomButton from "components/Button";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CustomInput from "components/CustomInput";
import { LuUpload } from "react-icons/lu";
import { MdOutlineBlock } from "react-icons/md";
import { BiSolidEditAlt } from "react-icons/bi";
import BlockModal from "components/Modals/BlockModal";
import EditModal from "components/Modals/EditModal";
import CsvModal from "components/Modals/CsvModal";
import PaymentModal from "components/Modals/PaymentModal";
import PaymentSuccessModal from "components/Modals/PaymentSuccessModal";
import { validateAddDriver } from "yupSchema";
import { useAuth } from "context";
import { getFreeTrailInvoice, registerDrivers } from "APIs/Services";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PaymentFormWrapper from "pages/Payment";
import { backendEndpoints } from "constants/Backend";
import { calculateDriverCost } from "Utils/costHandler";
import PhoneInputField from "components/PhoneNumberInput";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ActionButton from "components/ActionButton";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "gray" }} spin />
);

function Dashboard() {
  const { idUser, tokenUser, user, getAllDrivers, handleUnautherized } =
    useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;
  const [lastInvoive, setLastInvoice] = useState();
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [matchedList, setMatchedList] = useState();
  const [csvFileData, setCsvFileData] = useState(null);
  const [showCsvData, setShowCsvData] = useState(false);
  const [isEditArray, setIsEditArray] = useState(
    Array(csvFileData?.length)?.fill(false)
  );
  const [isIndex, setIsIndex] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] =
    useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentSection, setPaymentSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkboxCheck, setCheckboxCheck] = useState(false);
  const [payBtnLoading, setPayBtnLoading] = useState(false);
  const [finishLaoding, setFinishLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [matchingIndices, setMatchingIndices] = useState([]);

  const paymentData = {
    amount: 5,
    user_id: id,
  };

  // This the utility function to calculate LF with respec to total driver cost, rem days and plan days
  const result = calculateDriverCost(
    process.env.REACT_APP_MONTHLY_COST,
    user?.planRemainingDays,
    user?.planDays
  );

  const getPaymentIntent = async () => {
    setPayBtnLoading(true);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.createPaymentIntent}`,
        {
          user_id: id,
          amount: totalCharges,
          subAmount: licenseFee,
          subTaxes: percentageOfLicenseFee?.toFixed(2),
          subCharges: pfAndStripe,
          invoice_id: lastInvoive?.invoiceId ? lastInvoive?.invoiceId : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientSecret(data?.data?.data.client_secret);
      setPaymentSection(true);
      setIsPaymentModalOpen(false);

      const modifiedData = csvFileData.map((item) => {
        const { serialNo, key, ...newItem } = item;
        newItem.join_date = result.currentDate;
        newItem.end_date = result.lastDateOfMonth;
        newItem.per_day = result.perDayCost;
        newItem.current_month_charges = result.totalCost;
        newItem.monthly_charges = result.monthlyCost;
        return newItem;
      });
      localStorage.setItem("drivers", JSON.stringify(modifiedData));
      localStorage.setItem("invoiceId", data?.data?.data?.invoiceId);
      localStorage.setItem(
        "totalAmount",
        csvFileData?.length * result.totalCost +
          (lastInvoive ? parseInt(lastInvoive?.previousAmount) : 0)
      );

      localStorage.setItem(
        "chargesObj",
        JSON.stringify({
          amount: totalCharges,
          subAmount: licenseFee,
          subTaxes: percentageOfLicenseFee?.toFixed(2),
          subCharges: pfAndStripe,
        })
      );
      setPayBtnLoading(false);
    } catch (error) {
      notification.error({
        message: "Request Failed",
        description: error.response.data.message
          ? error.response.data.message
          : "Something Went Wrong",
      });
      setPayBtnLoading(false);
    }
  };

  const addDriverWithFreeTrial = async (driverObj) => {
    const intentObj = {
      amount: 0,
      user_id: id,
      subAmount: 0,
      subCharges: 0,
      subTaxes: 0,
    };

    const freeTrialRes = await getFreeTrailInvoice(token, intentObj);
    const invoiceId = freeTrialRes?.data?.data?.invoiceId;

    if (freeTrialRes.status === 200) {
      const modifiedData = driverObj?.map((item) => {
        const { serialNo, key, ...newItem } = item;
        newItem.join_date = result.currentDate;
        newItem.end_date = result.lastDateOfMonth;
        newItem.per_day = result.perDayCost;
        newItem.current_month_charges = result.totalCost;
        newItem.monthly_charges = result.monthlyCost;
        return newItem;
      });

      const addDriverRes = await registerDrivers(
        token,
        modifiedData,
        id,
        invoiceId,
        0,
        0,
        0,
        0
      );
      if (addDriverRes?.status === 200) {
        await getAllDrivers();
        notification.success({
          message: "Success",
          description:
            modifiedData?.length < 2
              ? "Driver Added Successfully"
              : "Drivers Added Successfully",
        });
        setCsvFileData([]);
        setShowCsvData(false);
        setIsLoading(false);
        navigate("/alldrivers");
      } else {
        notification.error({
          message: "Request Failed",
        });
        setIsLoading(false);
      }
    } else {
      notification.error({
        message: "Request Failed",
      });
      setIsLoading(false);
    }
  };

  const driversDuplicationCheck = async (obj) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.checkDriverDuplication}`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      return error?.response;
    }
  };

  const getLastUnpaidInvoice = async () => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getLastUnpaidAmount}`,
        { user_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      alert(error);
    }
  };

  const toggleEdit = (index) => {
    const updatedIsEditArray = [...isEditArray];
    updatedIsEditArray[index] = !updatedIsEditArray[index];
    setIsEditArray(updatedIsEditArray);
    setIsIndex(index);
  };

  const handlePaymentSuccessOk = () => {
    setIsPaymentSuccessModalOpen(false);
    setCsvFileData(null);
  };

  const handlePaymentSuccessCancel = () => {
    setIsPaymentSuccessModalOpen(false);
  };

  const showPaymentModal = async () => {
    setFinishLoading(true);
    const response = await driversDuplicationCheck({
      data: csvFileData,
      user_id: id,
    });

    if (response?.status !== 200) {
      notification.error({
        message: "Request Failed",
        description: "Something Went Wrong",
      });
      setFinishLoading(false);
      return;
    }
    setMatchedList(response?.data?.data);

    const secondResponse = await getLastUnpaidInvoice();
    setLastInvoice(secondResponse.data.data);

    if (response?.data?.data.length === 0) {
      if (user?.plan === "free") {
        await addDriverWithFreeTrial(csvFileData);
      } else {
        setIsPaymentModalOpen(true);
      }
    } else {
      notification.error({
        message: "Request Failed",
        description: "Remove Duplications From the Drivers Record",
      });
    }
    setFinishLoading(false);
  };

  const handlePaymentOk = () => {
    if (!checkboxCheck) {
      notification.error({
        message: "Accept Terms and Conditions",
      });
      return null;
    } else {
      getPaymentIntent(paymentData);
    }
  };

  const handlePaymentCancel = () => {
    setIsPaymentModalOpen(false);
  };

  const showBlockModal = () => {
    setIsBlockModalOpen(true);
  };

  const handleBlockOk = () => {
    if (selectedRowKeys?.length > 0) {
      const indicesToRemove = new Set(selectedRowKeys);
      const updatedData = csvFileData?.filter(
        (_, index) => !indicesToRemove?.has(index)
      );
      setCsvFileData(updatedData);
      const updatedKeys = selectedRowKeys?.filter(
        (key) => !indicesToRemove?.has(key)
      );
      setSelectedRowKeys(updatedKeys);
      notification.success({
        message: "Success",
        description: "Driver Removed Successfully",
      });
      setIsBlockModalOpen(false);
    } else {
      csvFileData?.splice(editItemIndex, 1);
      notification.success({
        message: "Success",
        description: "Driver Removed Successfully",
      });
      setIsBlockModalOpen(false);
    }
  };

  const handleBlockCancel = () => {
    setIsBlockModalOpen(false);
    toggleEdit(isIndex);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = () => {
    notification.success({
      message: "Success",
      description: "Driver Updated Succesfully",
    });
    setIsEditModalOpen(false);
    toggleEdit(isIndex);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
    toggleEdit(isIndex);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setShowCsvData(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onDrop = useCallback((acceptedFile) => {
    setIsLoading(true);

    Papa.parse(acceptedFile[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        // Check if CSV file has required properties
        const requiredProperties = [
          "serialNo",
          "first_name",
          "last_name",
          "phone",
          "email",
        ];

        console.log("CSV FILE", results?.data);
        const fileProperties = Object.keys(results?.data[0] || {});
        const isValidFile = requiredProperties.every((property) =>
          fileProperties.includes(property)
        );

        // Clean up phone numbers
        results.data.forEach((item) => {
          if (item.phone) {
            // Remove double quotes and leading/trailing whitespaces
            item.phone = item.phone.replace(/["' ]/g, "");
          }
        });

        // Check if the phone field starts with '+'
        const isPhoneValid = results.data.every(
          (item) => item.phone && item.phone.startsWith("+")
        );

        if (!isPhoneValid) {
          // Handle invalid phone field
          setIsLoading(false);
          notification.error({
            message: "Invalid phone format",
            description: "All phone numbers must start with '+'",
          });
          return;
        }

        if (!isValidFile) {
          // Handle invalid file format
          setIsLoading(false);
          notification.error({
            message: "Invalid CSV file format",
            description:
              "Please make sure it includes serialNo, first_name, last_name, phone, and email column.",
          });
          return;
        }

        // Check if the serialNo of the first object is zero
        if (parseInt(results?.data[0]?.serialNo) !== 0) {
          // Handle invalid serialNo
          setIsLoading(false);
          notification.error({
            message: "Invalid CSV file format",
            description: "The serial no. of the first object should be zero.",
          });
          return;
        }

        // Continue with processing the valid file
        setCsvFileData(results?.data);
        const response = await driversDuplicationCheck({
          data: results.data,
          user_id: id,
        });

        if (response?.status === 401) {
          notification.error({
            message: "Request Failed",
            description: "Session Expired",
          });
          handleUnautherized(response?.status);
        }
        setMatchedList(response?.data?.data);
        setIsLoading(false);
        showModal();
      },
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    accept: { "text/csv": [".csv"] },
    onDrop,
    multiple: true,
  });

  // useEffect(() => {
  //   const findMatchingIndices = () => {
  //     if (csvFileData && matchedList) {
  //       const matchedPhones = matchedList?.map((matchItem) =>
  //         matchItem?.phone?.replace(/\s+/g, "")
  //       );
  //       const indices = csvFileData
  //         .map((driver, serialNo) =>
  //           matchedPhones?.includes(driver?.phone?.replace(/\s+/g, ""))
  //             ? serialNo
  //             : -1
  //         )
  //         .filter((serialNo) => serialNo !== -1);
  //       return indices;
  //     }
  //     return [];
  //   };

  //   const indices = findMatchingIndices();
  //   setMatchingIndices(indices);
  //   setCsvFileData(csvFileData);
  // }, [csvFileData, matchedList]);

  //Following useEffect holds the logic for handling dupluication check before adding the drivers to DB
  useEffect(() => {
    function compareArrays(array1, array2) {
      if (csvFileData && matchedList) {
        const resultArray = [];

        for (const obj1 of array1) {
          for (const obj2 of array2) {
            if (obj1.phone === obj2.phone) {
              resultArray.push(obj1.serialNo);
              break; // Break once a match is found to avoid duplicates
            }
          }
        }

        return resultArray;
      }
      return [];
    }

    const commonSerialNumbers = compareArrays(csvFileData, matchedList);
    setMatchingIndices(commonSerialNumbers);
    setCsvFileData(csvFileData);
  }, [csvFileData, matchedList]);

  const showEditModalWithData = (item) => {
    const itemIndex = csvFileData.findIndex(
      (dataItem) => dataItem.serialNo === item.serialNo
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditModal();
  };
  const downloadFile = () => {
    const link = document.createElement("a");
    link.download = "Driver List Sample.csv";
    link.href = "/Driver List Sample.csv";
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice for opening in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateCsvFileData = (updatedItem, index) => {
    // Create a copy of the csvFileData
    const updatedCsvFileDataTemp = [...csvFileData];

    // Update the item at the specified index with the edited values
    updatedCsvFileDataTemp[index] = updatedItem;

    // Update the state with the updated data
    setCsvFileData(updatedCsvFileDataTemp);
  };

  const showDeleteModalWithData = (item) => {
    const itemIndex = csvFileData.findIndex(
      (dataItem) => dataItem.serialNo === item.serialNo
    );
    setEditItemIndex(itemIndex);
    showBlockModal();
  };

  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "first_name",
      width: 250,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: 200,
      sorter: (a, b) => a.phone - b.phone,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => showEditModalWithData(record)}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
            isDisabled={selectedRowKeys?.length > 0 ? true : false}
          />
          <ActionButton
            onClick={() => {
              showDeleteModalWithData(record);
              console.log("REcord =>>>>", record);
            }}
            icon={<MdOutlineBlock size={15} />}
            text="Delete"
            isDisabled={selectedRowKeys?.length > 0 ? true : false}
          />
        </div>
      ),
    },
  ];
  var initialValues = {
    firstName: "",
    lastName: "",
    driverEmail: "",
    // confirmDriverEmail: "",
    driverPhoneNumber: "",
    confirmDriverPhoneNumber: "",
  };

  const handleSelectChange = (selected, setFieldValue) => {
    if (selected === "companyEmail") {
      setFieldValue("driverEmail", user?.email);
    } else if (selected === "driverEmail") {
      setFieldValue("driverEmail", "");
    }
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  const items = [
    {
      key: "1",
      label: "Add a Driver",
      children: (
        <div className={styles.addDriverFormWrapper}>
          <p>Add Driver</p>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validateAddDriver}
            onSubmit={async (values, actions) => {
              actions.setSubmitting(false);
              setIsLoading(true);
              const dataObj = [];
              const duplicationCheckObj = [];

              duplicationCheckObj.push({
                first_name: values.firstName,
                email: values.driverEmail,
                phone: values.driverPhoneNumber,
              });

              dataObj.push({
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.driverEmail,
                phone: values.driverPhoneNumber,
              });
              const response = await driversDuplicationCheck({
                data: duplicationCheckObj,
                user_id: id,
              });

              if (response?.status === 401) {
                notification.error({
                  message: "Request Failed",
                  description: "Session Expired",
                });
                handleUnautherized(response?.status);
                return;
              }

              if (response?.data?.data?.length === 0) {
                if (user?.plan === "free") {
                  await addDriverWithFreeTrial(dataObj);
                  actions.resetForm();
                } else {
                  const secondResponse = await getLastUnpaidInvoice();
                  setLastInvoice(secondResponse.data.data);
                  localStorage.setItem(
                    "totalAmount",
                    JSON.stringify(secondResponse.data.data)
                  );
                  setCsvFileData(dataObj);
                  setIsPaymentModalOpen(true);
                  setIsLoading(false);
                }
              } else {
                setIsLoading(false);
                notification.error({
                  message: "Duplication",
                  description: "Drivers Already Exist",
                });
              }
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className={styles.inputFieldsWrapper}>
                  <div className={styles.inputAndErrorWrapper}>
                    <Field
                      component={CustomInput}
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      style={{ height: "42px" }}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className={styles.inputAndErrorWrapper}>
                    <Field
                      component={CustomInput}
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      style={{ height: "42px" }}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className={styles.inputFieldsWrapper}>
                  <div className={styles.inputAndErrorWrapper}>
                    <Select
                      defaultValue="Select Email"
                      style={{ height: 42 }}
                      onChange={(selected) =>
                        handleSelectChange(selected, setFieldValue)
                      }
                      options={[
                        { value: "driverEmail", label: "Driver Email" },
                        { value: "companyEmail", label: "Company Email" },
                      ]}
                    />
                  </div>
                  <div className={styles.inputAndErrorWrapper}>
                    <Field
                      component={CustomInput}
                      type="email"
                      id="driverEmail"
                      name="driverEmail"
                      placeholder="Email"
                      style={{ height: "42px" }}
                    />
                    <ErrorMessage
                      name="driverEmail"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div className={styles.inputFieldsWrapper}>
                  <div className={styles.inputAndErrorWrapper}>
                    <Field
                      component={PhoneInputField}
                      type="tel"
                      id="driverPhoneNumber"
                      name="driverPhoneNumber"
                      placeholder="Driver Phone Number"
                      style={{ height: "42px" }}
                    />
                    <ErrorMessage
                      name="driverPhoneNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className={styles.inputAndErrorWrapper}>
                    <Field
                      component={PhoneInputField}
                      type="text"
                      id="confirmDriverPhoneNumber"
                      name="confirmDriverPhoneNumber"
                      placeholder="Confirm Phone Number"
                      style={{ height: "42px" }}
                      onPaste={(e) => preventCopyPaste(e)}
                    />
                    <ErrorMessage
                      name="confirmDriverPhoneNumber"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <CustomButton
                  style={{
                    height: "38px",
                    width: "120px",
                    alignSelf: "flex-end",
                    fontSize: "14px",
                    borderRadius: "7px",
                    marginTop: "7px",
                  }}
                  text={"Add Driver"}
                  type={"submit"}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      ),
    },
    {
      key: "2",
      label: "Add a Driver List",
      children: (
        <div {...getRootProps()} className={styles.dragAndDropWrapper}>
          <input {...getInputProps()} />
          <div className={styles.dropDownContentWrapper}>
            {isDragActive ? (
              <div>Drop the files here ...</div>
            ) : (
              <>
                <div className={styles.dropDownContent}>
                  <LuUpload size={30} />
                  <p className={styles.dragOne}>Drag & drop any file here</p>
                  <p className={styles.dragTwo}>or browse file from device</p>
                </div>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <button
                    onClick={open}
                    disabled={
                      isLoading || csvFileData?.length > 0 ? true : false
                    }
                  >
                    {isLoading ? <Spin indicator={antIcon} /> : "Upload"}
                  </button>
                  <button onClick={downloadFile}>Download Sample</button>
                </div>
              </>
            )}
          </div>
        </div>
      ),
    },
  ];

  // Starts here => This section holds the logic for LF, Tax, Sub total , PF and Total Payable Amount Calculations
  const totalPayAble =
    csvFileData?.length * result.totalCost +
    (lastInvoive ? Number(lastInvoive?.previousAmount) : 0);

  const licenseFee =
    csvFileData?.length === 1 && result.totalCost <= 0.5
      ? 0.5
      : csvFileData?.length * result.totalCost;
  const percentageOfLicenseFee = (6 / 100) * licenseFee;
  const licenseFeeAndTax = Number(licenseFee + percentageOfLicenseFee).toFixed(
    2
  );
  const lastUnpaidLfAndTax = lastInvoive?.previousAmount
    ? (Number(licenseFeeAndTax) + Number(lastInvoive?.previousAmount)).toFixed(
        2
      )
    : licenseFeeAndTax;
  const stripeChargesPercentage = (2.9 / 100) * lastUnpaidLfAndTax;
  const pfAndStripe = Number(stripeChargesPercentage + 0.3).toFixed(2);
  var totalCharges = (Number(lastUnpaidLfAndTax) + Number(pfAndStripe)).toFixed(
    2
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <MainLayout>
      {paymentSection ? (
        <div className={styles.dashboardPaymentForm}>
          <PaymentFormWrapper paymentIntent={clientSecret} />

          <div className={styles.invoiceSection}>
            <p style={{ selfAlign: "center" }}>Invoice</p>
            <p>
              No. of Driver(s): <span>{csvFileData?.length}</span>
            </p>
            <p>
              Lisence Fee: <span>${licenseFee}</span>
            </p>
            <p>
              Tax (Lisence Fee + 6%):{" "}
              <span>${percentageOfLicenseFee?.toFixed(2)}</span>
            </p>
            <p>
              Sub Total: <span>${licenseFeeAndTax}</span>
            </p>
            <p>
              Transaction Charges: <span>${pfAndStripe}</span>
            </p>

            {lastInvoive?.previousAmount ? (
              <>
                <p style={{ selfAlign: "center" }}>UnPaid Invoice</p>
                <p>
                  Lisence Fee: <span>${lastInvoive?.subAmount}</span>
                </p>
                <p>
                  Tax (Lisence Fee + 6%): <span>${lastInvoive?.subTaxes}</span>
                </p>
                <p>
                  Sub Total: <span>${lastInvoive?.previousAmount}</span>
                </p>
              </>
            ) : (
              ""
            )}

            <div className={styles.divider}></div>
            <p className={styles.totalPayable}>
              Total Payable: <span>${totalCharges}</span>
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="custom-tab-wrapper">
            <Tabs
              defaultActiveKey="1"
              type="card"
              size={"small"}
              items={items}
              tabBarGutter={12}
            />
          </div>

          {showCsvData && (
            <div className={styles.allDriversWrapper}>
              {selectedRowKeys?.length > 0 && (
                <CustomButton
                  style={{
                    width: "11rem",
                    height: "2rem",
                    fontSize: "14px",
                    fontWeight: "400",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    marginLeft: "30px",
                  }}
                  text="Delete Selected Drivers"
                  onClick={showBlockModal}
                />
              )}
              <div className={styles.allDriversInnerWrapper}>
                <Table
                  enableReinitialize
                  columns={ActiveDriversColumns}
                  rowSelection={rowSelection}
                  dataSource={csvFileData?.map((item, index) => ({
                    ...item,
                    key: index,
                    first_name: item.first_name,
                    email: item.email,
                    phone: item.phone,
                  }))}
                  scroll={{
                    x: 950,
                  }}
                  className="custom-table"
                  rowClassName={(record) =>
                    matchingIndices.includes(record?.serialNo.toString())
                      ? "highlighted-row"
                      : ""
                  }
                />
              </div>
              {csvFileData?.length > 0 && (
                <div className={styles.finishButtonWrapper}>
                  <CustomButton
                    style={{
                      width: "6rem",
                      height: "2rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      borderRadius: "5px",
                      marginBottom: "1rem",
                    }}
                    text="Finish"
                    onClick={showPaymentModal}
                    isLoading={finishLaoding}
                    disabled={finishLaoding}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}

      <PaymentModal
        handlePaymentCancel={handlePaymentCancel}
        handlePaymentOk={handlePaymentOk}
        isPaymentModalOpen={isPaymentModalOpen}
        data={csvFileData}
        perDriverCost={result.totalCost}
        lastUnpaidInvoice={lastInvoive}
        checkboxCheck={checkboxCheck}
        toggleCheckBox={setCheckboxCheck}
        payBtnLoading={payBtnLoading}
        totalPayAble={totalPayAble}
        licenseFee={licenseFee}
        percentageOfLicenseFee={percentageOfLicenseFee}
        licenseFeeAndTax={licenseFeeAndTax}
        pfAndStripe={pfAndStripe}
        totalCharges={totalCharges}
      />
      <BlockModal
        handleBlockCancel={handleBlockCancel}
        handleBlockOk={handleBlockOk}
        isBlockModalOpen={isBlockModalOpen}
      />
      <CsvModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
      />
      <EditModal
        editAbleItem={editItem}
        handleEditCancel={handleEditCancel}
        handleEditOk={handleEditOk}
        isEditModalOpen={isEditModalOpen}
        editItemIndex={editItemIndex}
        updateCsvFileData={updateCsvFileData}
      />
      <PaymentSuccessModal
        handlePaymentSuccessCancel={handlePaymentSuccessCancel}
        handlePaymentSuccessOk={handlePaymentSuccessOk}
        isPaymentSuccessModalOpen={isPaymentSuccessModalOpen}
      />
    </MainLayout>
  );
}

export default Dashboard;
