import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "context/AuthProvider";
import styles from "./styles.module.scss";
import IconButton from "components/IconButton";
import { IoLogIn } from "react-icons/io5";

import { ReactComponent as CircularPersonActiveIcon } from "assets/icons/CircularPersonActiveIcon.svg";
import { ReactComponent as CircularPersonInActiveIcon } from "assets/icons/CircularPersonInActiveIcon.svg";
import { ReactComponent as EditDriverInActiveIcon } from "assets/icons/EditDriverInActiveIcon.svg";
import { ReactComponent as EditDriverActiveIcon } from "assets/icons/EditDriverActiveIcon.svg";
import { ReactComponent as BlockDriverInActiveIcon } from "assets/icons/BlockDriverInActiveIcon.svg";
import { ReactComponent as BlockDriverActiveIcon } from "assets/icons/BlockDriverActiveIcon.svg";
import { ReactComponent as PersonIcon } from "assets/icons/PersonIcon.svg";
import { ReactComponent as PersonIconActive } from "assets/icons/PersonIconActive.svg";
import { ReactComponent as HomeActiveIcon } from "assets/icons/HomeActiveIcon.svg";
import { ReactComponent as HomeInActiveIcon } from "assets/icons/HomeInActiveIcon.svg";
import { ReactComponent as EditCompanyActiveIcon } from "assets/icons/EditCompanyActiveIcon.svg";
import { ReactComponent as EditCompanyInActiveIcon } from "assets/icons/EditCompanyInActiveIcon.svg";
import { ReactComponent as BlockCompanyActiveIcon } from "assets/icons/BlockCompanyActiveIcon.svg";
import { ReactComponent as BlockCompanyInActiveIcon } from "assets/icons/BlockCompanyInActiveIcon.svg";
import ConfirmLogoutModal from "components/Modals/ConfirmLogoutModal";

function SideBar() {
  const [isAddDriverHovered, setIsAddDriverHovered] = useState(false);
  const [isAllDriverHovered, setIsAllDriverHovered] = useState(false);
  const [isEditDriverHovered, setIsEditDriverHovered] = useState(false);
  const [isBlockDriverHovered, setIsBlockDriverHovered] = useState(false);
  const [isHoveredProfile, setIsHoveredProfile] = useState(false);
  const [isAddCompanyHovered, setIsAddCompanyHovered] = useState(false);
  const [isAllCompanyHovered, setIsAllCompanyHovered] = useState(false);
  const [isEditCompanyHovered, setIsEditCompanyHovered] = useState(false);
  const [isBlockCompanyHovered, setIsBlockCompanyHovered] = useState(false);

  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] = useState(false);
  const Navigate = useNavigate();
  const { logout, user } = useAuth();

  const location = useLocation();
  const URL = location.pathname;
  
  const handleShowConfirmLogout= () => {
    setIsConfirmLogoutModalOpen(true);
  }
  const handleConfirmLogoutOk = async () => {
    await logout();
    setIsConfirmLogoutModalOpen(false);
  };

  const handleConfirmLogoutCancel = () => {
    setIsConfirmLogoutModalOpen(false);
  };
  return (
    <div className={styles.sidebarWrapper}>
      <div
        style={{
          filter: user?.isBlocked === true ? "blur(5px)" : "none",
          pointerEvents: user?.isBlocked === true ? "none" : "auto",
        }}
        className={styles.navWrapper}
      >
        {(user?.roleName).toLowerCase() === "superadmin" ? (
          <>
            <div
              onMouseOver={() => setIsAddCompanyHovered(true)}
              onMouseOut={() => setIsAddCompanyHovered(false)}
              onClick={() => Navigate("/addcompany")}
              className={
                URL === "/addcompany"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isAddCompanyHovered || URL === "/addcompany" ? (
                <HomeActiveIcon />
              ) : (
                <HomeInActiveIcon />
              )}
              <p>Add Company</p>
            </div>

            <div
              onMouseOver={() => setIsAllCompanyHovered(true)}
              onMouseOut={() => setIsAllCompanyHovered(false)}
              onClick={() => Navigate("/allcompanies")}
              className={
                URL === "/allcompanies"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isAllCompanyHovered ||
              URL === "/allcompanies"  ? (
                <HomeActiveIcon />
              ) : (
                <HomeInActiveIcon />
              )}
              <p>All Companies</p>
            </div>

            <div
              onMouseOver={() => setIsEditCompanyHovered(true)}
              onMouseOut={() => setIsEditCompanyHovered(false)}
              onClick={() => Navigate("/editcompany")}
              className={
                URL === "/editcompany"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isEditCompanyHovered || URL === "/editcompany" ? (
                <EditCompanyActiveIcon />
              ) : (
                <EditCompanyInActiveIcon />
              )}
              <p>Edit Company</p>
            </div>

            <div
              onMouseOver={() => setIsBlockCompanyHovered(true)}
              onMouseOut={() => setIsBlockCompanyHovered(false)}
              style={{ gap: "10px" }}
              onClick={() => Navigate("/blockcompany")}
              className={
                URL === "/blockcompany"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isBlockCompanyHovered || URL === "/blockcompany" ? (
                <BlockCompanyActiveIcon />
              ) : (
                <BlockCompanyInActiveIcon />
              )}
              <p>Block Company</p>
            </div>
          </>
        ) : (
          <>
            <div
              onMouseOver={() => setIsAddDriverHovered(true)}
              onMouseOut={() => setIsAddDriverHovered(false)}
              onClick={(e) => {
                if (user?.isBlocked === true) {
                  e.stopPropagation();
                } else {
                  Navigate("/dashboard");
                }
              }}
              className={
                URL === "/dashboard"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isAddDriverHovered || URL === "/dashboard" ? (
                <CircularPersonActiveIcon />
              ) : (
                <CircularPersonInActiveIcon color="#FF0200" />
              )}
              <p>Add Drivers</p>
            </div>

            <div
              onMouseOver={() => setIsAllDriverHovered(true)}
              onMouseOut={() => setIsAllDriverHovered(false)}
              onClick={(e) => {
                if (user?.isBlocked === true) {
                  e.stopPropagation();
                } else {
                  Navigate("/alldrivers");
                }
              }}
              className={
                URL === "/alldrivers"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isAllDriverHovered || URL === "/alldrivers" ? (
                <CircularPersonActiveIcon />
              ) : (
                <CircularPersonInActiveIcon color="#FF0200" />
              )}
              <p>All Drivers</p>
            </div>

            <div
              onMouseOver={() => setIsEditDriverHovered(true)}
              onMouseOut={() => setIsEditDriverHovered(false)}
              onClick={(e) => {
                if (user?.isBlocked === true) {
                  e.stopPropagation();
                } else {
                  Navigate("/editdriver");
                }
              }}
              className={
                URL === "/editdriver"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isEditDriverHovered || URL === "/editdriver" ? (
                <EditDriverActiveIcon />
              ) : (
                <EditDriverInActiveIcon />
              )}
              <p>Edit Driver</p>
            </div>

            <div
              onMouseOver={() => setIsBlockDriverHovered(true)}
              onMouseOut={() => setIsBlockDriverHovered(false)}
              style={{ gap: "10px" }}
              onClick={(e) => {
                if (user?.isBlocked === true) {
                  e.stopPropagation();
                } else {
                  Navigate("/blockdriver");
                }
              }}
              className={
                URL === "/blockdriver"
                  ? styles.activeButtonWrapper
                  : styles.buttonWrapper
              }
            >
              {isBlockDriverHovered || URL === "/blockdriver" ? (
                <BlockDriverActiveIcon />
              ) : (
                <BlockDriverInActiveIcon />
              )}
              <p>Delete Driver</p>
            </div>
          </>
        )}

        <div
          onMouseOver={() => setIsHoveredProfile(true)}
          onMouseOut={() => setIsHoveredProfile(false)}
          className={
            URL === "/profile"
              ? styles.activeButtonWrapper
              : styles.buttonWrapper
          }
          style={{ gap: "10px" }}
          onClick={(e) => {
            if (user?.isBlocked === true) {
              e.stopPropagation();
            } else {
              Navigate("/profile");
            }
          }}
        >
          {isHoveredProfile || URL === "/profile" ? (
            <PersonIconActive />
          ) : (
            <PersonIcon />
          )}
          <p>Profile</p>
        </div>
      </div>
      <div className={styles.divider}></div>

      <IconButton
        style={{
          width: "198px",
          margin: "0 auto",
          marginTop: "1rem",
          height: "3.4rem",
          paddingLeft: "1.3rem",
          zIndex: "200",
        }}
        text="Logout"
        icon={<IoLogIn size={30} />}
        onClick={handleShowConfirmLogout}
      />
      <div className={styles.circleShade}></div>

      <ConfirmLogoutModal
        isConfirmLogoutModalOpen={isConfirmLogoutModalOpen}
        handleConfirmLogoutOk={handleConfirmLogoutOk}
        handleConfirmLogoutCancel={handleConfirmLogoutCancel}
      />
    </div>
  );
}

export default SideBar;
