import React, { useEffect, useState, useRef } from "react";
import MainLayout from "layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Tabs, Table, Spin, notification, Input } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { BiSolidEditAlt } from "react-icons/bi";
import { TbFolderExclamation } from "react-icons/tb";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import { HiArrowLeft } from "react-icons/hi";
import ViewDetailsModal from "components/Modals/ViewDetailsModel";
import DeleteDriverModal from "components/Modals/DeleteDriverModal";
import { useAuth } from "context";
import axios from "axios";
import { backendEndpoints } from "constants/Backend";
import EditAddedDriverModal from "components/Modals/EditAddedDriverModal";
import { CSVLink } from "react-csv";
import ActionButton from "components/ActionButton";
import CustomButton from "components/Button";
import BlockCompanyModal from "components/Modals/BlockCompanyModal";
import EditCompanyModal from "components/Modals/EditCompanyModal";
import { deleteDriver } from "APIs/Services";
import { editDriver } from "APIs/Services";
import { blockCompany } from "APIs/Services";
import { editCompany } from "APIs/Services";

function CompanyDrivers() {
  const { tokenUser, getAllCompanies, handleUnautherized } = useAuth();
  const [editItem, setEditItem] = useState();
  const [companyDriversList, setCompanyDriversList] = useState([]);
  const [editItemIndex, setEditItemIndex] = useState();
  const [viewDriverInfo, setViewDriverInfo] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [isDeleteDriverModalOpen, setIsDeleteDriverModalOpen] = useState(false);
  const [isBlockCompanyModalOpen, setIsBlockCompanyModalOpen] = useState(false);
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);

  const [companyDriverId, setCompanyDriverId] = useState();
  const Navigate = useNavigate();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const csvLink = useRef();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;

  const info = JSON.parse(localStorage.getItem("specificComDetails"));

  useEffect(() => {
    getAllDrivers();
  }, []);

  const getAllDrivers = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.getDriverList}`,
        { user_id: info?.id, limit: 10000 },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompanyDriversList(response?.data?.data);
      setIsLoadingData(false);
    } catch (error) {
      notification.error({
        message: "Request Failed",
        description:
          error?.response?.status === 401
            ? "Session Expired"
            : "Something Went Wrong",
      });
      setIsLoadingData(false);
      handleUnautherized(error?.response?.status);
    }
  };

  // const editDriverDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.editDriver}`,
  //       {
  //         user_id: info?.id,
  //         driver_id: item.id,
  //         email: item.email,
  //         first_name: item.firstName,
  //         last_name: item.lastName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Driver Details Updated Succesfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description: error?.response?.data?.message,
  //     });
  //     setIsLoading(false);
  //   }
  // };

  // const deleteDriver = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.deleteDriver}`,
  //       {
  //         user_id: info?.id,
  //         driver:
  //           companyDriverId?.length > 0
  //             ? companyDriverId
  //             : selectedRowKeys?.map((key) => ({ id: key })),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description: error?.response?.data?.errors?.driver
  //         ? error?.response?.data?.errors?.driver[0]
  //         : "Something went wrong",
  //     });
  //     setIsLoading(false);
  //   }
  // };

  // const blockCompany = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.blockCompany}`,
  //       {
  //         user_id: info?.id,
  //         is_blocked: !info?.isBlocked,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description: "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //   }
  // };

  // const editCompanyDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
  //       {
  //         user_id: item?.id,
  //         first_name: item?.firstName,
  //         last_name: item?.lastName,
  //         phone: item?.mobileNumber,
  //         address: item?.address,
  //         user_name: item?.preferredName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Company Details Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description: error,
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const updateCompaniesData = (updatedItem, index) => {};

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCompanyDriverId([]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showEditModalWithData = (item) => {
    const itemIndex = companyDriversList?.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditModal();
  };

  const updateAllDriversData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...companyDriversList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setCompanyDriversList(updatedCsvFileDataTemp);
  };
  const showDeleteDriverModal = () => {
    setIsDeleteDriverModalOpen(true);
  };

  const handleDeleteDriverOk = async () => {
    // const response = await deleteDriver();
    setIsLoading(true);
    const response = await deleteDriver(token, {
      user_id: info?.id,
      driver:
        companyDriverId.length > 0
          ? companyDriverId
          : selectedRowKeys.map((key) => ({ id: key })),
    });

    if (response.status === 200) {
      // Block was successful, update allDriversList state.
      await getAllDrivers();
      notification.success({
        message: "Success",
        description: "Driver Deleted Successfully",
      });
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
    setIsLoading(false);
    setSelectedRowKeys([]);
    setIsDeleteDriverModalOpen(false);
  };

  const showBlockCompanyModal = () => {
    setIsBlockCompanyModalOpen(true);
  };

  const handleBlockCompanyOk = async () => {
    // await blockCompany();
    setIsLoading(true);
    const response = await blockCompany(token, {
      user_id: info?.id,
      is_blocked: !info?.isBlocked,
    });

    if (response?.status === 200) {
      await getAllCompanies();
      info["isBlocked"] = !info?.isBlocked;
      localStorage.setItem("specificComDetails", JSON.stringify(info));
      setIsBlockCompanyModalOpen(false);
      notification.success({
        message: "Success",
        description: "Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const handleBlockCompanyCancel = () => {
    setIsBlockCompanyModalOpen(false);
  };

  const handleDeleteDriverCancel = () => {
    setIsDeleteDriverModalOpen(false);
  };
  const showViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const handleViewDetailsOk = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalOpen(false);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = async (updatedData) => {
    // const response = await editDriverDetails(updatedData);
    setIsLoading(true);
    const response = await editDriver(token, {
      user_id: info?.id,
      driver_id: updatedData.id,
      email: updatedData.email,
      first_name: updatedData.firstName,
      last_name: updatedData.lastName,
    });
    await getAllDrivers();
    setIsEditModalOpen(false);
    if (response?.status === 200) {
      notification.success({
        message: "Success",
        description: "Driver Details Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const showEditCompanyModal = () => {
    setIsEditCompanyModalOpen(true);
  };

  const handleEditCompanyOk = async (updatedData) => {
    // const response = await editCompanyDetails(updatedData);
    setIsLoading(true);
    const response = await editCompany(token, {
      user_id: updatedData?.id,
      first_name: updatedData?.firstName,
      last_name: updatedData?.lastName,
      phone: updatedData?.mobileNumber,
      address: updatedData?.address,
      user_name: updatedData?.preferredName,
    });
    if (response?.status === 200) {
      info["firstName"] = updatedData?.firstName;
      info["mobileNumber"] = updatedData?.mobileNumber;
      info["address"] = updatedData?.address;
      localStorage.setItem("specificComDetails", JSON.stringify(info));
      await getAllCompanies();
      notification.success({
        message: "Success",
        description: "Company Details Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
    setIsEditCompanyModalOpen(false);
  };

  const handleEditCompanyCancel = () => {
    setIsEditCompanyModalOpen(false);
  };

  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 200,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 175,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Actions",
      width: 175,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
          <ActionButton
            onClick={() => {
              showDeleteDriverModal();
              setCompanyDriverId([{ id: record?.id }]);
            }}
            icon={<RiDeleteBin6Line size={15} />}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
            text="Delete"
          />
          <ActionButton
            onClick={() => {
              showViewDetailsModal();
              setViewDriverInfo(record);
            }}
            icon={<AiFillEye size={15} />}
            text="View"
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
        </div>
      ),
    },
  ];

  const handleDownload = () => {
    csvLink.current.link.click();
  };

  const preprocessData = (originalData) => {
    // Your custom logic to select specific columns
    return originalData.map((item) => ({
      "First Name": item?.firstName,
      "Last Name": item?.lastName,
      Phone: `'${item?.phone}'`,
      Email: item?.email,
      Active: item?.status === true ? "YES" : "NO",
      "Created Date": item?.created_date,
      // You can add more columns if needed
    }));
  };

  // Use the processed data for CSV export
  const processedData = preprocessData(companyDriversList);

  const extractDataByIds = (originalData, ids) => {
    return originalData
      .filter((item) => ids.includes(item.id))
      .map((item) => ({
        "First Name": item?.firstName,
        "Last Name": item?.lastName,
        Phone: `'${item?.phone}'`,
        Email: item?.email,
        Active: item?.status === true ? "YES" : "NO",
        "Created Date": item?.created_date,
      }));
  };

  // Use the extracted data for CSV export
  const extractedData = extractDataByIds(companyDriversList, selectedRowKeys);
  const items = [
    {
      key: "1",
      label: `All Drivers (${
        companyDriversList?.length ? companyDriversList?.length : 0
      })`,
      children: (
        <div className={styles.tabContentWrapper}>
          <div className={styles.backArrowWrapper}>
            <>
              <div className={styles.backArrowLeftWrapper}>
                <HiArrowLeft
                  style={{ cursor: "pointer" }}
                  size={"30"}
                  onClick={() => Navigate(-1)}
                />
                <p>
                  {info?.firstName ? info?.firstName : "XYZ Company"} Drivers
                </p>
              </div>
            </>

            {companyDriversList?.length > 0 && (
              <button
                className={styles.downloadListBtn}
                onClick={handleDownload}
              >
                {<FiDownload size={10} />} {"Download Drivers"}
              </button>
            )}
            {companyDriversList?.length > 0 && (
              <CSVLink
                data={
                  selectedRowKeys?.length > 0 ? extractedData : processedData
                }
                filename="DriverList.csv"
                style={{ display: "none" }}
                ref={csvLink}
                target="_blank"
              />
            )}
          </div>
          <div className={styles.contentAndSearchWrapper}>
            <div className={styles.addressAndPhoneWrapper}>
              <p>
                <span>Plan Expiry:</span> {info?.planEndDate}
              </p>
              <p>
                <span>Email:</span> {info?.email}
              </p>
              <p>
                <span>Phone:</span> {info?.mobileNumber}
              </p>
              <p>
                <span>Address:</span> {info?.address}
              </p>
              <p>
                <span>Subscription Plan:</span> {info?.plan}
              </p>
              {/* <p>
                <span>Remaining Days:</span> {info?.plan}
              </p> */}
              {info?.plan === "free" ? (
                <p>
                  <span>Remaining Days:</span> {`${info?.planRemainingDays}`}
                </p>
              ) : info?.planGraceDays > 1 ? (
                <p>
                  <span>Grace Period: </span>
                  {`${info?.planGraceDays}`}
                </p>
              ) : info?.planGraceDays > 1 && info?.isUnpaid === true ? (
                <p>
                  <span>Expired</span>
                </p>
              ) : (
                ""
              )}
            </div>
            <div className={styles.actionButtonAndSearchBar}>
              <div className={styles.actionButtonsWrapper}>
                <ActionButton
                  onClick={() => {
                    showEditCompanyModal();
                  }}
                  icon={<BiSolidEditAlt size={15} />}
                  text="Edit"
                />

                <ActionButton
                  onClick={() => {
                    showBlockCompanyModal();
                  }}
                  icon={
                    info?.isBlocked === false ? (
                      <MdOutlineBlock size={15} />
                    ) : (
                      ""
                    )
                  }
                  text={info?.isBlocked === false ? "Block" : "Blocked"}
                  isBlocked={info?.isBlocked}
                />
              </div>
              {companyDriversList?.length > 0 ? (
                <Input.Search
                  placeholder="Search here..."
                  onChange={(e) => setSearchText(e.target.value)}
                />
              ) : null}
            </div>
          </div>
          {
            // isLoadingData ? (
            //   <div style={{ margin: "20vh 50%" }}>
            //     <Spin tip="Loading" size="large" />
            //   </div>
            // ) :
            isLoadingData ? (
              <div style={{ margin: "3rem auto" }}>
                <Spin tip="Loading" size="large" />
              </div>
            ) : companyDriversList?.length > 0 ? (
              <>
                {selectedRowKeys?.length > 0 && (
                  <CustomButton
                    style={{
                      width: "160px",
                      height: "33px",
                      borderRadius: "4px",
                      marginBottom: "1rem",
                      fontSize: "12px",
                    }}
                    text={"Delete Selected Drivers"}
                    onClick={showDeleteDriverModal}
                    disabled={isLoading}
                  />
                )}
                <Table
                  columns={ActiveDriversColumns}
                  rowSelection={rowSelection}
                  dataSource={companyDriversList?.map((item) => ({
                    ...item,
                    key: item.id,
                    name: item.firstName,
                    phoneNumber: item.phone,
                    email: item.email,
                  }))}
                  scroll={{
                    x: 1050,
                  }}
                  className="custom-table"
                />
              </>
            ) : (
              <div className={styles.noDataWrapper}>
                <TbFolderExclamation size={100} />
                <p>No Data</p>
              </div>
            )
          }
        </div>
      ),
    },
  ];
  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
        />

        <EditAddedDriverModal
          handleEditAddedDriverCancel={handleEditCancel}
          handleEditAddedDriverOk={handleEditOk}
          isEditAddedDriverModalOpen={isEditModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCsvFileData={updateAllDriversData}
          isLoading={isLoading}
        />

        <ViewDetailsModal
          handleViewDetailsCancel={handleViewDetailsCancel}
          handleViewDetailsOk={handleViewDetailsOk}
          isViewDetailsModalOpen={isViewDetailsModalOpen}
          viewDriverData={viewDriverInfo}
          companyName={info?.firstName}
          companyAddress={info?.address}
          companyNumber={info?.mobileNumber}
        />
        <DeleteDriverModal
          handleDeleteDriverCancel={handleDeleteDriverCancel}
          handleDeleteDriverOk={handleDeleteDriverOk}
          isDeleteDriverModalOpen={isDeleteDriverModalOpen}
          isLoading={isLoading}
        />

        <BlockCompanyModal
          handleBlockCompanyCancel={handleBlockCompanyCancel}
          handleBlockCompanyOk={handleBlockCompanyOk}
          isBlockCompanyModalOpen={isBlockCompanyModalOpen}
          blockStatus={info?.isBlocked}
          isLoading={isLoading}
        />

        <EditCompanyModal
          handleEditCompanyCancel={handleEditCompanyCancel}
          handleEditCompanyOk={handleEditCompanyOk}
          updateCompaniesData={updateCompaniesData}
          isEditCompanyModalOpen={isEditCompanyModalOpen}
          editItemIndex={0}
          editAbleItem={info}
          isLoading={isLoading}
        />
      </div>
    </MainLayout>
  );
}

export default CompanyDrivers;
