import React from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import CustomButton from "components/Button";
import CancelButton from "components/CancelButton";

function ConfirmLogoutModal({
  isConfirmLogoutModalOpen,
  handleConfirmLogoutOk,
  handleConfirmLogoutCancel,
}) {
  return (
    <Modal
      centered
      open={isConfirmLogoutModalOpen}
      onOk={handleConfirmLogoutOk}
      onCancel={handleConfirmLogoutCancel}
      maskClosable={false}
      width={"56%"}s
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "4.75rem",
            gap: "14px",
            width: "100%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleConfirmLogoutCancel}
          >
            Custom Button
          </CancelButton>
          <CustomButton
            text={"Logout"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleConfirmLogoutOk}
          >
            Custom Button
          </CustomButton>
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
          marginTop: "5.8rem",
          marginBottom: "2rem",
          boxSizing: "border-box",
        }}
      >
        <p className={styles.modalText}>
          Are you sure you want to <span>“Logout”?</span>
        </p>
      </div>
    </Modal>
  );
}

export default ConfirmLogoutModal;
