import React from "react";
import { useAuth } from "context";
import { Spin } from "antd";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ route, children }) => {
  const { user, isLoading } = useAuth();
  if (isLoading) {
    return (
      <div className="absCenter">
        <Spin tip="Loading" size="large" />
      </div>
    );
  }
  if (route?.auth && user)
    return (
      <Navigate
        replace
        to={
          (user?.roleName).toLowerCase() === "superadmin"
            ? "/addcompany"
            : "/dashboard"
        }
      />
    );
  if (route?.auth && !user) return children;
  if (route?.protected && !user) return <Navigate to={"/login"} />;
  if (!route?.protected && user) return children;
  
  if (route?.protected && user) {
    // Check if the user is trying to access a route associated with a different role
    if (
      (route.role === "superadmin" && (user?.roleName).toLowerCase() === "admin") ||
      (route.role === "admin" && (user?.roleName).toLowerCase() === "superadmin")
    ) {
      // Redirect to a page for unauthorized access, e.g., a Forbidden page
      return <Navigate replace to="*" />;
    }

    // Continue to render the protected route
    return children;
  }

  
  return children;

  // return isLogin ? children : <Navigate replace to="/login" />;
};

export default PrivateRoute;
