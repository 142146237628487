import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import CustomButton from "components/Button";
import CustomInput from "components/CustomInput";
import { validateAddedDriversEditModal } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import InActiveInput from "components/InActiveInput";
import CancelButton from "components/CancelButton";
import PhoneInputField from "components/PhoneNumberInput";

function EditAddedDriverModal({
  isEditAddedDriverModalOpen,
  handleEditAddedDriverOk,
  handleEditAddedDriverCancel,
  editAbleItem,
  editItemIndex,
  updateCsvFileData,
  isLoading
}) {
  const formikRef = useRef(null);

  const handleCancelClick = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    handleEditAddedDriverCancel();
  }

  const handleUpdateClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  console.log("EditAfterAddDriver", editAbleItem);
  return (
    <Modal
      centered
      open={isEditAddedDriverModalOpen}
      onOk={handleEditAddedDriverOk}
      onCancel={handleCancelClick}
      width={"79%"}
      maskClosable={false}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "94%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            disabled={isLoading}
            onClick={handleCancelClick}
          >
            Custom Button
          </CancelButton>
          <CustomButton
            text={"Update"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleUpdateClick}
          >
            Custom Button
          </CustomButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "94%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p style={{ alignSelf: "flex-start" }} className={styles.modalText}>
          Edit Driver <span>Details</span>
        </p>
        <Formik
          initialValues={{
            firstName: editAbleItem?.firstName || "",
            lastName: editAbleItem?.lastName || "",
            driverEmail: editAbleItem?.email || "",
            driverPhoneNumber: editAbleItem?.phone || "",
          }}
          validationSchema={validateAddedDriversEditModal}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            const updatedItem = {
              ...editAbleItem,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.driverEmail,
              phone: values.driverPhoneNumber,
            };

            // Pass the editItemIndex to the updateCsvFileData function
            updateCsvFileData(updatedItem, editItemIndex);

            // Close the modal
            handleEditAddedDriverOk(updatedItem);
            console.log("Check");
          }}
          innerRef={formikRef}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="driverEmail"
                    name="driverEmail"
                    placeholder="Driver Email"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="driverEmail"
                    component="div"
                    className="error"
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={PhoneInputField}
                    type="text"
                    id="driverPhoneNumber"
                    name="driverPhoneNumber"
                    placeholder="Driver Phone Number"
                    disabled={true}
                    style={{ height: "42px", caretColor: 'transparent' }}
                  />
                  <ErrorMessage
                    name="driverPhoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditAddedDriverModal;
