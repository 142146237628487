import React from 'react'
import { useNavigate } from "react-router-dom"
import styles from "./styles.module.scss"


function PageNotFound() {
    const Navigate= useNavigate();

  return (
    <div className={styles.pageNotFoundWrapper}>
        <p>Oops!</p>
        <p>404</p>
        <p>Something Went Wrong</p>
        <p>Loream ipsum is dummy text</p>

        <button onClick={() => Navigate("/login")}><span>Back to Home Page</span></button>
    </div>
  )
}

export default PageNotFound