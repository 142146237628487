import React, { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import styles from "./styles.module.scss";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { validateEmail } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CustomInput from "components/CustomInput";
import { ReactComponent as CabitIcon } from "assets/icons/CabitIcon.svg";
import CustomButton from "components/Button";
import { backendEndpoints } from "constants/Backend";

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  function handleClick() {
    history("/verifylink");
  }

  const initialValues = {
    email: "",
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.innerLeft}>
          <div className={styles.iconTextWrapper}>
            <Link to={"/"}>
              <CabitIcon />
            </Link>
            <p>
              Ride without Limits. No Logins, No Frills. Just Hop In and Go!
            </p>
          </div>
        </div>
        <div className={styles.innerRight}>
          <div className={styles.welcomeTextWrapper}>
            <p>Forgot Password?</p>
            <p>
              Don’t worry ! It happens. Please enter the Email Address we will
              send the Link in this Email.
            </p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validateEmail}
            onSubmit={(values, actions) => {
              const forgetPassword = async () => {
                setIsLoading(true);
                try {
                  const loginApiToken = process.env.REACT_APP_API_ACCESS_TOKEN;
                  await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.forgetPassword}`,
                    {
                      email: values?.email,
                      // base_url: "http://localhost:3000/changepassword",
                      base_url: `${process.env.REACT_APP_FRONTEND_BASE_URL}/changepassword`,
                    },
                    {
                      headers: {
                        Authorization: `${loginApiToken}`,
                      },
                    }
                  );
                  notification.success({
                    message: "Success",
                    description: "Email Sent Successfully",
                  });
                  setIsLoading(false);
                  handleClick();
                } catch (error) {
                  notification.error({
                    message: "Invalid Email",
                    description: error?.response?.data?.errors?.email[0],
                  });
                  setIsLoading(false);
                }
              };
              forgetPassword();
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    component={CustomInput}
                    type="email"
                    name="email"
                    placeholder="Enter the Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                    text="Continue"
                    onClick={Navigate("/verifylink")}
                    isLoading={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.circleShade}></div>
    </div>
  );
};

export default ForgetPassword;
