import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import CustomButton from "components/Button";
import { Link, useLocation } from "react-router-dom";
import { validateContactUs } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { ReactComponent as AppleIcon } from "assets/icons/AppleIcon.svg";
import { ReactComponent as PlaystoreIcon } from "assets/icons/PlaystoreIcon.svg";
import CustomInput from "components/CustomInput";
import TextArea from "components/TextArea";
import { notification } from "antd";
import LandingPageFooter from "layouts/LandingPageFooter";
import LandingPageHeader from "layouts/LandingPageHeader";
import axios from "axios";
import { backendEndpoints } from "constants/Backend";

function LandingPage() {
  const [isLoading, setIsLoading] = useState(false);

  const { state, pathname } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }

    window.history.replaceState(null, "", pathname);
  }, [targetId, pathname]);

  const handleContactUs = async (data) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.contactUs}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: process.env.REACT_APP_API_ACCESS_TOKEN,
          },
        }
      );
      notification.success({
        message: "Form Submitted Successfully",
      });
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Request Failed",
        description: error,
      });
      setIsLoading(false);
    }
  };
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const tilesArray = [
    {
      id: 1,
      img: "/Images/tracking.png",
      title: "Real-time Tracking",
      subtitle:
        "Users can track the location of available drivers in real time on a map.",
    },
    {
      id: 1,
      img: "/Images/shield.png",
      title: "Intuitive User Interface",
      subtitle:
        "Enjoy a user-friendly interface that simplifies the entire journey, from locating drivers to booking rides, ensuring a seamless and accessible experience for all users..",
    },
    {
      id: 1,
      img: "/Images/tick.png",
      title: "No Login Hassle",
      subtitle:
        "Enjoy the freedom of instant access to Cabber's services without the need for cumbersome logins. Say goodbye to account creation complexities and seamlessly explore and book rides with ease.",
    },
    // {
    //   id: 1,
    //   img: "/Images/paymentCard.png",
    //   title: "Payment Integration",
    //   subtitle:
    //     "Enable secure and convenient in-app payments for both taxi company and Cabber.",
    // },
    {
      id: 1,
      img: "/Images/personTick.png",
      title: "Easy Cab Booking",
      subtitle:
        "Effortlessly book a cab with just a few taps on the Cabber app. Our user-friendly interface ensures a smooth and straight forward booking process, putting the convenience of reliable transportation at your fingertips",
    },
    {
      id: 1,
      img: "/Images/settings.png",
      title: "Direct Communication with Drivers",
      subtitle:
        "Enhance the booking process by enabling users to initiate direct communication with drivers through calls or WhatsApp, fostering convenience and quick coordination for a smoother ride experience",
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <div id="home" className={styles.headerContainer}>
        <img
          alt=""
          className={styles.headerpatternImage}
          src="/Images/landingpageHeaderBgBgimg.png"
        />
        {/* <img
        src="/Images/headerSpiral.png"
        className={styles.headerSpiralBgImg}
        /> */}
        <LandingPageHeader />
        <div className={styles.innerHeaderSection}>
          <div className={styles.introSection}>
            <div className={styles.introHeadingWrapper}>
              <p>
                Tired of waiting for a ride-hailing app to send you a ride?
                Tired of skyrocketing surge pricing during rush hour, holidays,
                and events?
                <br /> Welcome to CABBER - Your passport to quick and effortless
                transportation. <br />
                We will connect you to the nearest taxi for a ride. Yes, a Taxi!
              </p>
            </div>
            <div className={styles.introSubHeadingWrapper}>
              <p>Here is the best part – it’s FREE.</p>
            </div>
            <div className={styles.headerButtonsWrapper}>
              <p className={styles.btnHeading}>Download our app Cabber User</p>
              <div className={styles.innerBtnWrapper}>
                <Link
                  to={process.env.REACT_APP_APP_STORE_USER_APP_URL}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.appBtnWrapper}>
                    <AppleIcon />
                    <div className={styles.appBtnInner}>
                      <p>Download on the</p>
                      <p>App Store</p>
                    </div>
                  </div>
                </Link>
                <Link
                  to={process.env.REACT_APP_GOOGLE_PLAY_USER_APP_URL}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.appBtnWrapper}>
                    <PlaystoreIcon />
                    <div className={styles.appBtnInner}>
                      <p>Get It on</p>
                      <p>Google Play</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* <div className={styles.socialWrapper}>
            <div className={styles.phoneWrapper}>
              <PhoneIcon />
            </div>
            <div className={styles.socialIconsWrapper}>
              <FbIcon />
              <YoutubeIcon />
              <LinkedInIcon />
              <InstaIcon />
            </div>
          </div> */}
          </div>

          <div className={styles.rightImageSection}>
            <img
              alt=""
              className={styles.headerBackgroundImage}
              src="/Images/landingpageHeaderBgBgimg.png"
            />
          </div>
        </div>
      </div>
      <div id="aboutUs" className={styles.aboutUsWrapper}>
        <img
          alt=""
          className={styles.aboutUsBgleft}
          src="/Images/aboutUsBgleft.png"
        />
        <img
          alt=""
          className={styles.aboutUsBgRight}
          src="/Images/aboutUsBgRight.png"
        />
        <div className={styles.aboutUsLeftWrapper}>
          <p>About Us</p>
          <p>
            At Cabber, we are driven by a passion for redefining the way you
            experience transportation. Born from the vision of making every
            journey effortless and enjoyable, Cabber breaks free from the
            conventional norms. <br />
            <br />
            We understand the value of time and the importance of simplicity.
            That's why we've crafted a platform where users can navigate the
            city without the burden of logins, ensuring a seamless and
            liberating travel experience.
            <br />
            <br /> Our commitment is to empower you with the freedom to move at
            your own pace, embracing innovation to create a more accessible and
            efficient future of transportation. Join us on this exciting ride,
            as Cabber transforms the way you move, one journey at a time.
          </p>
        </div>
        <div className={styles.aboutUsRightWrapper}>
          <img alt="" src="/Images/aboutUsBackgroundImage.png" />
        </div>
      </div>
      <div className={styles.interfaceSection}>
        <div className={styles.firstSection}>
          <div className={styles.headingWrapper}>
            <p>Discover Seamless Transportation with Cabber</p>
          </div>
          <p className={styles.firstDes}>
            {" "}
            Our platform connects you with a vast network of reliable and
            professional drivers, ensuring that you can easily locate a cab
            whenever and wherever you need it.{" "}
          </p>
          <div className={styles.bottomWrapper}>
            <div className={styles.bottomLeft}>
              <p>
                With a simple and intuitive user interface, finding a cab
                becomes a hassle-free experience. Whether you're in a bustling
                city center or a quieter neighborhood, "Cabber" is designed to
                meet your transportation needs with efficiency and ease.
              </p>

              <Link
                to={process.env.REACT_APP_GOOGLE_PLAY_APP_URL}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {/* <button className={styles.firstSectionBtn}>
                    Download Now!
                  </button> */}
              </Link>
            </div>
            <img alt="" src="Images/InterfaceImage1.png" />
          </div>
        </div>

        <div className={styles.secondSection}>
          <img alt="" src="/Images/InterfaceImage2.png" />

          <p className={styles.secondSectionHeading}>Explore Privacy Policy</p>
          <p className={styles.secondSectionSubHeading}>
            "Explore our Privacy Policy in the app, ensuring transparency and
            safeguarding your information."
          </p>
        </div>

        <div className={styles.thirdSection}>
          <p className={styles.secondSectionHeading}>
            Intuitive User Interface
          </p>
          <p className={styles.secondSectionSubHeading}>
            Seamless Navigation for Effortless Experiences
          </p>

          <img alt="" src="/Images/InterfaceImage3.png" />
        </div>
      </div>
      <div className={styles.consumerAppSection}>
        <div className={styles.conAppSecHeader}>
          <p>Conusmer App</p>
          <p>
            Effortlessly hail rides, track drivers in real-time, and enjoy a
            seamless travel experience with our intuitive consumer app
          </p>
        </div>
        <div className={styles.conAppSubFirst}>
          <img alt="" src="/Images/conAppImg1.png" />

          <div className={styles.contentWrapper}>
            <div className={styles.numberWrapper}>
              <p>01</p>
            </div>
            <p className={styles.contentDes}>
              Tired of waiting for the hailing Apps - Take a taxi. Tired of
              paying too much during surge - Take a taxi. Around the corner,
              electronic hailing <span>AND IT'S FREE. </span>
              We revolutionize getting from place to place. We will place taxi
              at your finger tips in minutes just download the App "Cabber
              User". A map will appear... Tap the closest taxi near you, you
              will be connected with taxi driver who will pick you up in
              minutes.
            </p>
          </div>
        </div>
        <div className={styles.conAppSubSecond}>
          <img
            alt=""
            className={styles.mobileImage}
            src="/Images/conAppImg2.png"
          />
          <div className={styles.contentWrapper}>
            <div className={styles.numberWrapper}>
              <p>02</p>
            </div>
            <p className={styles.contentDes}>
              Your privacy is paramount to us. Within the Cabber user app, we've
              made it easy for you to stay informed by providing direct access
              to our privacy policy. Take a moment to explore the details,
              ensuring complete transparency on how we handle and protect your
              personal information. At Cabber, your trust is our priority, and
              we're committed to keeping you informed about our data practices
              for a secure and confident ride-sharing experience.
            </p>
          </div>
          <img
            alt=""
            className={styles.desktopImage}
            src="/Images/conAppImg2.png"
          />
        </div>
        <div style={{ marginBottom: "4rem" }} className={styles.conAppSubFirst}>
          <img alt="" src="/Images/conAppImg3.png" />

          <div className={styles.contentWrapper}>
            <div className={styles.numberWrapper}>
              <p>03</p>
            </div>
            <p className={styles.contentDes}>
              Have questions, feedback, or need assistance? Our 'Contact Us'
              page on the Cabber user app is your direct line to our dedicated
              support team. We value your input and are here to ensure a smooth
              and enjoyable experience. Reach out to us effortlessly, and let us
              know how we can enhance your journey with Cabber. Your
              satisfaction is our priority, and we're ready to assist you every
              step of the way.
            </p>
          </div>
        </div>
      </div>
      <div id="features" className={styles.featuresWrapper}>
        <div className={styles.featuresHeader}>
          <p>Key Features</p>
          <p>
            Cabber delivers effortless rides with a no-login approach,
            prioritizing your convenience. Experience swift and reliable
            transportation without the fuss, putting you in control of your
            journey. Here is the best part - it’s FREE
          </p>
        </div>
        <div className={styles.tilesWrapper}>
          {tilesArray.map((item) => (
            <div key={item.id} className={styles.tile}>
              <img alt="" src={item.img} />
              <div className={styles.tileTitleAndSubWrapper}>
                <p>{item.title}</p>
                <p>{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="contactUsSection" className={styles.contactUsWrapper}>
        <div className={styles.contactUsFormWrapper}>
          <p>Let’s Connect</p>
          <p>
            Reach out effortlessly through an intuitive "Let’s Connect" form for
            any inquiries or feedback.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validateContactUs}
            onSubmit={async (values, actions) => {
              setIsLoading(true);
              await handleContactUs({
                name: values?.name,
                email: values?.email,
                subject: "Subject loream",
                body: values?.message,
              });

              actions.setSubmitting(false);
              setIsLoading(false);
              actions.resetForm();
              // console.log("Email", values.email, "\nPassword", values.password);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={"Name"}
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#F2F3FF",
                      height: "56px",
                    }}
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>

                <div>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="email"
                    name="email"
                    placeholder={"Enter Email"}
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#F2F3FF",
                      height: "56px",
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div>
                  <Field
                    component={TextArea}
                    type="text"
                    id="message"
                    name="message"
                    style={{ height: "87px" }}
                    placeholder={"Your Message"}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error"
                  />
                </div>
                <CustomButton
                  style={{ marginTop: "5px", height: "53px" }}
                  text={"Send"}
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
}

export default LandingPage;
