import * as Yup from "yup";
const nameRegex = /^[A-Za-z]+$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validateContactUs = Yup.object().shape({
  name: Yup.string()
  .matches(nameRegex, "Name must contain only alphabets")
  .required("First Name is required"),
  email: Yup.string()
  .matches(emailRegex, "Invalid email address")
  .required("Email is required"),
  message: Yup.string().required("Message is required").min(11, "Message should have more than 10 characters"),
});


