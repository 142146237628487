import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import { Checkbox } from "antd";
import { Table } from "antd";
import { Link } from "react-router-dom";
import CustomButton from "components/Button";
import PaymentTable from "components/Tables/PaymentTable";
import PaymentForm from "pages/Payment/PaymentForm";
import PaymentFormWrapper from "pages/Payment";

function PayInvoiceModal({
  isPayInvoiceModalOpen,
  handlePayInvoiceOk,
  handlePayInvoiceCancel,
  data,
  perDriverCost,
  lastUnpaidInvoice,
  userData,
  isLoading,
  checkboxCheck,
  toggleCheckBox,
  handleShowConfirmLogout,
  showContactAdminModal,
}) {
  const stripeChargesPercentage =
    (2.9 / 100) * Number(userData?.unPaidInvoices[0]?.amount);
  const pfAndStripe = Number(stripeChargesPercentage + 0.3).toFixed(2);
  const totalCharges = (
    Number(userData?.unPaidInvoices[0]?.amount) + Number(pfAndStripe)
  ).toFixed(2);
  const ActiveDriversColumns = [
    {
      title: "Company Name",
      dataIndex: "firstName",
      width: 250,
    },
    {
      title: "Total Drivers",
      dataIndex: "totalDrivers",
      width: 200,
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      render: (text, record) => `$ ${text}`,
    },
  ];
  return (
    <Modal
      centered
      open={isPayInvoiceModalOpen}
      onOk={handlePayInvoiceOk}
      onCancel={handlePayInvoiceCancel}
      width={"85%"}
      maskClosable={false}
      closable={userData?.isBlocked ? false : true}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: userData?.isUnpaid ? "flex-end" : "center",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "88%",
          }}
        >
          {" "}
          {userData?.isUnpaid ? (
            <CustomButton
              text={"Pay Now"}
              key="customButton"
              style={{
                width: "156px",
                fontSize: "15px",
                height: "38px",
              }}
              onClick={handlePayInvoiceOk}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Custom Button
            </CustomButton>
          ) : (
            <>
              <CustomButton
                text={"Logout"}
                key="customButton"
                style={{
                  width: "156px",
                  fontSize: "15px",
                  height: "38px",
                }}
                onClick={handleShowConfirmLogout}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Custom Button
              </CustomButton>
              <CustomButton
                text={"Contact Admin"}
                key="customButton"
                style={{
                  width: "156px",
                  fontSize: "15px",
                  height: "38px",
                }}
                onClick={showContactAdminModal}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Custom Button
              </CustomButton>
            </>
          )}
        </div>,
      ]}
    >
      {}
      <div
        style={{
          width: "88%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        {userData?.unPaidInvoices?.length !== 0 ? (
          <>
            <Table
              columns={ActiveDriversColumns}
              dataSource={userData?.unPaidInvoices?.map((item) => ({
                ...item,
                key: item.id,
                firstName: userData?.firstName,
                totalDrivers: item.totalDrivers,
                amount: totalCharges,
              }))}
              scroll={{
                x: 750,
              }}
              pagination={userData?.length > 4 ? true : false}
              className="custom-table-inactive"
            />{" "}
            <div className={styles.termsAndBillingWrapper}>
              <div className={styles.termsAndConditionsWrapper}>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    className="custom-checkbox"
                    // checked={true}
                    onChange={(e) => toggleCheckBox(!checkboxCheck)}
                  >
                    <p>Terms & Conditions</p>
                  </Checkbox>
                </div>

                <p>
                  By clicking 'I agree,' you acknowledge that you have read and
                  understood the{" "}
                  <Link
                    style={{ textDecoration: "none", color: "#ffaa00" }}
                    target="_blank"
                    to={"/termsandconditions"}
                  >
                    terms and conditions
                  </Link>{" "}
                  and agree to be bound by them
                </p>
              </div>
              <div className={styles.billingDetailsWrapper}>
                <div className={styles.subTotal}>
                  <p>Lisence Fee:</p>
                  {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
                  <p>{`$ ${userData?.unPaidInvoices[0]?.subAmount}`}</p>
                </div>
                <div className={styles.subTotal}>
                  <p>Tax (Lisence Fee + 6%):</p>
                  {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
                  <p>{`$ ${userData?.unPaidInvoices[0]?.subTaxes}`}</p>
                </div>
                <div className={styles.subTotal}>
                  <p>Sub Total:</p>
                  {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
                  <p>{`$ ${
                    Number(userData?.unPaidInvoices[0]?.subTaxes) +
                    Number(userData?.unPaidInvoices[0]?.subAmount)
                  }`}</p>
                </div>
                <div className={styles.subTotal}>
                  <p>Transaction Charges:</p>
                  {/* <p>{`$ ${totalPayAble < 0.5 ? 0.5 : totalPayAble}`}</p> */}
                  <p>{`$ ${pfAndStripe}`}</p>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.total}>
                  <p>Total Payable Amount:</p>
                  <p>{`$ ${totalCharges}`}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Contact support. Your Company is blocked</p>
        )}
      </div>
    </Modal>
  );
}

export default PayInvoiceModal;
