import * as Yup from "yup";
const nameRegex = /^[A-Za-z\s]+$/;
const phoneNumberPattern = /^[+-]?\d+(\.\d+)?( |$)/;

export const validateCompanyEditModal = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("This field is required"),
    // confirmCompanyName: Yup.string()
    // .oneOf([Yup.ref("firstName"), null], "Company Name must match")
    // .required("This field is required"),
  companyPhone: Yup.string()
    .matches(phoneNumberPattern, "Phone Number must contain only digits")
    .max(15, "Phone Number must not exceed 11 characters")
    .required("Phone is required"),
  // confirmCompanyPhone: Yup.string()
  //   .oneOf([Yup.ref("companyPhone"), null], "Company must match")
  //   .required("Confirm Phone Number is required"),
  companyAddress: Yup.string().required("Phone Address is required"),
  // confirmCompanyAddress: Yup.string()
  //   .oneOf([Yup.ref("companyAddress"), null], "Company Address must match")
  //   .required("Confirm Address is required"),
});
