import React from "react";
import styles from "./styles.module.scss";
import {useNavigate} from "react-router-dom"
import { Modal } from "antd";
import { ReactComponent as PaymentSuccessIcon } from "assets/icons/PaymentSuccessIcon.svg";
import CustomButton from "components/Button";

function PaymentSuccessModal({
  isPaymentSuccessModalOpen,
  handlePaymentSuccessOk,
  handlePaymentSuccessCancel,
}) {
  const Navigate= useNavigate();
  return (
    <Modal
      centered
      open={isPaymentSuccessModalOpen}
      onOk={handlePaymentSuccessOk}
      onCancel={handlePaymentSuccessCancel}
      maskClosable={false}
      closable={false}
      width={"85%"}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2.75rem",
            width: "100%",
          }}
        >
          <button
            className={styles.modalCustomButton}
            key="customButton"
            onClick={handlePaymentSuccessOk}
          >
            <span>Back to Home Page</span>
          </button>
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.8rem",
          marginBottom: "1.8rem",
          boxSizing: "border-box",
        }}
      >
        {/* <PaymentSuccessIcon /> */}
        <img width={"256px"} height={"256px"} src="/Images/tickSuccess.png"/>
        <p className={styles.modalText}>Your Payment is Successfull</p>
        <p className={styles.modalSubtitle}>Thank you for your payment.</p>
      </div>
    </Modal>
  );
}

export default PaymentSuccessModal;
