import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Table, Input } from "antd";
import { TbFolderExclamation } from "react-icons/tb";

function CompanyTableContainer({ data }) {
  const [searchText, setSearchText] = useState("");

  const InActiveCompanycolumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 200,
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
  ];

  return (
    <div className={styles.companyTableWrapper}>
      {data && data?.length > 0 ? (
        <>
          <div className={styles.titleAndBarWrapper}>
            <p>Recent Companies</p>
            <Input.Search
              placeholder="Search here..."
              style={{ marginBottom: "1rem", width: "40%" }}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <Table
            columns={InActiveCompanycolumns}
            dataSource={data?.map((item) => ({
              ...item,
              key: item.id,
              name: item.firstName,
              email: item.email,
              phoneNumber: item.mobileNumber,
            }))}
            scroll={{
              x: 750,
            }}
            className="custom-table-inactive"
          />
        </>
      ) : (
        <div className={styles.noDataWrapper}>
          <TbFolderExclamation size={100} />
          <p>No Data</p>
        </div>
      )}
    </div>
  );
}

export default CompanyTableContainer;
