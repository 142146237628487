import axios from "axios";
import { header } from "constants";
import { authCatchHandler } from "Utils/authCatchHandler";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_API_ACCESS_TOKEN}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    const err = authCatchHandler(error);
    if (err) {
      console.log("Error", err);
    }
    throw error;
  }
);
