import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as CabitFooterIcon } from "assets/icons/CabitFooterIcon.svg";
import { ReactComponent as AppleIcon } from "assets/icons/AppleIcon.svg";
import { ReactComponent as PlaystoreIcon } from "assets/icons/PlaystoreIcon.svg";

function LandingPageFooter() {
  const location = useLocation();
  const URL = location.pathname;
  const navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  console.log("windowWidth", windowWidth?.current);
  return (
    <div className={styles.footerWrapper}>
      <img className={styles.footerBgimg} src="/Images/footerBgimg.png" />
      <div className={styles.footerTop}>
        <div className={styles.topLeft}>
          <Link to={"/"}>
            <CabitFooterIcon />
          </Link>
          {URL === "/taxicompany" || URL === "/" ? (
            <div className={styles.footerNavWrapper}>
              <p onClick={() => scrollToSection("home")}>Home</p>
              <p onClick={() => navigate("/taxicompany")}>Taxi Company</p>
              <p
                onClick={() =>
                  URL === "/taxicompany"
                    ? navigate("/", { state: { targetId: "aboutUs" } })
                    : scrollToSection("aboutUs")
                }
              >
                About Us
              </p>
              <p onClick={() => scrollToSection("contactUsSection")}>
                Contact Us
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.topRight}>
          <div className={styles.rightContentWrapper}>
            <p>
              THE APP FOR THE {URL === "/taxicompany" ? "DRIVER" : "CONSUMER"}
            </p>
            <p>Install your smartphone app and go live instantly.</p>
          </div>
          <div className={styles.rightButtonsWrapper}>
            <div className={styles.qrAndBtnWrapper}>
              {URL === "/taxicompany" ? (
                <img src="/Images/qrDriverAppIos.png" />
              ) : (
                <img src="/Images/qrUserAppIos.png" />
              )}
              <Link
                to={
                  URL === "/taxicompany"
                    ? process.env.REACT_APP_APP_STORE_DRIVER_APP_URL
                    : process.env.REACT_APP_APP_STORE_USER_APP_URL
                }
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button>
                  <AppleIcon />
                  <span>
                    <p>Download on the</p>
                    <p>App Store</p>
                  </span>
                </button>
              </Link>
            </div>
            <div className={styles.qrAndBtnWrapper}>
              {URL === "/taxicompany" ? (
                <img src="/Images/qrDriverAppAndriod.png" />
              ) : (
                <img src="/Images/qrUserAppAndriod.png" />
              )}
              <Link
                to={
                  URL === "/taxicompany"
                    ? process.env.REACT_APP_GOOGLE_PLAY_DRIVER_APP_URL
                    : process.env.REACT_APP_GOOGLE_PLAY_USER_APP_URL
                }
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <button>
                  <PlaystoreIcon />
                  <span>
                    <p>GET IT ON</p>
                    <p>Google Play</p>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className={styles.socialWrapper}>
            <Link to={process.env.REACT_APP_FB_URL} target="_blank">
              <img src="/Images/fbLogo.png" />
            </Link>
            <Link to={process.env.REACT_APP_LINKEDIN_URL} target="_blank">
              <img src="/Images/linkedInLogo.png" />
            </Link>
            <Link to={process.env.REACT_APP_INSTA_URL} target="_blank">
              <img src="/Images/instalogo.png" />
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>Copyright © 2023 Cabber on Demand Cab Booking Platform.</p>
        <div className={styles.termsAndPolicyWrapper}>
          <p>
            <Link to={"/termsandconditions"}>Terms & Conditions</Link> |{" "}
            <Link to={"/privacypolicy"}>Privacy Policy</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPageFooter;
