import React, { useState } from "react";
import styles from "./styles.module.scss";
import CustomButton from "components/Button";
import { validateContactUs } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { FaArrowRight } from "react-icons/fa6";
import CustomInput from "components/CustomInput";
import TextArea from "components/TextArea";
import { notification } from "antd";
import LandingPageFooter from "layouts/LandingPageFooter";
import LandingPageHeader from "layouts/LandingPageHeader";
import axios from "axios";
import { backendEndpoints } from "constants/Backend";
import { GoCheck } from "react-icons/go";

function TaxiCompany() {
  const [isLoading, setIsLoading] = useState(false);

  const handleContactUs = async (data) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.contactUs}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: process.env.REACT_APP_API_ACCESS_TOKEN,
          },
        }
      );
      notification.success({
        message: "Form Submitted Successfully",
      });
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Request Failed",
        description: error,
      });
      setIsLoading(false);
    }
  };
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div id="home" className={styles.headerContainer}>
        <img
          alt=""
          className={styles.headerpatternImage}
          src="/Images/taxicompanyBgBgImage.png"
        />
        {/* <img
          src="/Images/headerSpiral.png"
          className={styles.headerSpiralBgImg}
        /> */}
        <LandingPageHeader />
        <div className={styles.innerHeaderSection}>
          <div className={styles.introSection}>
            <div className={styles.introHeadingWrapper}>
              <p>
                We will level the playing field between you and ride hailing
                apps.
              </p>
            </div>
            <div className={styles.introSubHeadingWrapper}>
              <p>
                The consumer needs transportation. You open our app and see a
                Google map showing the real-time GPS locations of taxis near
                you. Here’s the best part: <span>The App is FREE!</span>
              </p>
            </div>
            {/* <button
              className={styles.headerBtn}
              onClick={() => scrollToSection("contactUsSection")}
            >
              Let's schedule a demo
            </button> */}
            {/* <div className={styles.headerButtonsWrapper}>
            <div className={styles.appBtnWrapper}>
              <AppleIcon />
              <div className={styles.appBtnInner}>
                <p>Downlaod on the</p>
                <p>App Store</p>
              </div>
            </div>
            <div className={styles.appBtnWrapper}>
              <PlaystoreIcon />
              <div className={styles.appBtnInner}>
                <p>Get it on</p>
                <p>Google Play</p>
              </div>
            </div>
          </div> */}
            {/* <div className={styles.socialWrapper}>
            <div className={styles.phoneWrapper}>
              <PhoneIcon />
            </div>
            <div className={styles.socialIconsWrapper}>
              <FbIcon />
              <YoutubeIcon />
              <LinkedInIcon />
              <InstaIcon />
            </div>
          </div> */}
          </div>
          <div className={styles.rightImageSection}>
            <img
              alt=""
              className={styles.headerBackgroundImage}
              src="/Images/taxicompanyBgBgImage.png"
            />
          </div>
        </div>
      </div>
      <div className={styles.taxiCompanySection}>
        <div className={styles.conAppSecHeader}>
          <p>Taxi Company</p>
          <p>
            <span>The hailing apps</span> have succeeded and have taken your
            customers because of smartphones. Now, your drivers can provide and
            surpass their offer of convenience and certainty. Your customer can
            directly
            <span> HAIL YOUR DRIVER</span> using our app, Cabber on their phone.
          </p>
        </div>
        <div className={styles.conAppSubFirst}>
          <img alt="" src="/Images/taxiCompanySec1.png" />

          <div className={styles.contentWrapper}>
            <div className={styles.noAndContent}>
              <div className={styles.numberWrapper}>
                <p>01</p>
              </div>
              <p className={styles.contentDes}>Effortless Driver Onboarding</p>
            </div>

            <p className={styles.contentDes}>
              Empowering taxi companies with seamless control, Cabber allows you
              to effortlessly expand your fleet by adding drivers individually
              or in bulk through the convenience of a CSV file. Streamlining the
              onboarding process, our platform caters to the dynamic needs of
              your business, offering flexibility and efficiency in managing
              your driver network. Whether you're scaling up or enhancing
              operational agility, Cabber provides an intuitive solution for
              taxi companies to navigate the road to success, one driver at a
              time.
            </p>
          </div>
        </div>
        <div className={styles.conAppSubSecond}>
          <img
            alt=""
            className={styles.mobileImage}
            src="/Images/taxiCompanySec2.png"
          />
          <div className={styles.contentWrapper}>
            <div className={styles.noAndContent}>
              <div className={styles.numberWrapper}>
                <p>02</p>
              </div>
              <p className={styles.contentDes}>Dynamic Billing Flexibility</p>
            </div>

            <p className={styles.contentDes}>
              After a 30-day free trial, Cabber enables taxi companies to pay
              per driver, ensuring flexibility and cost-effectiveness. This
              transparent model optimizes financial management, allowing
              companies to invest strategically in their active drivers.
            </p>
          </div>
          <img
            alt=""
            className={styles.desktopImage}
            src="/Images/taxiCompanySec2.png"
          />
        </div>
        <div style={{ marginBottom: "4rem" }} className={styles.conAppSubFirst}>
          <img alt="" src="/Images/taxiCompanySec3.png" />

          <div className={styles.contentWrapper}>
            <div className={styles.noAndContent}>
              <div className={styles.numberWrapper}>
                <p>03</p>
              </div>
              <p className={styles.contentDes}> Fleet Management Control</p>
            </div>

            <p className={styles.contentDes}>
              With Cabber, taxi companies wield unparalleled control over their
              fleet. Effortlessly view, edit, delete, and add drivers through
              our intuitive platform. This robust fleet management feature
              ensures agility and efficiency in optimizing your driver network.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.whyChooseUs}>
        <div className={styles.whyChooseUsHeader}>
          <p>Why Choose Us?</p>
          <p>
            Choosing Cabber for taxi companies brings a myriad of benefits,
            making it the ideal platform for transforming and elevating your
            transportation business
          </p>
        </div>

        <div className={styles.whyChooseUsObjs}>
          <div className={styles.whyChooseUsObjLeft}>
            <p>Driver Management</p>
            <p>Administrative Control</p>
            <p>Detailed Driver Profiles</p>
          </div>
          <div className={styles.whyChooseUsObjMiddle}>
            <img alt="" src="/Images/whyChooseUsMainImg.png" />
          </div>
          <div className={styles.whyChooseUsObjRight}>
            <p>30-Day Free Trial</p>
            <p>Comprehensive Driver Management</p>
            <p>Flexible Payment Options</p>
          </div>
        </div>
        <div className={styles.lastObj}>
          <p>Effortless Driver Onboarding</p>
        </div>
      </div>
      <div className={styles.getStartedSection}>
        <div className={styles.getStartedContentWrapper}>
          <p>Elevate Your Taxi Business with Cabber</p>
          <p>
            Join the Future of Transportation — Seamless Fleet Management,
            Dynamic Billing, and Unparalleled Control for Taxi Companies.
          </p>
          <button
            className={styles.getStartedBtn}
            onClick={() => scrollToSection("contactUsSection")}
          >
            Get Started <FaArrowRight size="15" />
          </button>
        </div>
      </div>
      <div className={styles.pricingSection}>
        <div className={styles.pricingSectionHeader}>
          <p>Pricing</p>
          <p>
            Explore Our Transparent and Flexible Pricing Plans Tailored to Meet
            Your Needs
          </p>
        </div>

        <div className={styles.subsPackagesWrapper}>
          <div className={styles.subsPackageFirst}>
            <p className={styles.subsHeading}>Free Trial</p>
            <div className={styles.subsPrice}>
              <p>30</p>
              <p>Days</p>
            </div>

            <div className={styles.pricingSectionFeatures}>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>No Upfront Payment</p>
              </div>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>Full access to features</p>
              </div>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>Unlimited Driver Onboarding</p>
              </div>
            </div>

            {/* <div className={styles.infoWrapper}>
              <RxInfoCircled size={80} />
              <p>Small businesses and startups looking to gain data insights</p>
            </div> */}

            <button
              className={styles.subsBtn}
              onClick={() => scrollToSection("contactUsSection")}
            >
              Schedule a demo <FaArrowRight size="15" />
            </button>
          </div>

          <div className={styles.subsPackageSecond}>
            <p className={styles.subsHeading}>Monthly</p>
            <div className={styles.subsPrice}>
              <p>$</p>
              <p>5</p>
              <div className={styles.eachDriver}>
                <p>Each</p>
                <p>Driver</p>
              </div>
            </div>

            <div className={styles.pricingSectionFeatures}>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>Full access to features</p>
              </div>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>Flexible Subscription</p>
              </div>
              <div className={styles.singleFeaturesWrapper}>
                <GoCheck size={"25"} color="#000" />
                <p>Dedicated Support</p>
              </div>
            </div>

            {/* <div className={styles.infoWrapper}>
              <RxInfoCircled size={80} />
              <p>Small businesses and startups looking to gain data insights</p>
            </div> */}

            <button
              className={styles.subsBtn}
              onClick={() => scrollToSection("contactUsSection")}
            >
              Schedule a demo <FaArrowRight size="15" />
            </button>
          </div>
        </div>
      </div>
      <div id="contactUsSection" className={styles.contactUsWrapper}>
        <div className={styles.contactUsFormWrapper}>
          <p>Let’s Connect</p>
          <p>
            Reach out effortlessly through an intuitive "Let’s Connect" form for
            any inquiries or feedback.
          </p>

          <Formik
            initialValues={initialValues}
            validationSchema={validateContactUs}
            onSubmit={async (values, actions) => {
              setIsLoading(true);
              await handleContactUs({
                name: values?.name,
                email: values?.email,
                subject: "Subject loream",
                body: values?.message,
              });

              actions.setSubmitting(false);
              setIsLoading(false);
              actions.resetForm();
              // console.log("Email", values.email, "\nPassword", values.password);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="name"
                    name="name"
                    placeholder={"Name"}
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#F2F3FF",
                      height: "56px",
                    }}
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>

                <div>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="email"
                    name="email"
                    placeholder={"Enter Email"}
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#F2F3FF",
                      height: "56px",
                    }}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div>
                  <Field
                    component={TextArea}
                    type="text"
                    id="message"
                    name="message"
                    style={{ height: "87px" }}
                    placeholder={"Your Message"}
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="error"
                  />
                </div>
                <CustomButton
                  style={{ marginTop: "5px", height: "53px" }}
                  text={"Send"}
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
}

export default TaxiCompany;
