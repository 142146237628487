import React, { useState, useRef } from "react";
import axios from "axios";
import MainLayout from "layouts/MainLayout";
import styles from "./styles.module.scss";
import { Table, Tabs, notification, Input } from "antd";
import { TbFolderExclamation } from "react-icons/tb";
import { BiSolidEditAlt } from "react-icons/bi";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import EditCompanyModal from "components/Modals/EditCompanyModal";
import BlockCompanyModal from "components/Modals/BlockCompanyModal";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import ActionButton from "components/ActionButton";
import CustomButton from "components/Button";
import { CSVLink } from "react-csv";
import { blockCompany } from "APIs/Services";
import { editCompany } from "APIs/Services";

function AllCompanies() {
  const {
    tokenUser,
    allCompaniesList,
    setAllCompaniesList,
    getAllCompanies,
    handleUnautherized,
  } = useAuth();
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [companyData, setCompanyData] = useState();
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
  const [isBlockCompanyModalOpen, setIsBlockCompanyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchAllText, setSearchAllText] = useState("");
  const [searchActiveText, setSearchActiveText] = useState("");
  const [searchInActiveText, setSearchInActiveText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysActiveCompanies, setSelectedRowKeysActiveCompanies] =
    useState([]);
  const [selectedRowKeysBlockedCompanies, setSelectedRowKeysBlockedCompanies] =
    useState([]);

  const csvLink = useRef();
  const csvLinkActiveCompanies = useRef();
  const csvLinkBlockedCompanies = useRef();

  const Navigate = useNavigate();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;

  const showEditModalWithData = (item) => {
    const itemIndex = allCompaniesList.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditCompanyModal();
  };

  const updateCompaniesData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...allCompaniesList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setAllCompaniesList(updatedCsvFileDataTemp);
  };

  // const editCompanyDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
  //       {
  //         user_id: item?.id,
  //         first_name: item?.firstName,
  //         last_name: item?.lastName,
  //         phone: item?.mobileNumber,
  //         address: item?.address,
  //         user_name: item?.preferredName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Company Details Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  // const blockCompany = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.blockCompany}`,
  //       {
  //         user_id: companyData.id,
  //         is_blocked: !companyData.isBlocked,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  const showEditCompanyModal = () => {
    setIsEditCompanyModalOpen(true);
  };

  const handleEditCompanyOk = async (updatedData) => {
    // await editCompanyDetails(updatedData);
    // await getAllCompanies();
    // setIsEditCompanyModalOpen(false);

    setIsLoading(true);
    const response = await editCompany(token, {
      user_id: updatedData?.id,
      first_name: updatedData?.firstName,
      last_name: updatedData?.lastName,
      phone: updatedData?.mobileNumber,
      address: updatedData?.address,
      user_name: updatedData?.preferredName,
    });

    if (response?.status === 200) {
      await getAllCompanies();
      notification.success({
        message: "Success",
        description: "Company Details Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }

    setIsEditCompanyModalOpen(false);
  };

  const handleEditCompanyCancel = () => {
    setIsEditCompanyModalOpen(false);
  };

  const showBlockCompanyModal = () => {
    setIsBlockCompanyModalOpen(true);
  };

  const handleBlockCompanyOk = async () => {
    // await blockCompany();
    // await getAllCompanies();
    // setIsBlockCompanyModalOpen(false);
    setIsLoading(true);
    const response = await blockCompany(token, {
      user_id: companyData.id,
      is_blocked: !companyData.isBlocked,
    });

    if (response?.status === 200) {
      await getAllCompanies();
      notification.success({
        message: "Success",
        description: "Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
    setIsBlockCompanyModalOpen(false);
  };

  const handleBlockCompanyCancel = () => {
    setIsBlockCompanyModalOpen(false);
  };

  const handleDownload = () => {
    csvLink.current.link.click();
  };
  const handleDownloadActiveCompanies = () => {
    csvLinkActiveCompanies.current.link.click();
  };

  const handleDownloadBlockedCompanies = () => {
    csvLinkBlockedCompanies.current.link.click();
  };

  const preprocessData = (originalData) => {
    // Your custom logic to select specific columns
    return originalData?.map((item) => ({
      "Company Name": item?.firstName,
      Email: item?.email,
      "Phone Number": `'${item?.mobileNumber}'`,
      Plan: item?.plan,
      "Remaining Days": item?.planRemainingDays,
      "Grace Period": item?.planGraceDays,
      "No. of drivers": item?.totalDrivers,
      "Total Paid Amount": item?.paidAmount,
      Blocked: item?.isBlocked === true ? "YES" : "NO",
      UnPaid: item?.isUnpaid === true ? "YES" : "NO",
      // You can add more columns if needed
    }));
  };

  // Use the processed data for CSV export
  const processedData = preprocessData(allCompaniesList);
  const processedDataActiveCompanies = preprocessData(
    allCompaniesList?.filter((item) => !item.isBlocked)
  );
  const processedDataBlockedCompanies = preprocessData(
    allCompaniesList?.filter((item) => item.isBlocked)
  );

  const extractDataByIds = (originalData, ids) => {
    return originalData
      ?.filter((item) => ids.includes(item.id))
      ?.map((item) => ({
        "Company Name": item?.firstName,
        Email: item?.email,
        "Phone Number": `'${item?.mobileNumber}'`,
        Plan: item?.plan,
        "Remaining Days": item?.planRemainingDays,
        "Grace Period": item?.planGraceDays,
        "No. of drivers": item?.totalDrivers,
        "Total Paid Amount": item?.paidAmount,
        Blocked: item?.isBlocked === true ? "YES" : "NO",
        UnPaid: item?.isUnpaid === true ? "YES" : "NO",
      }));
  };

  // Use the extracted data for CSV export
  const extractedData = extractDataByIds(allCompaniesList, selectedRowKeys);
  const extractedDataActiveCompanies = extractDataByIds(
    allCompaniesList,
    selectedRowKeysActiveCompanies
  );
  const extractedDataBlockedCompanies = extractDataByIds(
    allCompaniesList,
    selectedRowKeysBlockedCompanies
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onSelectChangeActiveCompanies = (newSelectedRowKeys) => {
    setSelectedRowKeysActiveCompanies(newSelectedRowKeys);
  };
  const rowSelectionActiveCompanies = {
    selectedRowKeysActiveCompanies,
    onChange: onSelectChangeActiveCompanies,
  };

  const onSelectChangeBlockedCompanies = (newSelectedRowKeys) => {
    setSelectedRowKeysBlockedCompanies(newSelectedRowKeys);
  };
  const rowSelectionBlockedCompanies = {
    selectedRowKeysBlockedCompanies,
    onChange: onSelectChangeBlockedCompanies,
  };

  const AllDriversColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers`}
          onClick={() =>
            localStorage.setItem("specificComDetails", JSON.stringify(record))
          }
        >
          {text}
        </Link>
      ),
      filteredValue: [searchAllText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: 120,
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      render: (text, record) => (
        <div>
          <p>{`${(record?.plan).toUpperCase()}`}</p>
        </div>
      ),
    },
    {
      title: "Remaining Days",
      dataIndex: "planRemainingDays",
      width: 230,
      sorter: (a, b) => a.planRemainingDays - b.planRemainingDays,
      render: (text, record) => (
        <div>
          <p>
            {record?.plan === "free" ? `${record?.planRemainingDays}` : "--"}
          </p>
        </div>
      ),
    },
    {
      title: "Grace Period",
      dataIndex: "planGraceDays",
      width: 210,
      sorter: (a, b) => a.planGraceDays - b.planGraceDays,
      render: (text, record) => (
        <div>
          <p>{record?.plan === "paid" ? `${record?.planGraceDays}` : "--"}</p>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: 210,
      sorter: (a, b) => a.mobileNumber - b.mobileNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Driver",
      dataIndex: "totalDrivers",
      width: 120,
      sorter: (a, b) => a.totalDrivers - b.totalDrivers,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
          <ActionButton
            onClick={() => {
              showBlockCompanyModal();
              setCompanyData(record);
            }}
            icon={
              record?.isBlocked === false ? <MdOutlineBlock size={15} /> : ""
            }
            text={record?.isBlocked === false ? "Block" : "Blocked"}
            // style={{
            //   width: "50px",
            //   backgroundColor: record?.isBlocked === false ? "" : "yellow",
            //   color: record?.isBlocked === false ? "" : "black",
            // }}
            isBlocked={record?.isBlocked}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
          <ActionButton
            onClick={() => {
              Navigate(`/companydrivers`);
              localStorage.setItem(
                "specificComDetails",
                JSON.stringify(record)
              );
            }}
            icon={<AiFillEye size={15} />}
            text="View"
            isDisabled={selectedRowKeys.length > 0 ? true : false}
          />
        </div>
      ),
    },
  ];

  const ActiveCompanycolumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers`}
          onClick={() =>
            localStorage.setItem("specificComDetails", JSON.stringify(record))
          }
        >
          {text}
        </Link>
      ),
      filteredValue: [searchActiveText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.mobileNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: 120,
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      render: (text, record) => (
        <div>
          <p>{`${(record?.plan).toUpperCase()}`}</p>
        </div>
      ),
    },
    {
      title: "Remaining Days",
      dataIndex: "planRemainingDays",
      width: 230,
      sorter: (a, b) => a.planRemainingDays - b.planRemainingDays,
      render: (text, record) => (
        <div>
          <p>
            {record?.plan === "free" ? `${record?.planRemainingDays}` : "--"}
          </p>
        </div>
      ),
    },
    {
      title: "Grace Period",
      dataIndex: "planGraceDays",
      width: 210,
      sorter: (a, b) => a.planGraceDays - b.planGraceDays,
      render: (text, record) => (
        <div>
          <p>{record?.plan === "paid" ? `${record?.planGraceDays}` : "--"}</p>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: 210,
      sorter: (a, b) => a.mobileNumber - b.mobileNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Driver",
      dataIndex: "totalDrivers",
      width: 120,
      sorter: (a, b) => a.totalDrivers - b.totalDrivers,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
            isDisabled={
              selectedRowKeysActiveCompanies.length > 0 ? true : false
            }
          />
          <ActionButton
            onClick={() => {
              showBlockCompanyModal();
              setCompanyData(record);
            }}
            icon={
              record?.isBlocked === false ? <MdOutlineBlock size={15} /> : ""
            }
            text={record?.isBlocked === false ? "Block" : "Blocked"}
            // style={{
            //   width: "50px",
            //   backgroundColor: record?.isBlocked === false ? "" : "yellow",
            //   color: record?.isBlocked === false ? "" : "black",
            // }}
            isBlocked={record?.isBlocked}
            isDisabled={
              selectedRowKeysActiveCompanies.length > 0 ? true : false
            }
          />
          <ActionButton
            onClick={() => {
              Navigate(`/companydrivers`);
              localStorage.setItem(
                "specificComDetails",
                JSON.stringify(record)
              );
            }}
            icon={<AiFillEye size={15} />}
            text="View"
            isDisabled={
              selectedRowKeysActiveCompanies.length > 0 ? true : false
            }
          />
        </div>
      ),
    },
  ];

  const InActiveCompanycolumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers`}
          onClick={() =>
            localStorage.setItem("specificComDetails", JSON.stringify(record))
          }
        >
          {text}
        </Link>
      ),
      filteredValue: [searchInActiveText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.mobileNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      width: 120,
      sorter: (a, b) => a.plan.localeCompare(b.plan),
      render: (text, record) => (
        <div>
          <p>{`${(record?.plan).toUpperCase()}`}</p>
        </div>
      ),
    },
    {
      title: "Remaining Days",
      dataIndex: "planRemainingDays",
      width: 230,
      sorter: (a, b) => a.planRemainingDays - b.planRemainingDays,
      render: (text, record) => (
        <div>
          <p>
            {record?.plan === "free" ? `${record?.planRemainingDays}` : "--"}
          </p>
        </div>
      ),
    },
    {
      title: "Grace Period",
      dataIndex: "planGraceDays",
      width: 210,
      sorter: (a, b) => a.planGraceDays - b.planGraceDays,
      render: (text, record) => (
        <div>
          <p>{record?.plan === "paid" ? `${record?.planGraceDays}` : "--"}</p>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "mobileNumber",
      width: 210,
      sorter: (a, b) => a.mobileNumber - b.mobileNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Driver",
      dataIndex: "totalDrivers",
      width: 120,
      sorter: (a, b) => a.totalDrivers - b.totalDrivers,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
      sorter: (a, b) => a.totalAmount - b.totalAmount,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            text="Edit"
            isDisabled={
              selectedRowKeysBlockedCompanies.length > 0 ? true : false
            }
          />
          <ActionButton
            onClick={() => {
              showBlockCompanyModal();
              setCompanyData(record);
            }}
            icon={
              record?.isBlocked === false ? <MdOutlineBlock size={15} /> : ""
            }
            text={record?.isBlocked === false ? "Block" : "Blocked"}
            // style={{
            //   width: "50px",
            //   backgroundColor: record?.isBlocked === false ? "" : "yellow",
            //   color: record?.isBlocked === false ? "" : "black",
            // }}
            isBlocked={record?.isBlocked}
            isDisabled={
              selectedRowKeysBlockedCompanies.length > 0 ? true : false
            }
          />
          <ActionButton
            onClick={() => {
              Navigate(`/companydrivers`);
              localStorage.setItem(
                "specificComDetails",
                JSON.stringify(record)
              );
            }}
            icon={<AiFillEye size={15} />}
            text="View"
            isDisabled={
              selectedRowKeysBlockedCompanies.length > 0 ? true : false
            }
          />
        </div>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: `All Companies (${
        allCompaniesList?.length ? allCompaniesList?.length : 0
      })`,
      children: (
        <div className={styles.tabContentWrapper}>
          {allCompaniesList?.length > 0 ? (
            <>
              <div className={styles.allCompaniesHeaderWrapper}>
                <CustomButton
                  style={{
                    width: "200px",
                    height: "33px",
                    borderRadius: "4px",
                    marginBottom: "1rem",
                    fontSize: "12px",
                  }}
                  text={"Download Companies"}
                  onClick={handleDownload}
                  disabled={isLoading}
                />
                <CSVLink
                  data={
                    selectedRowKeys?.length > 0
                      ? extractedData || ""
                      : processedData || ""
                  }
                  filename="CompaniesList.csv"
                  style={{ display: "none" }}
                  ref={csvLink}
                  target="_blank"
                />
                <Input.Search
                  placeholder="Search here..."
                  style={{ width: "40%" }}
                  onChange={(e) => setSearchAllText(e.target.value)}
                />
              </div>
              <Table
                columns={AllDriversColumns}
                rowSelection={rowSelection}
                dataSource={allCompaniesList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.mobileNumber,
                  email: item.email,
                  totalAmount: item.paidAmount,
                }))}
                scroll={{
                  x: 1800,
                }}
                className="custom-table"
              />
            </>
          ) : (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Active Companies (${
        allCompaniesList
          ? allCompaniesList.filter((company) => !company.isBlocked).length
          : 0
      })`,
      children: (
        <div className={styles.tabContentWrapper}>
          {allCompaniesList?.length > 0 ? (
            <>
              <div className={styles.allCompaniesHeaderWrapper}>
                <CustomButton
                  style={{
                    width: "200px",
                    height: "33px",
                    borderRadius: "4px",
                    marginBottom: "1rem",
                    fontSize: "12px",
                  }}
                  text={"Download Companies"}
                  onClick={handleDownloadActiveCompanies}
                  disabled={isLoading}
                />
                <CSVLink
                  data={
                    selectedRowKeysActiveCompanies?.length > 0
                      ? extractedDataActiveCompanies || ""
                      : processedDataActiveCompanies || ""
                  }
                  filename="CompaniesList.csv"
                  style={{ display: "none" }}
                  ref={csvLinkActiveCompanies}
                  target="_blank"
                />
                <Input.Search
                  placeholder="Search here..."
                  style={{ width: "40%" }}
                  onChange={(e) => setSearchActiveText(e.target.value)}
                />
              </div>
              <Table
                columns={ActiveCompanycolumns}
                rowSelection={rowSelectionActiveCompanies}
                dataSource={allCompaniesList
                  ?.filter((item) => item?.isBlocked === false)
                  ?.map((item) => ({
                    ...item,
                    key: item?.id,
                    name: item?.firstName,
                    phoneNumber: item?.phone,
                    email: item?.email,
                    totalAmount: item.paidAmount,
                  }))}
                scroll={{
                  x: 1800,
                }}
                className="custom-table"
              />
            </>
          ) : (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: `Blocked Companies (${
        allCompaniesList
          ? allCompaniesList.filter((company) => company.isBlocked).length
          : 0
      })`,
      children: (
        <div className={styles.tabContentWrapper}>
          {allCompaniesList?.length > 0 ? (
            <>
              <div className={styles.allCompaniesHeaderWrapper}>
                <CustomButton
                  style={{
                    width: "200px",
                    height: "33px",
                    borderRadius: "4px",
                    marginBottom: "1rem",
                    fontSize: "12px",
                  }}
                  text={"Download Companies"}
                  onClick={handleDownloadBlockedCompanies}
                  disabled={isLoading}
                />
                <CSVLink
                  data={
                    selectedRowKeysBlockedCompanies?.length > 0
                      ? extractedDataBlockedCompanies || ""
                      : processedDataBlockedCompanies || ""
                  }
                  filename="CompaniesList.csv"
                  style={{ display: "none" }}
                  ref={csvLinkBlockedCompanies}
                  target="_blank"
                />
                <Input.Search
                  placeholder="Search here..."
                  style={{ width: "40%" }}
                  onChange={(e) => setSearchInActiveText(e.target.value)}
                />
              </div>
              <Table
                columns={InActiveCompanycolumns}
                rowSelection={rowSelectionBlockedCompanies}
                dataSource={allCompaniesList
                  ?.filter((item) => item?.isBlocked === true)
                  ?.map((item) => ({
                    ...item,
                    key: item?.id,
                    name: item?.firstName,
                    phoneNumber: item?.phone,
                    email: item?.email,
                    totalAmount: item.paidAmount,
                  }))}
                scroll={{
                  x: 1800,
                }}
                className="custom-table-inactive-four"
              />
            </>
          ) : (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: "Edit Company",
    },
    {
      key: "5",
      label: "Block Company",
    },
  ];

  const onChange = (key) => {
    if (key === "4") {
      Navigate("/editcompany");
    } else if (key === "5") {
      Navigate("/blockcompany");
    }
  };

  return (
    <MainLayout>
      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
          onChange={onChange}
        />

        <EditCompanyModal
          handleEditCompanyCancel={handleEditCompanyCancel}
          handleEditCompanyOk={handleEditCompanyOk}
          isEditCompanyModalOpen={isEditCompanyModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCompaniesData={updateCompaniesData}
          isLoading={isLoading}
        />
        <BlockCompanyModal
          handleBlockCompanyCancel={handleBlockCompanyCancel}
          handleBlockCompanyOk={handleBlockCompanyOk}
          isBlockCompanyModalOpen={isBlockCompanyModalOpen}
          blockStatus={companyData?.isBlocked}
          isLoading={isLoading}
        />
      </div>
    </MainLayout>
  );
}

export default AllCompanies;
