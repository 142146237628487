import { client } from "APIs/axios"
import { backendEndpoints } from "constants/Backend";

export const loginService = ({ email, password }) => {
  return client({
    url: backendEndpoints.loginWithEmail,
    method: "POST",
    data: {
      email,
      password,
    },
  });
};

export default loginService;