import React from 'react';
import styles from "./styles.module.scss";

function TextArea({field, form, ...props} ) {
  return (
    <textarea {...field} {...props} className={styles.textArea}/>
  )
}

export default TextArea;
