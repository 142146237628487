import React from "react";
import styles from "./styles.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 25, color: "#000" }} spin />
);

const CancelButton = ({ onClick, text, style, type, disabled, isLoading }) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={styles.cancelbutton}
    >
      {isLoading ? <Spin indicator={antIcon} /> : text}
    </button>
  );
};

export default CancelButton;
