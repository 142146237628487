import React, { useCallback, useState, useEffect } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/Button";
import { Spin } from "antd";
import PaymentCancelModal from "components/Modals/PaymentCancellationModal";
import { useAuth } from "context";

function PaymentForm({ invoiceStatus }) {
  const elements = useElements();
  const stripe = useStripe();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(true);
  const [isPaymentCancelModalOpen, setIsPaymentCancelModalOpen] =
    useState(false);
  const navigate = useNavigate();

  const showHandlePaymentCancelModal = () => {
    setIsPaymentCancelModalOpen(true);
  };

  const handlePaymentCancelModalOk = () => {
    setIsPaymentCancelModalOpen(false);
    navigate("/alldrivers");
  };

  useEffect(() => {
    setTimeout(() => {
      setCheckoutLoading(false);
    }, 5000);
  });

  const doPayment = useCallback(async () => {
    if (elements != null && stripe != null) {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        return;
      }
      setIsLoading(true);
      const payloadPayment = await stripe.confirmPayment({
        elements,
        confirmParams: {
          receipt_email: user?.email,
          payment_method_data: {
            billing_details: {
              email: user?.email,
              name: user?.firstName,
              phone: user?.mobileNumber,
            },
          },
          // return_url: `http://localhost:3000/checkout?amount=${"20$"}&plan=${"Plan"}&invoiceStatus=${invoiceStatus}`,
          return_url: `${
            process.env.REACT_APP_FRONTEND_BASE_URL
          }/checkout?amount=${"20$"}&plan=${"Plan"}&invoiceStatus=${invoiceStatus}`,
        },
      });
      setIsLoading(false);
      console.log("SubscriptionOptions", payloadPayment);
      if (payloadPayment.error) {
        // alert(`${payloadPayment.error.message}`)
        console.log(
          payloadPayment?.error.message,
          "payloadPayment?.error.message"
        );
        showHandlePaymentCancelModal();

        return;
      }
      return;
    } else {
      console.log("No eleement");
    }
  }, [elements, stripe]);

  console.log("Text => ", !elements);
  return (
    <div style={{ position: "relative" }}>
      {checkoutLoading && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "-5px",
            width: "110%",
            height: "100%",
            background: "#F5F4F6",
            zIndex: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip="Loading" size="large" />
        </div>
      )}
      <PaymentElement
        options={{
          paymentMethodOrder: ["card"],
          business: {
            name: "cabit",
          },
          fields: {
            billingDetails: {
              address: {
                country: "auto",
              },
            },
          },
        }}
      />
      <CustomButton
        style={{
          marginTop: "1.5rem",
          // background: !stripe || !elements ? "black" : "inherit",
        }}
        text={"Done"}
        onClick={() => {
          doPayment();
        }}
        isLoading={isLoading}
        disabled={isLoading || !stripe || !elements}
      />

      <PaymentCancelModal
        handlePaymentCancelCancel={() => {}}
        handlePaymentCancelOk={handlePaymentCancelModalOk}
        isPaymentCancelModalOpen={isPaymentCancelModalOpen}
      />
    </div>
  );
}

export default PaymentForm;
