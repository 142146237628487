import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import CustomButton from "components/Button";
import CustomInput from "components/CustomInput";
import { validateDriverEditModal } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import PhoneInputField from "components/PhoneNumberInput";
import CancelButton from "components/CancelButton";


function EditModal({
  isEditModalOpen,
  handleEditOk,
  handleEditCancel,
  editAbleItem,
  editItemIndex,
  updateCsvFileData,
}) {
  const formikRef = useRef(null);

  const handleCancelClick = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    handleEditCancel();
  }

  const handleUpdateClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <Modal
      centered
      open={isEditModalOpen}
      onOk={handleEditOk}
      onCancel={handleCancelClick}
      maskClosable={false}
      width={"79%"}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "94%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleCancelClick}
          >
            Custom Button
          </CancelButton>
          <CustomButton
            text={"Update"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleUpdateClick}
          >
            Custom Button
          </CustomButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "94%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p style={{ alignSelf: "flex-start" }} className={styles.modalText}>
          Edit Driver <span>Details</span>
        </p>
        <Formik
          initialValues={{
            firstName: editAbleItem?.first_name,
            lastName: editAbleItem?.last_name,
            driverEmail: editAbleItem?.email,
            confirmDriverEmail: editAbleItem?.email,
            driverPhoneNumber: editAbleItem?.phone,
            confirmDriverPhoneNumber: editAbleItem?.phone,
          }}
          validationSchema={validateDriverEditModal}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            const updatedItem = {
              ...editAbleItem,
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.driverEmail,
              phone: values.driverPhoneNumber,
            };
          
            // Pass the editItemIndex to the updateCsvFileData function
            updateCsvFileData(updatedItem, editItemIndex);
          
            // Close the modal
            handleEditOk();
            console.log("Check");
          }}
          innerRef={formikRef}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="driverEmail"
                    name="driverEmail"
                    placeholder="Driver Email"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="driverEmail"
                    component="div"
                    className="error"
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="confirmDriverEmail"
                    name="confirmDriverEmail"
                    placeholder="Confirm Email"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="confirmDriverEmail"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={PhoneInputField}
                    type="text"
                    id="driverPhoneNumber"
                    name="driverPhoneNumber"
                    placeholder="Driver Phone Number"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="driverPhoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={PhoneInputField}
                    type="text"
                    id="confirmDriverPhoneNumber"
                    name="confirmDriverPhoneNumber"
                    placeholder="Confirm Phone Number"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="confirmDriverPhoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditModal;
