import React from "react";
import styles from "./styles.module.scss";

function ActionButton({ onClick, icon, text, style, isBlocked, isDisabled }) {
  return (
    <button
      onClick={onClick}
      style={style}
      disabled={isDisabled}
      className={isBlocked ? styles.blockActionButton : styles.actionButton}
    >
      {icon}
      {text}
    </button>
  );
}

export default ActionButton;
