import React, { useState, useRef } from "react";
import axios from "axios";
import styles from "./styles.module.scss";
import BlockModal from "components/Modals/BlockModal";
import { Tabs, Table, notification, Input } from "antd";
import { FiDownload } from "react-icons/fi";
import { BiSolidEditAlt } from "react-icons/bi";
import { TbFolderExclamation } from "react-icons/tb";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import ViewDetailsModal from "components/Modals/ViewDetailsModel";
import DeleteDriverModal from "components/Modals/DeleteDriverModal";
import { useAuth } from "context/AuthProvider";
import EditAddedDriverModal from "components/Modals/EditAddedDriverModal";
import { backendEndpoints } from "constants/Backend";
import { CSVLink } from "react-csv";
import ActionButton from "components/ActionButton";
import CustomButton from "components/Button";
import { useNavigate } from "react-router-dom";
import { deleteDriver } from "APIs/Services";
import { editDriver } from "APIs/Services";

const TabsSection = () => {
  const {
    allDriversList,
    setAllDriversList,
    getAllDrivers,
    idUser,
    tokenUser,
    user,
    handleUnautherized,
  } = useAuth();
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDeleteDriverModalOpen, setIsDeleteDriverModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [driverId, setDriverId] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [viewDriver, setViewDriver] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const csvLink = useRef();
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;

  const showEditModalWithData = (item) => {
    const itemIndex = allDriversList.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditModal();
  };

  const updateAllDriversData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...allDriversList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setAllDriversList(updatedCsvFileDataTemp);
  };

  // const editDriverDetails = async (item) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.editDriver}`,
  //       {
  //         user_id: id,
  //         driver_id: item.id,
  //         email: item.email,
  //         first_name: item.firstName,
  //         last_name: item.lastName,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     notification.success({
  //       message: "Success",
  //       description: "Driver Details Updated Successfully",
  //     });
  //     setIsLoading(false);
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };

  // const deleteDriver = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.deleteDriver}`,
  //       {
  //         user_id: id,
  //         driver:
  //           driverId.length > 0
  //             ? driverId
  //             : selectedRowKeys.map((key) => ({ id: key })),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return response;
  //   } catch (error) {
  //     notification.error({
  //       message: "Request Failed",
  //       description:
  //         error?.response?.status === 401
  //           ? "Session Expired"
  //           : "Something Went Wrong",
  //     });
  //     setIsLoading(false);
  //     handleUnautherized(error?.response?.status);
  //   }
  // };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setDriverId([]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteDriverModal = () => {
    setIsDeleteDriverModalOpen(true);
  };

  const handleDeleteDriverOk = async () => {
    setIsLoading(true);
    const response = await deleteDriver(token, {
      user_id: id,
      driver:
        driverId.length > 0
          ? driverId
          : selectedRowKeys.map((key) => ({ id: key })),
    });

    if (response.status === 200) {
      // Block was successful, update allDriversList state.
      await getAllDrivers();
      notification.success({
        message: "Success",
        description: "Driver Deleted Successfully",
      });
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
    setIsLoading(false);
    setSelectedRowKeys([]);
    setIsBlockModalOpen(false);
    setIsDeleteDriverModalOpen(false);
  };

  const handleDeleteDriverCancel = () => {
    setIsDeleteDriverModalOpen(false);
  };

  const showViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const handleViewDetailsOk = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleBlockOk = () => {
    setIsBlockModalOpen(false);
  };

  const handleBlockCancel = () => {
    setIsBlockModalOpen(false);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = async (updatedData) => {
    // await editDriverDetails(updatedData);
    setIsLoading(true);
    const response = await editDriver(token, {
      user_id: id,
      driver_id: updatedData.id,
      email: updatedData.email,
      first_name: updatedData.firstName,
      last_name: updatedData.lastName,
    });
    await getAllDrivers();
    setIsEditModalOpen(false);
    if (response?.status === 200) {
      notification.success({
        message: "Success",
        description: "Driver Details Updated Successfully",
      });
      setIsLoading(false);
    } else {
      notification.error({
        message: "Request Failed",
        description:
          response?.status === 401 ? "Session Expired" : "Something Went Wrong",
      });
      setIsLoading(false);
      handleUnautherized(response?.status);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(record?.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.phoneNumber)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      sorter: (a, b) => a.phoneNumber - b.phoneNumber,
      render: (text, record) => (
        <a className="table-href-tags" href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text, record) => (
        <a className="table-href-tags" href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <ActionButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={15} />}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
            text="Edit"
          />
          <ActionButton
            onClick={() => {
              showDeleteDriverModal();
              setDriverId([{ id: record?.id }]);
            }}
            icon={<MdOutlineBlock size={15} />}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
            text={"Delete"}
          />
          <ActionButton
            onClick={() => {
              showViewDetailsModal();
              setViewDriver(record);
            }}
            icon={<AiFillEye size={15} />}
            isDisabled={selectedRowKeys.length > 0 ? true : false}
            text="View"
          />
        </div>
      ),
    },
  ];
  const handleDownload = () => {
    csvLink.current.link.click();
  };

  const preprocessData = (originalData) => {
    // custom logic to select specific columns
    return originalData?.map((item) => ({
      "First Name": item?.firstName,
      "Last Name": item?.lastName,
      "Phone Number": `'${item?.phone}'`,
      Email: item?.email,
      Active: item?.status === true ? "YES" : "NO",
      "Created Date": item?.created_date,
      // You can add more columns if needed
    }));
  };

  // Use the processed data for CSV export
  const processedData = preprocessData(allDriversList);

  const extractDataByIds = (originalData, ids) => {
    return originalData
      ?.filter((item) => ids.includes(item.id))
      ?.map((item) => ({
        "First Name": item?.firstName,
        "Last Name": item?.lastName,
        "Phone Number": `'${item?.phone}'`,
        Email: item?.email,
        Active: item?.status === true ? "YES" : "NO",
        "Created Date": item?.created_date,
      }));
  };

  // Use the extracted data for CSV export
  const extractedData = extractDataByIds(allDriversList, selectedRowKeys);

  const items = [
    {
      key: "1",
      label: `All Drivers`,
      children: (
        <div className={styles.tabContentWrapper}>
          {allDriversList?.length === 0 ? (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          ) : (
            <>
              <div className={styles.allDriversHeaderWrapper}>
                <div className={styles.allDriversHeaderWrapperLeft}>
                  <p>
                    <span>No. of Drivers:</span> {allDriversList?.length || 0}
                  </p>
                  {selectedRowKeys?.length > 0 && (
                    <CustomButton
                      style={{
                        width: "160px",
                        height: "33px",
                        borderRadius: "4px",
                        marginBottom: "1rem",
                        fontSize: "12px",
                      }}
                      text={"Delete Selected Drivers"}
                      onClick={showDeleteDriverModal}
                      disabled={isLoading}
                    />
                  )}
                </div>
                <div className={styles.allDriversHeaderWrapperRight}>
                  <button
                    className={styles.downloadListBtn}
                    onClick={handleDownload}
                  >
                    {<FiDownload size={15} />} {"Download Drivers"}
                  </button>
                  <CSVLink
                    data={
                      selectedRowKeys?.length > 0
                        ? extractedData
                        : processedData
                    }
                    filename="DriversList.csv"
                    style={{ display: "none" }}
                    ref={csvLink}
                    target="_blank"
                  />
                  <Input.Search
                    placeholder="Search here..."
                    style={{
                      marginBottom: "1rem",
                      marginLeft: "20%",
                      width: "80%",
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>

              <Table
                columns={ActiveDriversColumns}
                rowSelection={rowSelection}
                dataSource={allDriversList?.map((item) => ({
                  ...item,
                  key: item.id,
                  name: item.firstName,
                  phoneNumber: item.phone,
                  email: item.email,
                }))}
                scroll={{
                  x: 1000,
                }}
                className="custom-table"
              />
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Edit Drivers",
    },
    {
      key: "3",
      label: "Delete Drivers",
    },
  ];
  const onChange = (key) => {
    if (key === "2") {
      console.log(key);
      navigate("/editdriver");
    } else if (key === "3") {
      console.log(key);
      navigate("/blockdriver");
    }
  };
  return (
    <div className="custom-tab-wrapper">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={"small"}
        items={items}
        tabBarGutter={12}
        onChange={onChange}
      />

      <EditAddedDriverModal
        handleEditAddedDriverCancel={handleEditCancel}
        handleEditAddedDriverOk={handleEditOk}
        isEditAddedDriverModalOpen={isEditModalOpen}
        editAbleItem={editItem}
        editItemIndex={editItemIndex}
        updateCsvFileData={updateAllDriversData}
        isLoading={isLoading}
      />
      <BlockModal
        handleBlockCancel={handleBlockCancel}
        handleBlockOk={handleBlockOk}
        isBlockModalOpen={isBlockModalOpen}
      />
      <ViewDetailsModal
        handleViewDetailsCancel={handleViewDetailsCancel}
        handleViewDetailsOk={handleViewDetailsOk}
        isViewDetailsModalOpen={isViewDetailsModalOpen}
        viewDriverData={viewDriver}
        companyName={user?.firstName}
        companyAddress={user?.address}
        companyNumber={user?.mobileNumber}
      />
      <DeleteDriverModal
        handleDeleteDriverCancel={handleDeleteDriverCancel}
        handleDeleteDriverOk={handleDeleteDriverOk}
        isDeleteDriverModalOpen={isDeleteDriverModalOpen}
        isLoading={isLoading}
      />
    </div>
  );
};
export default TabsSection;
