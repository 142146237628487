import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import { Modal, notification } from "antd";
import CustomButton from "components/Button";
import CustomInput from "components/CustomInput";
import TextArea from "components/TextArea";
import { validateContactUs } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { contactUs } from "APIs/Services";

function ContactAdminModal({
  isContactAdminModalOpen,
  handleContactAdminOk,
  handleContactAdminCancel,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const formikRef = useRef(null);
  const initialValues = {
    name: "",
    email: "",
    message: "",
  };

  const handleUpdateClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const handleCancelClick = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    handleContactAdminCancel();
  };

  const handleCallButtonClick = () => {
    const phoneNumber = '1234567890'; // Replace with the actual phone number

    // Open the phone call modal
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Modal
      centered
      open={isContactAdminModalOpen}
      onOk={handleContactAdminOk}
      onCancel={handleCancelClick}
      width={"85%"}
      maskClosable={false}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "88%",
          }}
        >
          <>
            <CustomButton
              text={"Send Email"}
              key="customButton"
              style={{
                width: "156px",
                fontSize: "15px",
                height: "38px",
              }}
              onClick={handleUpdateClick}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Custom Button
            </CustomButton>
            <CustomButton
              text={"Contact Via Call"}
              key="customButton"
              style={{
                width: "156px",
                fontSize: "15px",
                height: "38px",
              }}
              onClick={handleCallButtonClick}
            >
              Custom Button
            </CustomButton>
          </>
        </div>,
      ]}
    >
      {}
      <div
        style={{
          width: "88%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p className={styles.heading}>Contact Admin</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validateContactUs}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            const response = await contactUs({
              name: values?.name,
              email: values?.email,
              subject: "Subject loream",
              body: values?.message,
            });
            console.log("Response.status", response.status);
            if (response.status === 200) {
              actions.setSubmitting(false);
              setIsLoading(false);
              notification.success({
                message: "Form Submitted Successfully",
              });
              actions.resetForm();
              handleContactAdminOk();
            } else {
              notification.error({
                message: "Request Failed",
                description: "Something Went Wrong",
              });
              setIsLoading(false);
            }
          }}
          innerRef={formikRef}
        >
          {({ isSubmitting }) => (
            <Form>
              <div>
                <Field
                  component={CustomInput}
                  type="text"
                  id="name"
                  name="name"
                  placeholder={"Name"}
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#F2F3FF",
                    height: "56px",
                  }}
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>

              <div>
                <Field
                  component={CustomInput}
                  type="email"
                  id="email"
                  name="email"
                  placeholder={"Enter Email"}
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#F2F3FF",
                    height: "56px",
                  }}
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>

              <div>
                <Field
                  component={TextArea}
                  type="text"
                  id="message"
                  name="message"
                  style={{ height: "87px" }}
                  placeholder={"Your Message"}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default ContactAdminModal;
