export function calculateDriverCost(monthlyCost, remDays, planDays) {
    const today = new Date();
    const currentMonth = today.getMonth(); // Get the current month (0-based index)
    const currentYear = today.getFullYear(); // Get the current year
  
    // Calculate the last day of the current month
    const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
  
    // Extract the day component from the last day of the month
    const totalDays = lastDayOfMonth.getDate();
  
    // Calculate remaining days, including the current day
    // const remainingDays = totalDays - today.getDate() + 1;
        const remainingDays = remDays;
  
    // Calculate per day cost
    const perDayCost = (monthlyCost / planDays); // Round to two decimal places
  
    // Calculate total cost for the remaining days
    const totalCost = (perDayCost * remainingDays); // Round to two decimal places
  
    // Format the current date in 'YYYY-MM-DD' format
    const currentDate = today.toISOString().slice(0, 10);
    function getLastDayOfCurrentMonth() {
        const today = new Date();
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const year = lastDay.getFullYear();
        const month = String(lastDay.getMonth() + 1).padStart(2, '0');
        const day = String(lastDay.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
    // Format the last date of the current month in 'YYYY-MM-DD' format
    const lastDateOfMonth = getLastDayOfCurrentMonth();
  
    return {
      monthlyCost,
      totalDays,
      remainingDays,
      perDayCost,
      totalCost,
      currentDate,
      lastDateOfMonth,
    };
  }
  

  