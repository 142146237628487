import React from 'react'
import styles from "./styles.module.scss"
import { Modal } from 'antd'
import CustomButton from 'components/Button'
import CancelButton from 'components/CancelButton'

function BlockCompanyModal({isBlockCompanyModalOpen, handleBlockCompanyOk, handleBlockCompanyCancel, blockStatus, isLoading }) {
  return (
    <Modal
    centered
    open={isBlockCompanyModalOpen}
    onOk={handleBlockCompanyOk}
    onCancel={handleBlockCompanyCancel}
    width={"56%"}
    maskClosable={false}
    footer={[
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "4.75rem",
          gap: "14px",
          width: "100%",
        }}
      >
        <CancelButton
          text={"Cancel"}
          key="customButton"
          style={{
            width: "156px",
            fontSize: "15px",
            height: "38px",
          }}
          disabled={isLoading}
          onClick={handleBlockCompanyCancel}
        >
          Custom Button
        </CancelButton>
        <CustomButton
          text={blockStatus ? "Unblock" :"Block" }
          key="customButton"
          style={{
            width: "156px",
            fontSize: "15px",
            height: "38px",
          }}
          onClick={handleBlockCompanyOk}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Custom Button
        </CustomButton>
      </div>,
    ]}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "3rem",
        marginTop: "5.8rem",
        marginBottom: "2rem",
        boxSizing: "border-box",
      }}
    >
      <p className={styles.modalText}>
        Are you sure you want to {blockStatus ? "unblock" :"block"} <span>“Company”?</span>
      </p>
    </div>
  </Modal>
  )
}

export default BlockCompanyModal