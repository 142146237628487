import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useAuth } from "context";
import { backendEndpoints } from "constants/Backend";
import PaymentSuccessModal from "components/Modals/PaymentSuccessModal";

function Checkout() {
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] =
    useState(false);
  const [loading, setLoading] = useState();
  const Navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { idUser, tokenUser, getAllDrivers, user, getBasicInfo } = useAuth();
  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;
  const drivers = localStorage.getItem("drivers");
  const invoiceId = localStorage.getItem("invoiceId");
  const totalAmount = localStorage.getItem("totalAmount");
  const chargesObject = JSON.parse(localStorage.getItem("chargesObj"));

  const stripeChargesPercentage =
  (2.9 / 100) * Number(user?.unPaidInvoices[0]?.amount);
const pfAndStripe = Number(stripeChargesPercentage + 0.3).toFixed(2);
const totalCharges = (
  Number(user?.unPaidInvoices[0]?.amount) + Number(pfAndStripe)
).toFixed(2);

  // console.log("invoiceStatus", params?.invoiceStatus);
  console.log("chargesObj", chargesObject);


  const registerDriver = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.AddDrivers}`,
        {
          data: JSON.parse(drivers),
          user_id: parseInt(id),
          invoice_id: parseInt(invoiceId),
          amount: chargesObject?.amount,
          subAmount: chargesObject?.subAmount,
          subTaxes: chargesObject?.subTaxes,
          subCharges: chargesObject?.subCharges,
          previousAmount: user?.unPaidInvoices[0]?.subAmount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showPaymentSuccessModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };
  const payTheInvoice = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.payTheInvoice}`,
        {
          user_id: user?.id,
          invoice_id: user?.unPaidInvoices[0]?.id,
          amount: totalCharges,
          subAmount: user?.unPaidInvoices[0]?.subAmount,
          subTaxes: user?.unPaidInvoices[0]?.subTaxes,
          subCharges: pfAndStripe,
          noOfDrivers: user?.unPaidInvoices[0]?.totalDrivers
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      alert("error", error);
    }
  };

  const addDriversAndGetAllUsers = async () => {
    await registerDriver();
    await getBasicInfo();
    await getAllDrivers();
  };

  const payInvoiceAndGetUserBasicInfo = async () => {
    setLoading(true);
    const res = await payTheInvoice();

    if (res.status === 200) {
      await getBasicInfo();
      showPaymentSuccessModal();
      setLoading(false);
      // Navigate("/login");
    } else {
      alert("Internal Server Error");
    }
  };
  useEffect(() => {
    if (params?.invoiceStatus === "true") {
      payInvoiceAndGetUserBasicInfo();
    } else {
      addDriversAndGetAllUsers();
    }
    return () => {
      localStorage.removeItem("drivers");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("invoiceId");
    };
  }, []);

  const showPaymentSuccessModal = () => {
    setIsPaymentSuccessModalOpen(true);
  };

  const handlePaymentSuccessOk = () => {
    setIsPaymentSuccessModalOpen(false);
    Navigate("/alldrivers");
  };

  const handlePaymentSuccessCancel = () => {
    setIsPaymentSuccessModalOpen(false);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
            margin: "40vh auto",
          }}
        >
          <Spin tip="Loading" size="large" />
          <p>Do not close this tab. </p>
        </div>
      ) : (
        <PaymentSuccessModal
          handlePaymentSuccessCancel={handlePaymentSuccessCancel}
          handlePaymentSuccessOk={handlePaymentSuccessOk}
          isPaymentSuccessModalOpen={isPaymentSuccessModalOpen}
        />
      )}
    </>
  );
}

export default Checkout;
