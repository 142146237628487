import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CustomButton from "components/Button";
import { ReactComponent as SmallCabitIcon } from "assets/icons/SmallCabitIcon.svg";
import { ReactComponent as CabitIcon } from "assets/icons/CabitLandingPageIcon.svg";
import { LuMenu } from "react-icons/lu";
import { useAuth } from "context/AuthProvider";
import ConfirmLogoutModal from "components/Modals/ConfirmLogoutModal";

function LandingPageHeader({ isBoxShadow }) {
  const { logout, user, imageSrc } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const windowWidth = useRef(window.innerWidth);
  const [isConfirmLogoutModalOpen, setIsConfirmLogoutModalOpen] =
    useState(false);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 40;
      setScrolled(isScrolled);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const location = useLocation();
  const URL = location.pathname;

  const handleShowConfirmLogout = () => {
    setIsConfirmLogoutModalOpen(true);
  };
  const handleConfirmLogoutOk = async () => {
    await logout();
    setIsConfirmLogoutModalOpen(false);
  };

  const handleConfirmLogoutCancel = () => {
    setIsConfirmLogoutModalOpen(false);
  };
  return (
    <div
      style={{
        boxShadow: isBoxShadow
          ? "0px 4px 13px 0px rgba(0, 0, 0, 0.15)"
          : "none",
        background: scrolled
          ? "linear-gradient(90deg, #FF0200 0%, #FFB715 100%)"
          : "transparent", // Apply linear gradient when scrolled
      }}
      className={styles.header}
    >
      <div className={styles.headerLeftWrapper}>
        <Link style={{ textDecoration: "none", color: "inherit" }} to={"/"}>
          {URL === "/" || URL === "/taxicompany" || scrolled ? (
            <SmallCabitIcon />
          ) : (
            <CabitIcon />
          )}
        </Link>

        <div className={styles.headerNavWrapper}>
          {URL === "/termsandconditions" || URL === "/privacypolicy" ? null : (
            <>
              <p
                style={{ textDecoration: URL === "/" ? "underline" : "none" }}
                onClick={() => navigate("/")}
              >
                Home
              </p>

              <p
                style={{
                  textDecoration: URL === "/taxicompany" ? "underline" : "none",
                }}
                onClick={() => navigate("/taxicompany")}
              >
                Taxi Company
              </p>
              <p
                onClick={() =>
                  URL === "/taxicompany"
                    ? navigate("/", { state: { targetId: "aboutUs" } })
                    : scrollToSection("aboutUs")
                }
              >
                About Us
              </p>
              <p onClick={() => scrollToSection("contactUsSection")}>
                Contact Us
              </p>
            </>
          )}
        </div>
      </div>

      {URL === "/" || URL === "/taxicompany" ? (
        <div className={styles.navMenuWapper}>
          <LuMenu
            color="#fff"
            size={"20"}
            onClick={() => setShowMenu(!showMenu)}
          />

          {showMenu && (
            <div className={styles.menu}>
              <div className={styles.menuOptions} onClick={() => navigate("/")}>
                Home
              </div>
              <div
                className={styles.menuOptions}
                onClick={() => navigate("/taxicompany")}
              >
                Taxi Company
              </div>
              <div
                className={styles.menuOptions}
                onClick={() =>
                  URL === "/taxicompany"
                    ? navigate("/", { state: { targetId: "aboutUs" } })
                    : scrollToSection("aboutUs")
                }
              >
                About Us
              </div>
              <div
                className={styles.menuOptions}
                onClick={() => scrollToSection("contactUsSection")}
              >
                Contact Us
              </div>
              {URL === "/" ? (
                <></>
              ) : user ? (
                <>
                  {" "}
                  <div
                    className={styles.menuOptions}
                    onClick={() => navigate("/profile")}
                  >
                    Profile
                  </div>
                  <div
                    className={styles.menuOptions}
                    onClick={handleShowConfirmLogout}
                  >
                    Logout
                  </div>
                </>
              ) : (
                <div className={styles.menuLoginBtn}>
                  <CustomButton
                    style={{
                      width: "108px",
                      height: "36px",
                      fontSize: "12px",
                      borderRadius: "4px",
                      margin: "2rem 0rem 1rem 0rem",
                    }}
                    text={"Login"}
                    onClick={() => navigate("/login")}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {URL === "/" ? (
        <></>
      ) : user ? (
        <div
          style={{
            display:
              URL === "/taxicompany" && windowWidth?.current <= 425
                ? "none"
                : "flex",
          }}
          className={styles.profileSectionWrapper}
        >
          <p
            style={{
              color: URL === "/" || URL === "/taxicompany" ? "#fff" : "#000",
            }}
          >
            {user?.firstName}
          </p>
          <div className={styles.profileImgWrapper}>
            <img src={imageSrc ? imageSrc : user?.image} alt="" />
          </div>
          <div className={styles.menuDropdown}>
            <Link to={"/profile"}>
              <div className={styles.dropdownMenuOptions}>Profile</div>
            </Link>
            <div
              onClick={handleShowConfirmLogout}
              className={styles.dropdownMenuOptions}
            >
              Logout
            </div>
          </div>
        </div>
      ) : (
        <CustomButton
          style={{
            width: "108px",
            height: "36px",
            fontSize: "12px",
            borderRadius: "4px",
            display:
              URL === "/taxicompany" && windowWidth?.current <= 425
                ? "none"
                : "block",
          }}
          text={"Login"}
          onClick={() => navigate("/login")}
        />
      )}

      <ConfirmLogoutModal
        isConfirmLogoutModalOpen={isConfirmLogoutModalOpen}
        handleConfirmLogoutOk={handleConfirmLogoutOk}
        handleConfirmLogoutCancel={handleConfirmLogoutCancel}
      />
    </div>
  );
}

export default LandingPageHeader;
