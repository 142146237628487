import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { validateLogin } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import CustomInput from "components/CustomInput";
import { ReactComponent as CabitIcon } from "assets/icons/CabitIcon.svg";
import CustomButton from "components/Button";
import CustomPasswordInput from "components/CustomPassword";
import { useAuth } from "context";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  const initialValues = {
    email: "",
    password: "",
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.innerLeft}>
          <div className={styles.iconTextWrapper}>
            <Link to={"/"}><CabitIcon /></Link>
            <p>
              Ride without Limits. No Logins, No Frills. Just Hop In and Go!
            </p>
          </div>
        </div>
        <div className={styles.innerRight}>
          <div className={styles.welcomeTextWrapper}>
            <p>Hello Again!</p>
            <p>Welcome Back</p>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validateLogin}
            onSubmit={async (values, actions) => {
              setIsLoading(true);
              await login({
                email: values?.email,
                password: values?.password,
              });
              actions.setSubmitting(false);
              setIsLoading(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div>
                  <Field
                    component={CustomInput}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <Field
                    component={CustomPasswordInput}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                    text="Login"
                    isLoading={isLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>

          <Link to={"/forgetpassword"}>Forgot Password</Link>
        </div>
      </div>
      <div className={styles.circleShade}></div>
    </div>
  );
};

export default Login;
