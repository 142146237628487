import * as Yup from "yup";

export const validateChangePassword = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?=.{8,})/,
      "New Password must contain at least one uppercase letter, one lowercase letter, one special character, one digit, and be at least 8 characters long"
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
