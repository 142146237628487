import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { validateDriverEditModal } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import InActiveInput from "components/InActiveInput";
import CancelButton from "components/CancelButton";
import PhoneInputField from "components/PhoneNumberInput";

function ViewDetailsModal({
  isViewDetailsModalOpen,
  handleViewDetailsOk,
  handleViewDetailsCancel,
  viewDriverData,
  companyName,
  companyAddress,
  companyNumber,
}) {
  // const initialValues = {
  // name: "",
  // email: "",
  // };

  // const formikRef = useRef(null);

  // const handleUpdateClick = () => {
  //   if (formikRef.current) {
  //     formikRef.current.submitForm();
  //   }
  // };

  return (
    <Modal
      centered
      open={isViewDetailsModalOpen}
      onOk={handleViewDetailsOk}
      onCancel={handleViewDetailsCancel}
      maskClosable={false}
      width={"79%"}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "94%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            onClick={handleViewDetailsCancel}
          >
            Custom Button
          </CancelButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "94%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p style={{ alignSelf: "flex-start" }} className={styles.modalText}>
          Driver <span>Detail</span>
        </p>
        <div className={styles.companyInfoWrapper}>
          <p>
            Company Name: <span>{companyName}</span>
          </p>
          <p>
            Company Phone: <span>{companyNumber}</span>
          </p>
          <p>
            Company Address: <span>{companyAddress}</span>
          </p>
          <p>
            Created At: <span>{viewDriverData?.created_date}</span>
          </p>
        </div>
        {/* <Formik
          initialValues={initialValues}
          validationSchema={validateDriverEditModal}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleViewDetailsOk();
          }}
          enableReinitialize={true}
          innerRef={formikRef}
        >
          {({ isSubmitting }) => ( */}
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <div className={styles.inputFieldsWrapper}>
            <div className={styles.inputAndErrorWrapper}>
              <InActiveInput
                type="text"
                placeholder="First Name"
                style={{ height: "42px", color: "black" }}
                value={viewDriverData?.firstName || ""}
              />
            </div>
            <div className={styles.inputAndErrorWrapper}>
              <InActiveInput
                type="text"
                placeholder="Last Name"
                style={{ height: "42px", color: "black" }}
                value={viewDriverData?.lastName || ""}
              />
            </div>
          </div>
          <div className={styles.inputFieldsWrapper}>
            <div className={styles.inputAndErrorWrapper}>
              <InActiveInput
                type="text"
                placeholder="Driver Email"
                style={{ height: "42px", color: "black" }}
                value={viewDriverData?.email || ""}
              />
            </div>
            <div className={styles.inputAndErrorWrapper}>
              {/* <PhoneInputField
                type="text"
                name="driverPhone"
                placeholder="Driver Phone Number"
                style={{ height: "42px", color: "black" }}
                value={viewDriverData?.phone || ""}
              /> */}

              <PhoneInput
                placeholder={"Driver Phone Number"}
                name={"driverPhone"}
                value={viewDriverData?.phone || ""}
                limitMaxLength
                international={true}
                disabled={true}
              />
            </div>
          </div>
        </form>
        {/* )}
        </Formik> */}
      </div>
    </Modal>
  );
}

export default ViewDetailsModal;
