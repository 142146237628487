import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./styles.module.scss";
import IconButton from "components/IconButton";
import EditModal from "components/Modals/EditModal";
import BlockModal from "components/Modals/BlockModal";
import { Tabs, Table } from "antd";
import { FiDownload } from "react-icons/fi";
import { BiSolidEditAlt } from "react-icons/bi";
import { TbFolderExclamation } from "react-icons/tb";
import { AiFillEye } from "react-icons/ai";
import { MdOutlineBlock } from "react-icons/md";
import ViewDetailsModal from "components/Modals/ViewDetailsModel";
import DeleteCompanyModal from "components/Modals/DeleteCompanyModal";
import { ReactComponent as DeleteIcon } from "assets/icons/DeleteIcon.svg";
import DeleteDriverModal from "components/Modals/DeleteDriverModal";
import { useAuth } from "context/AuthProvider";
import EditAddedDriverModal from "components/Modals/EditAddedDriverModal";
import { backendEndpoints } from "constants/Backend";
import { useNavigate, Link } from "react-router-dom";
import EditCompanyModal from "components/Modals/EditCompanyModal";
import BlockCompanyModal from "components/Modals/BlockCompanyModal";
import ViewCompanyDetailsModal from "components/Modals/ViewCompanyDetails";

function SearchBarContainer() {
  const {
    allDriversList,
    setAllDriversList,
    getAllDrivers,
    idUser,
    tokenUser,
    user,
    searchResult,
    setSearchResult,
    getAllCompaniesBySearch,
    getAllDriversBySearch,
    searchInputText,
    setSearchInputText,
  } = useAuth();
  const [editItem, setEditItem] = useState();
  const [editItemIndex, setEditItemIndex] = useState();
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDeleteDriverModalOpen, setIsDeleteDriverModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [driverId, setDriverId] = useState();
  const [viewDriver, setViewDriver] = useState();
  const Navigate = useNavigate();

  const token = localStorage.getItem("userToken")
    ? localStorage.getItem("userToken")
    : tokenUser;
  const id = localStorage.getItem("userId")
    ? localStorage.getItem("userId")
    : idUser;

  useEffect(() => {
    return () => {
      setSearchResult([]);
      setSearchInputText("");
      // Set the state to an empty value when the component unmounts
    };
  }, []);

  //Company Section
  const [companyData, setCompanyData] = useState();
  const [isEditCompanyModalOpen, setIsEditCompanyModalOpen] = useState(false);
  const [isViewCompanyDetailsModalOpen, setIsViewCompanyDetailsModalOpen] =
    useState(false);
  const [isBlockCompanyModalOpen, setIsBlockCompanyModalOpen] = useState(false);

  const showEditCompanyModalWithData = (item) => {
    const itemIndex = searchResult.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditCompanyModal();
  };

  const updateCompaniesData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...searchResult];
    updatedCsvFileDataTemp[index] = updatedItem;
    setSearchResult(updatedCsvFileDataTemp);
  };

  const editCompanyDetails = async (item) => {
    console.log("Item", item);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.updateUserProfile}`,
        {
          user_id: item?.id,
          first_name: item?.firstName,
          last_name: item?.lastName,
          phone: item?.mobileNumber,
          address: item?.address,
          user_name: item?.preferredName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {}
  };

  const blockCompany = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.blockCompany}`,
        {
          user_id: companyData.id,
          is_blocked: !companyData.isBlocked,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {}
  };

  const showViewCompanyDetailsModal = () => {
    setIsViewCompanyDetailsModalOpen(true);
  };

  const handleViewCompanyDetailsOk = () => {
    setIsViewCompanyDetailsModalOpen(false);
  };

  const handleViewCompanyDetailsCancel = () => {
    setIsViewCompanyDetailsModalOpen(false);
  };

  const showEditCompanyModal = () => {
    setIsEditCompanyModalOpen(true);
  };

  const handleEditCompanyOk = async (updatedData) => {
    await editCompanyDetails(updatedData);
    await getAllCompaniesBySearch();
    setIsEditCompanyModalOpen(false);
  };

  const handleEditCompanyCancel = () => {
    setIsEditCompanyModalOpen(false);
  };

  const showBlockCompanyModal = () => {
    setIsBlockCompanyModalOpen(true);
  };

  const handleBlockCompanyOk = async () => {
    await blockCompany();
    await getAllCompaniesBySearch();
    setIsBlockCompanyModalOpen(false);
  };

  const handleBlockCompanyCancel = () => {
    setIsBlockCompanyModalOpen(false);
  };

  const showEditModalWithData = (item) => {
    const itemIndex = allDriversList.findIndex(
      (dataItem) => dataItem.id === item.id
    );
    setEditItem(item);
    setEditItemIndex(itemIndex);
    showEditModal();
  };

  const updateAllDriversData = (updatedItem, index) => {
    const updatedCsvFileDataTemp = [...allDriversList];
    updatedCsvFileDataTemp[index] = updatedItem;
    setAllDriversList(updatedCsvFileDataTemp);
  };

  const editDriverDetails = async (item) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.editDriver}`,
        {
          user_id: id,
          driver_id: item.id,
          email: item.email,
          first_name: item.firstName,
          last_name: item.lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {}
  };

  const deleteDriver = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${backendEndpoints.deleteDriver}`,
        {
          user_id: id,
          driver_id: driverId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {}
  };

  const showDeleteDriverModal = () => {
    setIsDeleteDriverModalOpen(true);
  };

  const handleDeleteDriverOk = async () => {
    const response = await deleteDriver();
    if (response.status === 200) {
      // Block was successful, update allDriversList state.
      await getAllDriversBySearch();
    }
    setIsBlockModalOpen(false);
    setIsDeleteDriverModalOpen(false);
  };

  const handleDeleteDriverCancel = () => {
    setIsDeleteDriverModalOpen(false);
  };

  const showViewDetailsModal = () => {
    setIsViewDetailsModalOpen(true);
  };

  const handleViewDetailsOk = () => {
    setIsViewDetailsModalOpen(false);
  };

  const handleViewDetailsCancel = () => {
    setIsViewDetailsModalOpen(false);
  };

  const showBlockModal = () => {
    setIsBlockModalOpen(true);
  };

  const handleBlockOk = () => {
    setIsBlockModalOpen(false);
  };

  const handleBlockCancel = () => {
    setIsBlockModalOpen(false);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleEditOk = async (updatedData) => {
    await editDriverDetails(updatedData);
    await getAllDriversBySearch();
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };
  const ActiveDriversColumns = [
    {
      title: "Driver Name",
      dataIndex: "name",
      width: 250,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Actions",
      width: 230,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <IconButton
            onClick={() => {
              showEditModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={10} />}
            text="Edit"
          />
          <IconButton
            onClick={() => {
              showDeleteDriverModal();
              setDriverId(record?.id);
            }}
            icon={<MdOutlineBlock size={10} />}
            text={"Delete"}
            style={{
              width: "50px",
            }}
          />
          <IconButton
            onClick={() => {
              showViewDetailsModal();
              setViewDriver(record);
            }}
            icon={<AiFillEye size={10} />}
            text="View"
          />
        </div>
      ),
    },
  ];
  const ActiveCompaniesColumns = [
    {
      title: "Company Names",
      dataIndex: "name",
      width: 250,
      render: (text, record) => (
        <Link
          style={{ color: "inherit" }}
          to={`/companydrivers/${record?.firstName}/${record?.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Total Drivers",
      dataIndex: "totalDrivers",
      width: 200,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 200,
    },
    {
      title: "Actions",
      width: 200,
      render: (text, record) => (
        <div className={styles.tableEditButtonWrapper}>
          <IconButton
            onClick={() => {
              showEditCompanyModalWithData(record);
            }}
            icon={<BiSolidEditAlt size={10} />}
            text="Edit"
          />
          <IconButton
            onClick={() => {
              showBlockCompanyModal();
              setCompanyData(record);
            }}
            icon={
              record?.isBlocked === false ? <MdOutlineBlock size={10} /> : ""
            }
            text={record?.isBlocked === false ? "Block" : "Blocked"}
            style={{
              width: "50px",
              backgroundColor: record?.isBlocked === false ? "" : "yellow",
              color: record?.isBlocked === false ? "" : "black",
            }}
          />
          <IconButton
            onClick={() => {
              Navigate(`/companydrivers/${record?.firstName}/${record?.id}`);
              setSearchInputText("");
            }}
            icon={<AiFillEye size={10} />}
            text="View"
          />
        </div>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label:
        (user?.roleName).toLowerCase() === "superadmin"
          ? "All Companies"
          : "All Drivers",
      children: (
        <div className={styles.tabContentWrapper}>
          {/* {searchResult?.length === 0 ? (
            <div className={styles.noDataWrapper}>
              <TbFolderExclamation size={100} />
              <p>No Data</p>
            </div>
          ) : (
            <> */}
          {(user?.roleName).toLowerCase() === "superadmin" ? (
            <Table
              columns={ActiveCompaniesColumns}
              dataSource={searchResult?.map((item) => ({
                ...item,
                key: item.id,
                name: item.firstName,
                phoneNumber: item.mobileNumber,
                email: item.email,
                totalAmount: item.paidAmount,
              }))}
              scroll={{
                x: 750,
              }}
              className="custom-table"
            />
          ) : (
            <Table
              columns={ActiveDriversColumns}
              dataSource={searchResult?.map((item) => ({
                ...item,
                key: item.id,
                name: item.firstName,
                phoneNumber: item.phone,
                email: item.email,
              }))}
              scroll={{
                x: 750,
              }}
              className="custom-table"
            />
          )}
          {/* </>
          )} */}
        </div>
      ),
    },
  ];
  return (
    <div className={styles.searchContentWrapper}>
      <p>Press Enter to search.....</p>

      <div className="custom-tab-wrapper">
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"small"}
          items={items}
          tabBarGutter={12}
        />

        <EditAddedDriverModal
          handleEditAddedDriverCancel={handleEditCancel}
          handleEditAddedDriverOk={handleEditOk}
          isEditAddedDriverModalOpen={isEditModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCsvFileData={updateAllDriversData}
        />
        <BlockModal
          handleBlockCancel={handleBlockCancel}
          handleBlockOk={handleBlockOk}
          isBlockModalOpen={isBlockModalOpen}
        />
        <ViewDetailsModal
          handleViewDetailsCancel={handleViewDetailsCancel}
          handleViewDetailsOk={handleViewDetailsOk}
          isViewDetailsModalOpen={isViewDetailsModalOpen}
          viewDriverData={viewDriver}
        />
        <DeleteDriverModal
          handleDeleteDriverCancel={handleDeleteDriverCancel}
          handleDeleteDriverOk={handleDeleteDriverOk}
          isDeleteDriverModalOpen={isDeleteDriverModalOpen}
        />

        <EditCompanyModal
          handleEditCompanyCancel={handleEditCompanyCancel}
          handleEditCompanyOk={handleEditCompanyOk}
          isEditCompanyModalOpen={isEditCompanyModalOpen}
          editAbleItem={editItem}
          editItemIndex={editItemIndex}
          updateCompaniesData={updateCompaniesData}
        />
        <ViewCompanyDetailsModal
          handleViewCompanyDetailsCancel={handleViewCompanyDetailsCancel}
          handleViewCompanyDetailsOk={handleViewCompanyDetailsOk}
          isViewCompanyDetailsModalOpen={isViewCompanyDetailsModalOpen}
        />
        <BlockCompanyModal
          handleBlockCompanyCancel={handleBlockCompanyCancel}
          handleBlockCompanyOk={handleBlockCompanyOk}
          isBlockCompanyModalOpen={isBlockCompanyModalOpen}
          blockStatus={companyData?.isBlocked}
        />
      </div>
    </div>
  );
}

export default SearchBarContainer;
