import React, { useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "antd";
import CustomButton from "components/Button";
import CustomInput from "components/CustomInput";

import { validateCompanyEditModal } from "yupSchema";
import { Field, Form, Formik, ErrorMessage } from "formik";
import InActiveInput from "components/InActiveInput";
import CancelButton from "components/CancelButton";
import PhoneInputField from "components/PhoneNumberInput";

function EditCompanyModal({
  isEditCompanyModalOpen,
  handleEditCompanyOk,
  handleEditCompanyCancel,
  editAbleItem,
  editItemIndex,
  updateCompaniesData,
  isLoading,
}) {
  const formikRef = useRef(null);

  const handleCancelClick = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    handleEditCompanyCancel();
  }

  const handleUpdateClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const initialValues ={
    firstName: editAbleItem?.firstName || "",
    companyPhone: editAbleItem?.mobileNumber || "", 
    companyAddress: editAbleItem?.address || "",
  };

  return (
    <Modal
      centered
      open={isEditCompanyModalOpen}
      onOk={handleEditCompanyOk}
      onCancel={handleCancelClick}
      maskClosable={false}
      width={"79%"}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5rem auto 0.75rem",
            gap: "14px",
            width: "94%",
          }}
        >
          <CancelButton
            text={"Cancel"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            disabled={isLoading}
            onClick={handleCancelClick}
          >
            Custom Button
          </CancelButton>
          <CustomButton
            text={"Update"}
            key="customButton"
            style={{
              width: "156px",
              fontSize: "15px",
              height: "38px",
            }}
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleUpdateClick}
          >
            Custom Button
          </CustomButton>
        </div>,
      ]}
    >
      <div
        style={{
          width: "94%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          margin: "2rem auto 0rem",
        }}
      >
        <p style={{ alignSelf: "flex-start" }} className={styles.modalText}>
          Edit Company <span>Details</span>
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validateCompanyEditModal}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            const updatedItem = {
              ...editAbleItem,
              firstName: values.firstName,
              lastName: "",
              mobileNumber: values.companyPhone,
              address: values.companyAddress,
            };

            updateCompaniesData(updatedItem, editItemIndex);
            handleEditCompanyOk(updatedItem);
            // actions.resetForm();
          }}
          innerRef={formikRef}
          enableReinitialize={true}
          // actions.resetForm()
        >
          {({ isSubmitting }) => (
            <Form
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Company Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={PhoneInputField}
                    type="text"
                    id="companyPhone"
                    name="companyPhone"
                    placeholder="Company Phone Number"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="companyPhone"
                    component="div"
                    className="error"
                  />
                </div>

                {/* <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="confirmCompanyName"
                    name="confirmCompanyName"
                    placeholder="Confirm Company Name"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="confirmCompanyName"
                    component="div"
                    className="error"
                  />
                </div> */}
              </div>

              <div className={styles.inputFieldsWrapper}>

                {/* <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="confirmCompanyPhone"
                    name="confirmCompanyPhone"
                    placeholder="Confirm Phone Number"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="confirmCompanyPhone"
                    component="div"
                    className="error"
                  />
                </div> */}
              </div>

              <div className={styles.inputFieldsWrapper}>
                <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="companyAddress"
                    name="companyAddress"
                    placeholder="Company Address"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="companyAddress"
                    component="div"
                    className="error"
                  />
                </div>
                {/* <div className={styles.inputAndErrorWrapper}>
                  <Field
                    component={CustomInput}
                    type="text"
                    id="confirmCompanyAddress"
                    name="confirmCompanyAddress"
                    placeholder="Confirm Address"
                    style={{ height: "42px" }}
                  />
                  <ErrorMessage
                    name="confirmCompanyAddress"
                    component="div"
                    className="error"
                  />
                </div> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditCompanyModal;
