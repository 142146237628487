import React from "react";
import { Input } from "antd";
import styles from "./styles.module.scss";

const CustomPasswordInput = ({ field, form, ...props }) => {
  return (
    <div className="custom-password">
      <Input.Password
        {...field}
        {...props}
        className={styles.customPasswordInput}
      />
    </div>
  );
};

export default CustomPasswordInput;
