import axios from "axios";

export function authCatchHandler(error) {
  if (axios.isAxiosError(error)) {
    const serverError = error;
    if (serverError && serverError.response) {
      return serverError.response.data;
    }
  }
  return { message: "something is wrong" };
}
