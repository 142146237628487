import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { ReactComponent as CabitIcon } from "assets/icons/CabitIcon.svg";

const VerifyLink = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.innerLeft}>
          <div className={styles.iconTextWrapper}>
            <Link to={"/"}>
              <CabitIcon />
            </Link>
            <p>
              Ride without Limits. No Logins, No Frills. Just Hop In and Go!
            </p>
          </div>
        </div>
        <div className={styles.innerRight}>
          <div className={styles.welcomeTextWrapper}>
            <p>Link Verification</p>
            <p>Link has been sent, please check your email</p>
          </div>
        </div>
      </div>
      <div className={styles.circleShade}></div>
    </div>
  );
};

export default VerifyLink;
