import * as Yup from "yup";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const nameRegex = /^[A-Za-z ]+$/;
const phoneNumberPattern = /^[+-]?\d+(\.\d+)?( |$)/;

export const validateAddCompany = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, "Name must contain only alphabets")
    .required("Company Name is required"),
    // confirmCompanyName: Yup.string()
    // .oneOf([Yup.ref("firstName"), null], "Company name must match")
    // .required("Confirm Company Name is required"),
  companyEmail: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is required"),
  confirmCompanyEmail: Yup.string()
    .oneOf([Yup.ref("companyEmail"), null], "Emails must match")
    .required("Confirm Email is required"),
  companyPhoneNumber: Yup.string()
    .matches(phoneNumberPattern, "Phone Number must contain only digits")
    .max(15, "Phone Number must not exceed 15 characters")
    .required("Phone Number is required"),
  confirmCompanyPhoneNumber: Yup.string()
    .oneOf([Yup.ref("companyPhoneNumber"), null], "Phone numbers must match")
    .required("Confirm Phone Number is required"),
  companyAddress: Yup.string().required("Address is required"),
  // confirmCompanyAddress: Yup.string()
  //   .oneOf([Yup.ref("companyAddress"), null], "Address must match")
  //   .required("Confirm Address is required"),
});
